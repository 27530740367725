<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="mt-16 mb-2 text-center">
        <span class="text-h6 font-weight-bold mt-4">
          倍升互联（北京）科技有限公司企业用户注册登录基本功能隐私协议
        </span>
      </v-col>
      <v-col cols="12" class="my-2">
        <span class="text-h7 font-weight-bold mb-2">
          一、引言
        </span>
      </v-col>
      <v-col cols="12" class="text-small">
        <p>
          欢迎您使用 倍升互联（北京）科技有限公司 网站为企业用户提供注册登录等相关服务。本隐私协议（以下称“协议”）详述了您在注册、登录及使用相关基本功能时，我们关于收集、使用、保护及分享贵公司及其授权使用的自然人用户信息的原则和方式。敬请您在使用服务前详细阅读并完全理解本协议内容，一旦您点击“同意”或实际使用服务，即表示您已同意并接受本隐私协议的各项条款。
        </p>
      </v-col>
      <v-col cols="12" class="my-2">
        <span class="text-h7 font-weight-medium mb-2">
          二、信息收集与使用
        </span>
      </v-col>
      <v-col cols="12" class="text-small">
        <p>
          1.注册信息：在企业用户注册过程中，我们将收集企业基本信息，包括但不限于企业名称、营业执照编号、法定代表人姓名、联系方式、电子邮件地址等，以及授权使用的自然人用户的账号信息，如用户名、密码、职务等，用以创建和维护企业账户，确保账户安全，以及提供相关的服务。<br />
          2.登录信息： 企业在登录网站时，我们会记录登录时间、登录来源IP地址等信息，目的是保证账户安全，防范非授权登录行为。<br />
          3.交易与互动信息：在使用服务的过程中，我们可能收集到企业用户的交易记录、服务请求、反馈信息等，以提供精准的服务支持及优化用户体验。
        </p>
      </v-col>
      <v-col cols="12" class="my-2">
        <span class="text-h7 font-weight-medium mb-2">
          三、信息保护与保密
        </span>
      </v-col>
      <v-col cols="12" class="text-small">
        <p>
          1.我们严格遵守《中华人民共和国数据安全法》、《中华人民共和国个人信息保护法》及《中华人民共和国网络安全法》及其他相关法律法规，采用先进的技术和管理措施，保护企业用户及其授权使用的自然人用户的个人信息，防止未经授权的访问、使用或泄露。<br />
          2.除法律法规另有规定、政府部门依法调取、履行合同约定、保护自身合法权益（例如反欺诈）等情况外，未经企业明示同意，我们不向任何第三方出售或转让企业用户的个人信息。
        </p>
      </v-col>
      <v-col cols="12" class="my-2">
        <span class="text-h7 font-weight-medium mb-2">
          四、信息分享与披露
        </span>
      </v-col>
      <v-col cols="12" class="text-small">
        <p>
          1.在下列情形下，我们可能与第三方共享企业的部分信息：<br />
          (a) 企业明确授权；<br />
          (b) 合作伙伴为提供服务所需；<br />
          (c) 法律法规要求或政府执法需求；<br />
          (d) 为了保护本网站、其他用户或公众的利益。<br />
          2.对于涉及为企业提供服务的关联公司、业务合作伙伴和服务提供商，我们可能与其分享必要信息，但此类第三方必须遵循本隐私协议，并仅限于提供服务所必需的目的。
        </p>
      </v-col>
      <v-col cols="12" class="my-2">
        <span class="text-h7 font-weight-medium mb-2">
          五、企业用户的权利
        </span>
      </v-col>
      <v-col cols="12" class="text-small">
        <p>
          企业用户有权查询、更正、更新或删除其提供的企业信息和授权使用的自然人用户信息。如有需要，可通过网站上的相关功能操作或直接联系我们的客户服务团队办理。
        </p>
      </v-col>
      <v-col cols="12" class="my-2">
        <span class="text-h7 font-weight-medium mb-2">
          六、政策更新
        </span>
      </v-col>
      <v-col cols="12" class="text-small">
        <p>
          我们会适时更新本隐私协议，更新后的版本将在网站明显位置公示。建议企业用户定期查阅，了解最新的隐私保护措施。
        </p>
      </v-col>
      <v-col cols="12" class="my-2">
        <span class="text-h7 font-weight-medium mb-2">
          七、联系我们
        </span>
      </v-col>
      <v-col cols="12" class="text-small">
        <p>
          对于本隐私协议的任何疑问、建议或投诉，企业用户可通过官方公布的客户服务渠道与我们取得联系。
        </p>
      </v-col>
      <v-col cols="12" class="my-2">
        <span class="text-h7 font-weight-medium mb-2">
          八、同意与接受
        </span>
      </v-col>
      <v-col cols="12" class="text-small">
        <p>
          通过使用本网站提供的企业用户注册登录服务，企业用户表明已经阅读、理解并接受本隐私协议的所有条款。若企业用户对本协议任何条款持有异议，则请勿使用或立即停止使用我们的服务。
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
body {
  font-family: PingFangSC-Regular, Microsoft Yahei;
}
.page-h1 {
  font-weight: 600;
  font-size: 56px;
  line-height: 62px;
}
.page-h2 {
  font-weight: 500;
  font-size: 48px;
}
.page-h3 {
  font-weight: 500;
  font-size: 36px;
  line-height: 31px;
}
.page-h4 {
  font-weight: 500;
  font-size: 26px;
}
.page-h5 {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
}
.page-tab {
  font-weight: 500;
  font-size: 26px;
}

.page-subtitle {
  font-weight: 500;
  font-size: 22px;
}
.page-content {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
}

.page-btn {
  font-weight: 400;
  font-size: 24px;
}

.page-list {
  li {
    list-style: none;
  }
}

.activity-title {
  margin-left: auto;
  margin-right: auto;
}
.large-btn {
  width: 300px;
  font-size: 24px;
}
.tab-title {
  font-weight: 500;
  font-size: 24px;
  padding: 10px 40px;
  border-bottom: 1px solid #d9d9d9;
}

.tab-mobile-title {
  padding: 10px 10px;
  border-bottom: 1px solid #d9d9d9;
}

.grey-block {
  background-color: #f5f5f7;
  color: #0573d9;
  border-radius: 24px;
  padding: 30px 40px;
}

.support-image {
  height: 66px;
  object-fit: contain;
  display: block;
  margin: 0 auto 14px;
}

@media screen and (max-width:600px){
  .support-image {
    height: 34px;
  }
}
</style>

<script>
export default {
  data() {
    return {
      contactDialog: false,
      activeTab: null,
      cityContacts: [
        {
          city: '北京',
          contacts: [
            {
              title: '倍升互联-北京中关村企业解决方案中心',
              address: '北京市海淀区东北旺西路8号院35号楼104室',
              phone: '010-82896880'
            },
            {
              title: '倍升互联-北京望京企业解决方案中心',
              address: '北京市朝阳区创远路34号院融新科技中心GH商业楼L1-05号',
              phone: '010-84870105'
            }
          ]
        },
        {
          city: '上海',
          contacts: [
            {
              title: '倍升互联-上海张江企业解决方案中心',
              address: '上海市浦东新区张江路368号1号楼102A倍升互联',
              phone: '021-50790358'
            },
            {
              title: '倍升互联-上海虹桥企业解决方案中心',
              address: '上海市长宁区金钟路968号 11 号楼 205B 室',
              phone: '021-61260917'
            }
          ]
        },
        {
          city: '苏州',
          contacts: [
            {
              title: '倍升互联-苏州企业解决方案中心',
              address: '江苏省苏州市朱家湾8号天安云谷2号楼A幢405',
              phone: '0512-68833720'
            }
          ]
        },
        {
          city: '杭州',
          contacts: [
            {
              title: '倍升互联-杭州企业解决方案中心',
              address: '浙江省杭州市余杭区五常街道文一西路998号海创园5幢104-2-1',
              phone: '0571-26230371'
            }
          ]
        },
        {
          city: '成都',
          contacts: [
            {
              title: '倍升互联-成都企业解决方案中心',
              address: '成都市天府软件园菁蓉国际广场 4A号楼206室',
              phone: '028-61527900'
            }
          ]
        },
        {
          city: '西安',
          contacts: [
            {
              title: '倍升互联-西安企业解决方案中心',
              address: '陕西省西安市雁塔区南三环辅路北100米旺座曲江AB座一层',
              phone: '029-81209960'
            }
          ]
        },
        {
          city: '宁波',
          contacts: [
            {
              title: '倍升互联-宁波企业解决方案中心',
              address: '浙江省宁波市鄞州区宁东路901号宁波报业传媒大厦A座1F西（1-4）',
              phone: '0574-88326786'
            }
          ]
        },
        {
          city: '石家庄',
          contacts: [
            {
              title: '倍升互联-石家庄企业解决方案中心',
              address: '石家庄市裕华区建华大街南二环交口中冶盛世广场A座一层C102A',
              phone: '0311-85258787'
            }
          ]
        },
        {
          city: '深圳',
          contacts: [
            {
              title: '倍升互联-深圳企业解决方案中心',
              address: '深圳市南山区西丽街道留仙大道创智云城A5栋一期151、152号',
              phone: '0755-22676026'
            }
          ]
        },
        {
          city: '天津',
          contacts: [
            {
              title: '倍升互联-天津企业解决方案中心',
              address: '天津市西青区滨海高新区华苑产业园开华道20号南开科技大厦主楼203',
              phone: '022-58883250'
            }
          ]
        },
        {
          city: '厦门',
          contacts: [
            {
              title: '倍升互联-厦门企业解决方案中心',
              address: '厦门市思明区软件园二期望海路23-1号708室',
              phone: null
            }
          ]
        }
      ]
    }
  }
}
</script>
