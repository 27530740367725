var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-img', {
    staticClass: "white--text align-end",
    attrs: {
      "src": "/static/solution/solution-banner.png"
    }
  })], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "mt-16 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-center text-h4 text-md-h3 font-weight-bold mt-4"
  }, [_vm._v("解决方案")])]), _c('v-col', {
    staticClass: "mb-16",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-center text-md-h5 my-4 grey--text text-pretty"
  }, [_vm._v(" 为不同领域的企业提供包含硬件采购、软件开发、移动设备管理（MDM）"), _c('br'), _vm._v(" 以及设备全生命周期服务在内的定制化解决方案。 ")])]), _c('v-col', {
    staticClass: "text-center mb-12",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    directives: [{
      name: "log",
      rawName: "v-log",
      value: 'SOLUTIONS_WITH_CONTACT',
      expression: "'SOLUTIONS_WITH_CONTACT'"
    }],
    staticClass: "rounded-xl large-btn",
    attrs: {
      "x-large": "",
      "depressed": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.contactDialog = true;
      }
    }
  }, [_vm._v(" 与我们取得联系 ")])], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('v-tabs', {
    attrs: {
      "centered": "",
      "plain": "",
      "transition": "fade-transition",
      "prev-icon": "chevron_left",
      "next-icon": "chevron_right",
      "show-arrows": ""
    }
  }, [_c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']
  }, [_vm._v(" MDM 移动设备管理 ")]), _c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']
  }, [_vm._v(" 桌面虚拟化 ")]), _c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']
  }, [_vm._v(" 零信任安全 ")]), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "mt-16 mb-4 text-h3"
  }, [_c('span', {
    staticClass: "activity-title text-h4 text-md-h3 font-weight-bold"
  }, [_vm._v("MDM 移动设备管理")])]), _c('v-card-text', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-md-h5 my-4 grey--text",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 移动设备管理，又称 MDM (Mobile Device Management)，它提供从设备注册、激活、使用、淘汰各个环节进行完整的移动设备全生命周期管理。移动设备管理 (MDM) 能实现用户及设备管理、配置管理、安全管理、资产管理等功能。移动设备管理 (MDM) 还能提供全方位安全体系防护，同时在移动设备、移动 APP、移动文档三方面进行管理和防护。在增强企业员工的移动性，在不影响企业安全的前提下提高员工生产力。它可以让你管理智能手机、笔记本电脑、平板电脑和台式机以及多种操作系统，如 iOS、Android、Windows、macOS 和 Chrome OS。 ")])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("选择适合企业的移动管理")])]), _c('v-col', {
    staticClass: "page-content my-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("功能方面选择")])]), _c('v-col', {
    staticClass: "my-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('img', {
    staticClass: "mdm-image",
    attrs: {
      "src": "/static/solution/s1.png"
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "11"
    }
  }, [_c('span', {
    staticClass: "page-h4"
  }, [_vm._v("1.部署")]), _c('p', {
    staticClass: "page-content"
  }, [_vm._v(" 通过 iPad 和自定 app，门店经理能掌握大量有用的数据。他们可以查看上一年的销售情况和流行趋势，了解本周表现出色的售货员，以及当下畅销的服装款式。这些数据全都触手可及，使门店经理能在不断变化的零售环境中始终拥有先见之明。 ")])]), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('img', {
    staticClass: "mdm-image",
    attrs: {
      "src": "/static/solution/s2.png"
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "11"
    }
  }, [_c('span', {
    staticClass: "page-h4"
  }, [_vm._v("2.配置")]), _c('p', {
    staticClass: "page-content"
  }, [_vm._v(" 一台新设备分发之后，往往需要有各种网络、用户、应用等等配置，十分耗费时间，管控软件可以有效的解决这个问题。 ")])]), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('img', {
    staticClass: "mdm-image",
    attrs: {
      "src": "/static/solution/s3.png"
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "11"
    }
  }, [_c('span', {
    staticClass: "page-h4"
  }, [_vm._v("3.Apps")]), _c('p', {
    staticClass: "page-content"
  }, [_vm._v(" APP 管理是企业中最重要的一环，移动化办公的核心就是移动应用的使用，因此如何快速部署和升级安装应用是企业最关心的功能之一。 ")])]), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('img', {
    staticClass: "mdm-image",
    attrs: {
      "src": "/static/solution/s4.png"
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "11"
    }
  }, [_c('span', {
    staticClass: "page-h4"
  }, [_vm._v("4.资产管理")]), _c('p', {
    staticClass: "page-content"
  }, [_vm._v(" 资产管理是企业很重要的一个需求，通过软件企业能够第一时间了解设备的使用状态和分布情况。可以根据设备所属部门分组，设备的类型分组，设备上安装的 APP 分组等等，然后根据不同的分组配置不同的配置文件和策略。 ")])]), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('img', {
    staticClass: "mdm-image",
    attrs: {
      "src": "/static/solution/s5.png"
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "11"
    }
  }, [_c('span', {
    staticClass: "page-h4"
  }, [_vm._v("5.安全")]), _c('p', {
    staticClass: "page-content"
  }, [_vm._v(" 安全管控对企业来说是非常重要，可以限制应用的使用，一旦发现有黑名单应用，我们可以将应用强制关闭，也可以直接删除。 ")])]), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('img', {
    staticClass: "mdm-image",
    attrs: {
      "src": "/static/solution/s6.png"
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "11"
    }
  }, [_c('span', {
    staticClass: "page-h4"
  }, [_vm._v("6.自助服务")]), _c('p', {
    staticClass: "page-content"
  }, [_vm._v(" 自助服务也是一个非常强大的功能，相当于为企业提供了一个内部的应用商店，企业可以把需要的内容分发上去，让员工自主选择安装。企业可以把 APP、电子书、甚至打印机或者其他硬件的驱动发布到自助服务区中，还可以自己编写一些可执行脚本发布进去，让用户根据需要点击使用或安装。 ")])])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', {
    attrs: {
      "centered": "",
      "plain": "",
      "transition": "fade-transition",
      "prev-icon": "chevron_left",
      "next-icon": "chevron_right",
      "show-arrows": ""
    }
  }, [_c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']
  }, [_vm._v(" VMware ")]), _c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']
  }, [_vm._v(" Jamf ")]), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "my-8"
  }, [_c('span', {
    staticClass: "activity-title text-h4 text-md-h3 font-weight-bold"
  }, [_vm._v("VMware")])]), _c('v-card-subtitle', {
    staticClass: "text-center text-md-h5 my-4 grey--text"
  }, [_c('p', [_vm._v("倍升互联作为 VMware 高级合作伙伴，为适应企业轻资产化的大趋势，现已推出更加灵活便捷的 VMware 订阅方案，企业客户可以根据实际需要，按年订阅所需席位。此外，我们还提供相应的 MDM 部署解决方案和运维服务，解放 IT 生产力。")])]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "10",
      "offset-md": "1"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/solution/VMwareLogo.png"
    }
  })], 1), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("VMware Workspace One 数字化工作空间平台")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" VMware Workspace ONE 是一款智能驱动型数字化工作空间平台，可通过自动注册零接触部署、软件分发和移动设 备管理，移动安全管理，来简单、安全地在相关设备上交付和管理任何应用。Workspace ONE 集成了访问控制 、应用管理和多平台端点管理功能，可以作为云计算服务提供，也可以用于本地部署。 ")])]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('img', {
    staticClass: "vm-image",
    attrs: {
      "src": "/static/solution/VMwarePic1.png"
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('span', {
    staticClass: "page-h5"
  }, [_vm._v("自动注册零接触部署")]), _c('p', {
    staticClass: "page-content"
  }, [_vm._v(" 通过 Apple Business Manager 您可以自动注册 Apple 设备，部署内容，并委派管理权限。您可以在后台设置，自动将企业拥有的移动设备的配置部署到您公司的所有设备。无需在设备上手动操作，您即享受更高级别的控制权限和安全级别，并确定所有用户在拿到设备时，开箱即用! ")])]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('img', {
    staticClass: "vm-image",
    attrs: {
      "src": "/static/solution/VMwarePic2.png"
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('span', {
    staticClass: "page-h5"
  }, [_vm._v("软件分发")]), _c('p', {
    staticClass: "page-content"
  }, [_vm._v(" 企业能够通过 Workspace ONE 软件分发来自动安装、更新和删除软件包，并且提供脚本分发和文件管理工具。为软件、应用、文件、脚本和命令创建自动化工作流，以在注册过程中，或按需在终端设备上安装和配置。您还可以将软件包设置为根据条件进行安装，自动部署软件更新，并在更新发布时通知用户。 ")])]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('img', {
    staticClass: "vm-image",
    attrs: {
      "src": "/static/solution/VMwarePic3.png"
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('span', {
    staticClass: "page-h5"
  }, [_vm._v("移动设备管理")]), _c('p', {
    staticClass: "page-content"
  }, [_vm._v(" 为了保持工作效率，员工需要随时随地能够访问企业的内容。很多员工正在利用免费的文件共享服务，来访问敏感文档，让企业数据陷入风险。Workspace ONE 可以让您随时随地安全的对内容进行移动访问。为远程办公团队，提供了更好的体验。 ")])]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('img', {
    staticClass: "vm-image",
    attrs: {
      "src": "/static/solution/VMwarePic4.png"
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('span', {
    staticClass: "page-h5"
  }, [_vm._v("移动安全管理")]), _c('p', {
    staticClass: "page-content"
  }, [_vm._v(" Workspace ONE 通过添加合规策略来检查设备日常安全状态，并制定相应的触发器来自动执行安全操作。如果设备丢失，也可以直接在管理控制台下发企业擦除，甚至设备擦除的命令，避免企业数据外泄。 ")])])], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "page-h2"
  }, [_c('span', {
    staticClass: "activity-title text-h4 text-md-h3 font-weight-bold my-4"
  }, [_vm._v("Jamf")])]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "mb-8",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('p', {
    staticClass: "text-h5 text-md-h4 font-weight-bold mt-4 mb-8"
  }, [_vm._v(" 由于专注于 Apple 生态系统，"), _c('br'), _c('br'), _vm._v(" 全球的行业领导者选择 Jamf ")]), _c('p', {
    staticClass: "text-md-h5 my-4 grey--text"
  }, [_vm._v(" 自动化 Apple 设备管理的整个生命周期"), _c('br'), _vm._v(" 自动化 Apple 设备管理的整个生命周期"), _c('br'), _vm._v(" 保留用户所需的 Apple 设备体验"), _c('br'), _vm._v(" 访问全球最大的 Apple IT 社区"), _c('br'), _vm._v(" 自动化 Apple 设备管理的整个生命周期"), _c('br'), _vm._v(" 对每个 macOS、iOS、iPadOS 和 tvOS 版本的"), _c('br'), _vm._v(" 当天 Apple OS 支持"), _c('br'), _vm._v(" 行业特定的应用程序和工作流程可满足您的确切需求 ")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/solution/jarmpos1.png"
    }
  })], 1), _c('v-col', {
    staticClass: "mb-8",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/solution/jarmpos2.png"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('span', {
    staticClass: "page-h4"
  }, [_vm._v(" 为什么使用 Jamf ？ ")]), _c('p', {
    staticClass: "page-content"
  }, [_vm._v(" Jamf 是唯一可远程连接、管理和保护 Apple 用户、设备和服务的大规模 Apple 企业管理解决方案。 ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('img', {
    staticClass: "jamf-image",
    attrs: {
      "src": "/static/solution/jamf1.png"
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "page-h5"
  }, [_vm._v("Jamf Pro 设备管理")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "page-content"
  }, [_vm._v(" 使简单的任务自动化，使复杂的任务简单化。Jamf Pro 为员工和学生提供他们所需的工具，让他们在工作和学习中更加轻松自如。Jamf Pro（原名为 Casper Suite）在提高终端用户工作效率和创造力的同时，使您的设备管理自动化，兑现了 Apple 设备统一端点管理的承诺，是适用于 IT 专家和其所支持用户的 EMM 工具。 ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('img', {
    staticClass: "jamf-image",
    attrs: {
      "src": "/static/solution/jamf2.png"
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "page-h5"
  }, [_vm._v("Jamf Connect 连接用户")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "page-content"
  }, [_vm._v(" 简化 Mac 身份验证和账户管理。是否要绑定到 Active Directory (AD)? 不需要。如今,大众强烈希望能够在保障账户安全性的基础上免除将 Mac 绑定到 Active Directory 的操作。Jamf 近期收购了 NoMAD，现已更名为 Jamf Connect，以实现这一流程的无缝衔接。通过 Jamf Connect，最终用户可在拆箱打开 Mac 后使用一组 Okta 或 Mic-rosoft Azure 凭据登录，而后访问其公司的所有应用程序。 ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('img', {
    staticClass: "jamf-image",
    attrs: {
      "src": "/static/solution/jamf3.png"
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "page-h5"
  }, [_vm._v("Jamf Protect 终端防护")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ol', {
    staticClass: "page-content"
  }, [_c('li', [_vm._v("防病毒保护")]), _c('li', [_vm._v("合规监控")]), _c('li', [_vm._v("安全可见性")]), _c('li', [_vm._v("行为侦测")]), _c('li', [_vm._v("威胁搜寻")])])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('img', {
    staticClass: "jamf-image",
    attrs: {
      "src": "/static/solution/jamf4.png"
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "page-h5"
  }, [_vm._v("Jamf Private Access")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "page-content"
  }, [_vm._v(" 让零信任网络访问成为现实。安全地将员工连接到在现代社会中发挥生产力所需的设备、应用程序和数据。Private Access 是一个真正的零信任网络访问解决方案，它取代了传统的条件式访问和 VPN 技术。现在，作为 Jamf 平台的一部分，它可以确保在用户对其设备进行身份验证后，业务连接得到保护，同时让非业务应用程序能够直通互联网，维护终端用户的私密性并优化网络基础设施。对于 IT 而言，部署服务器、管理证书和配置 IP 地址将成为过去。 ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "page-h5"
  }, [_vm._v("Jamf Threat Defense")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "page-content"
  }, [_vm._v(" 由 Jamf 提供支持的 Threat Defense 通过移动威胁检测和零日网络钓鱼攻击预防，借助一流的通知和修复应用程序来保护 iOS 终端设备免遭入侵。 Jamf 和 Threat Defense 一同组成了一个完整的专用解决方案，保护 Apple 用户免受恶意侵害，同时将对最终用户体验的影响降至最低。 ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "page-h5"
  }, [_vm._v("Jamf Data Policy")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "page-content"
  }, [_vm._v(" 自动化、执行和管理使用政策和数据使用。仅有基础知识无法做好设备管理，对于支持远程和混合环境员工的企业尤其如此。公司移动设备提供了随时随地工作的自由，但 YouTube和 Netflix 等流媒体服务的兴起，正在迅速消耗数据资源。即使在个人启用的设备上，非业务活动也不应为您的数据计划或者可以接受的远程用户使用政策带来压力。 ")])])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "mt-16 mb-4 text-h3"
  }, [_c('span', {
    staticClass: "activity-title text-h4 text-md-h3 font-weight-bold"
  }, [_vm._v("桌面虚拟化")])]), _c('v-card-text', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-md-h5 my-4 grey--text",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 将计算机的终端系统(也称作桌面)进行虚拟化，以达到桌面使用的安全性和灵活性。 可以通过任何设备，在任何地点，任何时间通过网络访问属于我们个人的桌面系统。 ")])]), _c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("为什么使用虚拟化桌面和应用?")])]), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('img', {
    staticClass: "mdm-image",
    attrs: {
      "src": "/static/solution/v1.png"
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "11"
    }
  }, [_c('span', {
    staticClass: "page-h4"
  }, [_vm._v("1.提高安全性")]), _c('p', {
    staticClass: "text-md-h5 my-4 grey--text"
  }, [_vm._v(" 数据和应用从不离开数据中心，消除了端点泄漏。 ")])]), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('img', {
    staticClass: "mdm-image",
    attrs: {
      "src": "/static/solution/v2.png"
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "11"
    }
  }, [_c('span', {
    staticClass: "page-h4"
  }, [_vm._v("2.简化管理")]), _c('p', {
    staticClass: "text-md-h5 my-4 grey--text"
  }, [_vm._v(" 借助现代方法集中、简化和加快管理和交付，避免使用传统的 PC 管理工具。 ")])]), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('img', {
    staticClass: "mdm-image",
    attrs: {
      "src": "/static/solution/v3.png"
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "11"
    }
  }, [_c('span', {
    staticClass: "page-h4"
  }, [_vm._v("3.确保合规性")]), _c('p', {
    staticClass: "text-md-h5 my-4 grey--text"
  }, [_vm._v(" 精细控制有助于在受监管的行业中实施合规性要求。 ")])]), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('img', {
    staticClass: "mdm-image",
    attrs: {
      "src": "/static/solution/v4.png"
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "11"
    }
  }, [_c('span', {
    staticClass: "page-h4"
  }, [_vm._v("4.提高工作效率")]), _c('p', {
    staticClass: "text-md-h5 my-4 grey--text"
  }, [_vm._v(" 将 Windows、传统和关键业务应用交付给任何位置的任何设备，无论这些设备采用何种操作系统。 ")])]), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('img', {
    staticClass: "mdm-image",
    attrs: {
      "src": "/static/solution/v5.png"
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "11"
    }
  }, [_c('span', {
    staticClass: "page-h4"
  }, [_vm._v("5.提高收入")]), _c('p', {
    staticClass: "text-md-h5 my-4 grey--text"
  }, [_vm._v(" 通过利用低成本端点以及池化数据中心中的计算、图形和存储资源来提高利用率，从而节省成本。 ")])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "page-h5 mb-12",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("为什么使用虚拟化桌面和应用??")])]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-row', [_c('v-col', {
    class: ['text-center', _vm.$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5'],
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 传统 PC"), _c('br'), _vm._v(" 架构缺点 ")]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("keyboard_arrow_down")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "9"
    }
  }, [_c('p', [_vm._v(" 1.难以管理 "), _c('br'), _vm._v(" 2.总体拥有成本高"), _c('br'), _vm._v(" 3.难以保护数据的安全"), _c('br'), _vm._v(" 4.资源未充分利用"), _c('br'), _vm._v(" 5.接入桌面方式单一、无法移动办公 ")])]), _c('v-col', {
    class: ['text-center', _vm.$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5'],
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 解决方案 ")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "9"
    }
  }, [_c('p', [_vm._v(" 桌面虚拟化构建基于服务器的桌面解决方案，不仅可以解决 PC 桌面面临的各种难题，还能优化可用性、可管理性、总体拥有成本和灵活性 ")])]), _c('v-col', {
    class: ['text-center', _vm.$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5'],
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 方案概述 ")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "9"
    }
  }, [_c('p', [_vm._v(" 桌面虚拟化架构采用以用户为中心的计算、按需向用户提供 IT 资源，既不改变用户 的使用习惯、保证用户获得足够的自由度，同时满足集中管理和数据安全的要求， 解决上述桌面管理的各种难题。 ")])]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("keyboard_arrow_down")])], 1), _c('v-col', {
    class: ['text-center', _vm.$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5'],
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 方案目标 ")])], 1)], 1), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "9"
    }
  }, [_c('p', [_vm._v(" 1.降低成本"), _c('br'), _vm._v(" 2.更广泛与简化的终端设备支持"), _c('br'), _vm._v(" 3.终端设备采购、维护成本大大降低"), _c('br'), _vm._v(" 4.集中管理、统一配置，使用更安全"), _c('br'), _vm._v(" 5.降低耗电、节能减排，让企业 IT 更为绿色"), _c('br'), _vm._v(" 6.让企业的数据与 IT 系统更为安全，保证业务连续性 ")])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 桌面虚拟化-大势所趋 ")]), _c('v-col', {
    staticClass: "text-md-h5 my-4 grey--text",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 许多公司已经转向虚拟化以便用有限的 IT 预算做更多的事情和降低技术成本。企业正认识到存储和服务器虚拟化能够显著减少硬件、电源、空间和管理等成本的好处，桌面虚拟化将为企业 IT 部门提供巨大的成本好处。 鉴于用户正在变得越来越移动性和应用程序越来越大的事实，接触每一个桌面，为最终用户的系统安装、设置和更新应用程序是一项非常繁重的任务。另一方面，用户日益要求他们的 PC 尽可能快速的运行，无论是在线还是离线都要快速地运行，同时还要保证他们的计算环境的安全。桌面虑拟化旨在使工作场所与它下面的架构层隔离开，为企业提供新的管理和部署的选择。 随着全球疫情的不固定性，我们注定要与疫情进行长期奋战，后疫情时代不止改变了我们的生活与工作习惯，更造成了全球零部件和电子产品短缺。桌面虚拟化也将迎来更大的机会。 ")])])], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "mt-16 mb-4"
  }, [_c('span', {
    staticClass: "activity-title text-h4 text-md-h3 font-weight-bold"
  }, [_vm._v("零信任安全")])]), _c('v-card-text', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-md-h5 my-4 grey--text",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 零信任是一种实现 IT 安全性的方法名称它假定不存在受信任的网络边界，并且每个网络事务都必须经过身份验证才能发生。零信任基于“永不信任，始终验证”的原则，并依赖其他网络安全方法，如网络分段和严格的访问控制。零信任网络义“保护面”，其中包括关键数据、资产、应用和服务 (有时小得多，因为只包括关键资产。零信任安全性取代了企业网络称为 DAAS)。保护面通常比整个攻击面任视为一个漏洞，因为“受信任”网络上的用户能够在整个网边界内的资源应受信任的旧假设，并将络中移动，或导致其被授予访问权限的任何和所有数据外泄。 ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/solution/case1.png"
    }
  })], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" “内部等于可信任”和“外部等于不可信任”的旧安全观念需要被打破。 ")]), _c('v-col', {
    staticClass: "text-md-h5 my-4 grey--text",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 由此，ZTNA (零信任)应运而生。"), _c('br'), _vm._v(" ZTNA 环境下，企业应用程序在公网上不再可见，可以免受攻击者的攻击。通过信任代理建立企业应用程序和用户之间的连接，根据身份、属性和环境动态授予访问权限，从而防止未经授权的用户进入并进一步防止数据泄露。对于数字化转型的企业，基于云的 ZTNA 产品，又提供了可扩展性和易用性。 ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/solution/case2.png"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('contact-dialog', {
    attrs: {
      "showDialog": _vm.contactDialog,
      "type": "SOLUTIONS"
    },
    on: {
      "dialogCloseEvent": function dialogCloseEvent($event) {
        _vm.contactDialog = false;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }