<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-card flat>
          <v-img src="/static/account/login-banner.png" class="white--text align-end">
          </v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mt-16 mb-4">
        <p class="text-center text-h4 text-md-h3 font-weight-bold mt-4">创建专属服务账户</p>
      </v-col>
      <v-col cols="12" class="mb-16">
        <p class="text-center text-md-h5 my-4 grey--text">
          注册后您可通过邮件或短信掌握服务订单的进度，以及获取最新的优惠及培训活动等信息。
        </p>
      </v-col>
      <v-col cols="12" class="text-center mb-12">
        <v-form v-model="validForm" @submit.prevent="submit">
          <v-row>
            <v-col cols="6" md="4" offset-md="2" class="text-center">
              <v-text-field
                name="surname"
                v-model="registerForm.surname"
                :rules="[rules.required]"
                label="姓氏*"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="4" class="text-center">
              <v-text-field
                name="name"
                v-model="registerForm.name"
                :rules="[rules.required]"
                label="名字*"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="8" offset-md="2" class="text-center">
              <v-text-field
                name="phone"
                v-model="registerForm.phone"
                :rules="[rules.required, rules.phonenum]"
                label="电话号码*"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="8" offset-md="2" class="text-center">
              <v-text-field
                name="email"
                v-model="registerForm.email"
                :rules="[rules.required, rules.email]"
                label="example@mail.com*"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="8" offset-md="2" class="text-center">
              <v-text-field
                name="username"
                v-model="registerForm.username"
                :rules="[rules.required]"
                label="用户名*"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="8" offset-md="2" class="text-center">
              <v-text-field
                name="password"
                v-model="registerForm.password"
                :rules="[rules.required, rules.sixLength, rules.password]"
                type="password"
                label="密码*"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="8" offset-md="2" class="text-center">
              <v-text-field
                name="confirmPassword"
                v-model="registerForm.confirmPassword"
                :rules="[rules.required, rules.sixLength, rules.password]"
                type="password"
                label="确认密码*"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="8" offset-md="2" class="text-center">
              <v-text-field
                name="enterpriseFullName"
                v-model="registerForm.enterpriseFullName"
                :rules="[rules.required]"
                label="公司全称*"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="8" offset-md="2" class="text-center">
              <v-row>
                <v-col cols="4">
                  <v-select
                    name="provinceCode"
                    v-model="registerForm.provinceCode"
                    :items="provinces"
                    item-text="name"
                    item-value="code"
                    label="省"
                    outlined
                    @change="provinceChanged"
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <v-select
                    name="cityCode"
                    v-model="registerForm.cityCode"
                    :items="cities"
                    item-text="name"
                    item-value="code"
                    label="市"
                    outlined
                    @change="cityChanged"
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <v-select
                    name="areaCode"
                    v-model="registerForm.areaCode"
                    :items="areas"
                    item-text="name"
                    item-value="code"
                    label="区"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="8" offset-md="2" class="text-center">
              <v-text-field
                name="contactAddress"
                v-model="registerForm.contactAddress"
                :rules="[rules.required]"
                label="企业详细地址*"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="8" offset-md="2" class="text-center">
              <v-text-field
                name="contactName"
                v-model="registerForm.contactName"
                :rules="[rules.required]"
                label="企业联系人*"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="8" offset-md="2" class="text-center">
              <v-text-field
                name="contactPhone"
                v-model="registerForm.contactPhone"
                :rules="[rules.required, rules.phonenum]"
                label="企业联系人电话*"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="4" offset-md="2" class="text-center">
              <v-text-field
                name="deptName"
                v-model="registerForm.deptName"
                label="部门"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="4" class="text-center">
              <v-text-field
                name="jobTitle"
                v-model="registerForm.jobTitle"
                label="职位"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="8" offset-md="2" class="text-center">
              <v-text-field
                name="licenseKey"
                v-model="registerForm.licenseKey"
                label="社会统一信用代码"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="8" offset-md="2">
              <div class="filePanel v-text-field d-flex justify-space-between justify-center" @click="handleFileSelect">
                <label>{{ uploadName }}</label>
                <v-btn depressed @click="handleFileSelect">{{ uploadBtn }}</v-btn>
                <input type="file" hidden ref="uploadFile" @change="handleUpload"/>
              </div>
            </v-col>
            <v-col cols="12" md="12" class="my-4 py-4 text-center">
              <v-btn
                x-large
                depressed
                :loading="loading"
                :disabled="loading"
                class="rounded-xl large-btn"
                @click="handleRegister"
              >
                注册
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
.page-h1 {
  font-weight: 600;
  font-size: 56px;
  line-height: 62px;
}
.page-h2 {
  font-weight: 500;
  font-size: 48px;
}
.page-h3 {
  font-weight: 500;
  font-size: 36px;
  line-height: 31px;
}
.page-h4 {
  font-weight: 500;
  font-size: 26px;
}
.page-h5 {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
}
.page-tab {
  font-weight: 500;
  font-size: 26px;
}

.page-subtitle {
  font-weight: 500;
  font-size: 22px;
}
.page-content {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
}

.page-btn {
  font-weight: 400;
  font-size: 24px;
}

.page-list {
  li {
    list-style: none;
  }
}

.activity-title {
  margin-left: auto;
  margin-right: auto;
}
.large-btn {
  width: 300px;
  font-size: 24px;
}
.tab-title {
  font-weight: 500;
  font-size: 24px;
  padding: 10px 40px;
  border-bottom: 1px solid #d9d9d9;
}

.tab-mobile-title {
  padding: 10px 10px;
  border-bottom: 1px solid #d9d9d9;
}
.grey-block {
  background-color: #f5f5f7;
  color: #0573d9;
  border-radius: 24px;
  padding: 30px 40px;
}

.mdm-image {
  height: 60px;
  width: 60px;
  object-fit: contain;
}
.jamf-image {
  height: 59px;
  margin-bottom: 20px;
}

.vm-image {
  width: 124px;
}

.filePanel {
  width: 100%;
  border-color: rgba(0, 0, 0, .42);
  border-style: solid;
  border-width: thin;
  border-radius: 4px;
  padding-bottom: 10px;

  label {
    color: rgba(0, 0, 0, 0.6);
    padding-left: 10px;
    padding-top: 4px;
  }

  button {
    margin-right: 10px;
  }
}

@media screen and (max-width:600px){
  .mdm-image {
    height: 30px;
    width: 30px;
  }
  .jamf-image {
    height: 40px;
  }

  .vm-image {
    width: 50px;
  }
}
</style>

<script>
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_AREA_LIST,
  REGISTER,
  UPLOAD
} from "@/store/actions.type";
import store from "@/store";

export default {
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
        sixLength: value => {
          if (value) {
            return value.length > 5 || '密码长度至少为6位';
          } else {
            return true;
          }
        },
        password: value => {
          if (value) {
            const pattern = /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9]{6,20}/
            return pattern.test(value) || '密码必须同时包含数字和大小写字母';
          } else {
            return true;
          }
        },
      },
      validForm: true,
      provinces: [],
      cities: [],
      areas: [],
      registerForm: {
        surname: "",
        name: "",
        phone: "",
        email: "",
        username: "",
        password: "",
        confirmPassword: "",
        enterpriseFullName: "",
        provinceCode: "",
        cityCode: "",
        areaCode: "",
        contactAddress: "",
        contactName: "",
        contactPhone: "",
        deptName: "",
        jobTitle: "",
        licenseKey: "",
        businessLicense: "",
      },
      uploadName: "点击上传公司营业执照",
      uploadBtn: "选择",
      loading: false,
    }
  },
  methods: {
    loadAreaList() {
      store.dispatch(FETCH_AREA_LIST)
        .then((data) => {
          if (data.data.code !== 200) {
            store.commit(SET_ERROR, {msg: data.data.message});
            return;
          }
          
          this.provinces = data.data.result;
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
    provinceChanged() {
      const pv = this.provinces.find(p => p.code === this.registerForm.provinceCode);
      this.cities = pv.childrenList;

      this.cityChanged();
    },
    cityChanged() {
      const city = this.cities.find(c => c.code === this.registerForm.cityCode);
      if (city === undefined) {
        this.areas = [];
        return;
      }
      this.areas = city.childrenList;
    },
    handleUpload(e) {
      if (e.target.files.length < 1) {
        return;
      }

      const param = new FormData();
      param.append('file', e.target.files[0]);

      const config = {
        headers: {'Content-Type': 'multipart/form-data'}
      };

      store.dispatch(UPLOAD, param, config)
        .then((data) => {
          if (data.data.code !== 0) {
            store.commit(SET_ERROR, {msg: data.data.message});
            return;
          }
          
          this.registerForm.businessLicense = data.data.message;
          this.uploadName = data.data.message;
          this.uploadBtn = '重新选择';
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
    handleFileSelect() {
      this.$refs.uploadFile.click();
    },
    handleRegister() {
      if (!this.validForm) {
        return;
      }
      this.loading = true;

      store.dispatch(REGISTER, this.registerForm)
        .then((data) => {
          if (data.data.code !== 200) {
            store.commit(SET_ERROR, {msg: data.data.message});
            return;
          }
          this.loading = false;
          this.$store.commit(SET_ERROR, {msg: '登录成功', color: 'primary'});

          this.$router.push('/login');
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    }
  },
  created() {
    this.loadAreaList();
  }
}
</script>
