var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('widgets-carousel', {
    attrs: {
      "list": _vm.banners
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('a', {
    attrs: {
      "href": "/mac-as-choice"
    }
  }, [_c('v-img', {
    staticClass: "white--text align-end",
    attrs: {
      "src": "/static/card/macchoice.png"
    }
  })], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('a', {
    attrs: {
      "href": "/get-support"
    }
  }, [_c('v-img', {
    staticClass: "white--text align-end",
    attrs: {
      "src": "/static/card/support.png"
    }
  })], 1)])], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "mt-16",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-center text-h4 text-md-h3 font-weight-bold"
  }, [_vm._v("一步一步，")]), _c('p', {
    staticClass: "text-center text-h4 text-md-h3 font-weight-bold"
  }, [_vm._v("全程为你服务。")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-center text-md-h6 font-weight-medium"
  }, [_vm._v("从租赁服务到选购，及后续全方位服务，")]), _c('p', {
    staticClass: "text-center text-md-h6 font-weight-medium"
  }, [_vm._v("更有软硬件专属解决方案。企业实力从这里开始稳扎稳打。")])])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('a', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "href": "/sales-support"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/card/sales.png"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h4 font-weight-bold justify-center mt-2"
  }, [_vm._v("销售支持")]), _c('p', {
    staticClass: "text-md-h6 font-weight-medium text-center justify-center mt-0"
  }, [_vm._v("持续为企业赋能，助力客户实现更大价值")])], 1)], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('a', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "href": "/equipment-lease"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/card/lease.png"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h4 font-weight-bold justify-center mt-2"
  }, [_vm._v("租赁服务")]), _c('p', {
    staticClass: "text-md-h6 font-weight-medium text-center justify-center mt-0"
  }, [_vm._v("重新定义租赁，助力企业资产高效运转")])], 1)], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('a', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "href": "/after-sales"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/card/service.png"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h4 font-weight-bold justify-center mt-2"
  }, [_vm._v("售后和管理")]), _c('p', {
    staticClass: "text-md-h6 font-weight-medium text-center justify-center mt-0"
  }, [_vm._v("一站式管理服务，为企业IT管理保驾护航")])], 1)], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('a', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "href": "/solution"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/card/solution.png"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h4 font-weight-bold justify-center mt-2"
  }, [_vm._v("解决方案")]), _c('p', {
    staticClass: "text-md-h6 font-weight-medium text-center justify-center mt-0"
  }, [_vm._v("全面完善的解决方案，助力客户实现商业愿景")])], 1)], 1)])], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-center text-h4 font-weight-bold mt-16"
  }, [_vm._v("告诉我们您的业务需求")]), _c('p', {
    staticClass: "text-center text-h6 font-weight-medium"
  }, [_vm._v("info@doublerise.com")])])], 1), _c('v-row', {
    staticClass: "mb-6"
  }, [_c('v-col', {
    attrs: {
      "cols": "0",
      "md": "0",
      "lg": "2"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "8"
    }
  }, [_c('contact-demand', {
    attrs: {
      "type": "BOTTOM_OF_HOMEPAGE"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "0",
      "md": "0",
      "lg": "2"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }