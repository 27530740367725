var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-col', {
    staticClass: "mt-16 mb-2 text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h6 font-weight-bold mt-4"
  }, [_vm._v(" 倍升互联（北京）科技有限公司企业用户注册登录基本功能隐私协议 ")])]), _c('v-col', {
    staticClass: "my-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h7 font-weight-bold mb-2"
  }, [_vm._v(" 一、引言 ")])]), _c('v-col', {
    staticClass: "text-small",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 欢迎您使用 倍升互联（北京）科技有限公司 网站为企业用户提供注册登录等相关服务。本隐私协议（以下称“协议”）详述了您在注册、登录及使用相关基本功能时，我们关于收集、使用、保护及分享贵公司及其授权使用的自然人用户信息的原则和方式。敬请您在使用服务前详细阅读并完全理解本协议内容，一旦您点击“同意”或实际使用服务，即表示您已同意并接受本隐私协议的各项条款。 ")])]), _c('v-col', {
    staticClass: "my-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h7 font-weight-medium mb-2"
  }, [_vm._v(" 二、信息收集与使用 ")])]), _c('v-col', {
    staticClass: "text-small",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 1.注册信息：在企业用户注册过程中，我们将收集企业基本信息，包括但不限于企业名称、营业执照编号、法定代表人姓名、联系方式、电子邮件地址等，以及授权使用的自然人用户的账号信息，如用户名、密码、职务等，用以创建和维护企业账户，确保账户安全，以及提供相关的服务。"), _c('br'), _vm._v(" 2.登录信息： 企业在登录网站时，我们会记录登录时间、登录来源IP地址等信息，目的是保证账户安全，防范非授权登录行为。"), _c('br'), _vm._v(" 3.交易与互动信息：在使用服务的过程中，我们可能收集到企业用户的交易记录、服务请求、反馈信息等，以提供精准的服务支持及优化用户体验。 ")])]), _c('v-col', {
    staticClass: "my-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h7 font-weight-medium mb-2"
  }, [_vm._v(" 三、信息保护与保密 ")])]), _c('v-col', {
    staticClass: "text-small",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 1.我们严格遵守《中华人民共和国数据安全法》、《中华人民共和国个人信息保护法》及《中华人民共和国网络安全法》及其他相关法律法规，采用先进的技术和管理措施，保护企业用户及其授权使用的自然人用户的个人信息，防止未经授权的访问、使用或泄露。"), _c('br'), _vm._v(" 2.除法律法规另有规定、政府部门依法调取、履行合同约定、保护自身合法权益（例如反欺诈）等情况外，未经企业明示同意，我们不向任何第三方出售或转让企业用户的个人信息。 ")])]), _c('v-col', {
    staticClass: "my-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h7 font-weight-medium mb-2"
  }, [_vm._v(" 四、信息分享与披露 ")])]), _c('v-col', {
    staticClass: "text-small",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 1.在下列情形下，我们可能与第三方共享企业的部分信息："), _c('br'), _vm._v(" (a) 企业明确授权；"), _c('br'), _vm._v(" (b) 合作伙伴为提供服务所需；"), _c('br'), _vm._v(" (c) 法律法规要求或政府执法需求；"), _c('br'), _vm._v(" (d) 为了保护本网站、其他用户或公众的利益。"), _c('br'), _vm._v(" 2.对于涉及为企业提供服务的关联公司、业务合作伙伴和服务提供商，我们可能与其分享必要信息，但此类第三方必须遵循本隐私协议，并仅限于提供服务所必需的目的。 ")])]), _c('v-col', {
    staticClass: "my-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h7 font-weight-medium mb-2"
  }, [_vm._v(" 五、企业用户的权利 ")])]), _c('v-col', {
    staticClass: "text-small",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 企业用户有权查询、更正、更新或删除其提供的企业信息和授权使用的自然人用户信息。如有需要，可通过网站上的相关功能操作或直接联系我们的客户服务团队办理。 ")])]), _c('v-col', {
    staticClass: "my-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h7 font-weight-medium mb-2"
  }, [_vm._v(" 六、政策更新 ")])]), _c('v-col', {
    staticClass: "text-small",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 我们会适时更新本隐私协议，更新后的版本将在网站明显位置公示。建议企业用户定期查阅，了解最新的隐私保护措施。 ")])]), _c('v-col', {
    staticClass: "my-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h7 font-weight-medium mb-2"
  }, [_vm._v(" 七、联系我们 ")])]), _c('v-col', {
    staticClass: "text-small",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 对于本隐私协议的任何疑问、建议或投诉，企业用户可通过官方公布的客户服务渠道与我们取得联系。 ")])]), _c('v-col', {
    staticClass: "my-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h7 font-weight-medium mb-2"
  }, [_vm._v(" 八、同意与接受 ")])]), _c('v-col', {
    staticClass: "text-small",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 通过使用本网站提供的企业用户注册登录服务，企业用户表明已经阅读、理解并接受本隐私协议的所有条款。若企业用户对本协议任何条款持有异议，则请勿使用或立即停止使用我们的服务。 ")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }