<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <widgets-carousel :list="banners"></widgets-carousel>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" lg="6">
        <v-card flat>
          <a href="/mac-as-choice">
            <v-img src="/static/card/macchoice.png" class="white--text align-end">
            </v-img>
          </a>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="6">
        <v-card flat>
          <a href="/get-support">
            <v-img src="/static/card/support.png" class="white--text align-end">
            </v-img>
          </a>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mt-16">
        <p class="text-center text-h4 text-md-h3 font-weight-bold">一步一步，</p>
        <p class="text-center text-h4 text-md-h3 font-weight-bold">全程为你服务。</p>
      </v-col>
      <v-col cols="12">
        <p class="text-center text-md-h6 font-weight-medium">从租赁服务到选购，及后续全方位服务，</p>
        <p class="text-center text-md-h6 font-weight-medium">更有软硬件专属解决方案。企业实力从这里开始稳扎稳打。</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" lg="6">
        <v-card flat>
          <a href="/sales-support" style="text-decoration: none;">
            <v-img src="/static/card/sales.png">
              <v-card-title class="text-h4 font-weight-bold justify-center mt-2">销售支持</v-card-title>
              <p class="text-md-h6 font-weight-medium text-center justify-center mt-0">持续为企业赋能，助力客户实现更大价值</p>
            </v-img>
          </a>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="6">
        <v-card flat>
          <a href="/equipment-lease" style="text-decoration: none;">
            <v-img src="/static/card/lease.png">
              <v-card-title class="text-h4 font-weight-bold justify-center mt-2">租赁服务</v-card-title>
              <p class="text-md-h6 font-weight-medium text-center justify-center mt-0">重新定义租赁，助力企业资产高效运转</p>
            </v-img>
          </a>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="6">
        <v-card flat>
          <a href="/after-sales" style="text-decoration: none;">
            <v-img src="/static/card/service.png">
              <v-card-title class="text-h4 font-weight-bold justify-center mt-2">售后和管理</v-card-title>
              <p class="text-md-h6 font-weight-medium text-center justify-center mt-0">一站式管理服务，为企业IT管理保驾护航</p>
            </v-img>
          </a>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="6">
        <v-card flat>
          <a href="/solution" style="text-decoration: none;">
            <v-img src="/static/card/solution.png">
              <v-card-title class="text-h4 font-weight-bold justify-center mt-2">解决方案</v-card-title>
              <p class="text-md-h6 font-weight-medium text-center justify-center mt-0">全面完善的解决方案，助力客户实现商业愿景</p>
            </v-img>
          </a>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <p class="text-center text-h4 font-weight-bold mt-16">告诉我们您的业务需求</p>
        <p class="text-center text-h6 font-weight-medium">info@doublerise.com</p>
      </v-col>
    </v-row>
    <v-row class="mb-6">
      <v-col cols="0" md="0" lg="2"></v-col>
      <v-col cols="12" md="12" lg="8">
        <contact-demand type="BOTTOM_OF_HOMEPAGE"></contact-demand>
      </v-col>
      <v-col cols="0" md="0" lg="2"></v-col>
    </v-row>
  </v-container>
</template>
<script>
import store from "@/store";
import {
  mapGetters
} from "vuex";
import {
  FETCH_BANNER_LIST
} from "@/store/actions.type";
export default {
  data() {
    return {}
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_BANNER_LIST, ''),
    ]).then(() => {
      next();
    });
  },
  computed: {
    ...mapGetters(['banners']),
  },
}
</script>
