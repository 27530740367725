<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-card flat class="supportBanner">
          <img src="/static/get-support/know-banner.png" class="white--text align-end" />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mt-8 mb-4">
        <p class="text-center text-h4 text-md-h3 font-weight-bold mt-4">获取支持</p>
      </v-col>
      <v-col cols="12" class="mb-16">
        <p class="text-center text-md-h5 my-4 grey--text">
          请描述您目前的具体问题，我们将提供针对<br />
          描述适用的解决方案；并提供 Apple 产品<br />
          相关的知识内容。
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-tabs 
          centered 
          plain 
          transition="fade-transition" 
          prev-icon="chevron_left"
          next-icon="chevron_right"
          show-arrows
        >
          <v-tab :class="[$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']">
            百科知识
          </v-tab>
          <v-tab :class="[$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']">
            服务订单
          </v-tab>
          <v-tab :class="[$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']">
            采购订单
          </v-tab>

          <!--百科知识-->
          <v-tab-item transition="fade-transition">
            <v-card flat class="mt-8">
              <v-row>
                <v-col cols="12" offset-md="2" md="8">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-text-field
                        v-model="knowSearch.key"
                        type="text"
                        label="请输入您遇到的问题或想要了解的内容"
                        outlined
                        hide-details
                        prepend-inner-icon="search"
                        @input="handleSearchKnow"
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="knowSearch.show" cols="12">
                      <div class="searchResult">
                        <div>
                          <div class="resultTitle">快速链接</div>
                          <div v-for="(item) in knowSearch.quickLinks" :key="item.id" class="resultFont" @click="handleNavigate(item.id)">{{ item.title }}</div>
                        </div>
                        <v-divider class="my-4"></v-divider>
                        <div>
                          <div class="resultTitle">搜索建议</div>
                          <div v-for="(item, index) in knowSearch.suggestions" :key="index" class="resultFont" @click="handleSelect(item)">{{ item }}</div>
                        </div>
                      </div>
                    </v-col>
                    <v-col v-if="knowSearch.showSuggestion" cols="12">
                      <div class="sugList">
                        <div class="selectItem">{{ knowSearch.suggestionText }}</div>
                          <div v-for="(item) in knowSearch.suggestionList" :key="item.id" class="sugListItem" @click="handleNavigate(item.id)">
                            <img :src="item.profileImage" />
                            <div class="flex flex-1 flex-col">
                              <div class="sugTop">{{ item.title }}</div>
                              <div class="sugBottom">
                                <span>{{ item.catalog }}</span>
                                更新时间：{{ item.updateTime }}
                              </div>
                            </div>
                          </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>

          <!--服务订单-->
          <v-tab-item transition="fade-transition">
            <v-card flat class="mt-8">
              <v-row>
                <v-col cols="12" offset-md="2" md="8">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-text-field
                        v-model="serviceSearch.key"
                        type="text"
                        label="请输入您的订单编号"
                        outlined
                        hide-details
                        prepend-inner-icon="search"
                        @input="handleSearchService"
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="serviceSearch.show" cols="12">
                      <div class="searchResult orderFadeIn">
                        <div>
                          <div class="resultTitle">搜索结果</div>
                          <div v-if="!isAuthenticated" class="loginTip">
                            <a href='/login'>登录</a> 查看你的订单
                          </div>
                          <div v-if="isAuthenticated">
                            <div v-for="(item) in serviceSearch.result" :key="item.id" class="resultFont" @click="handleOrderDetail(item.id, item.type, item)">
                              {{ item.orderNumber || item.caseNumber }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>

          <!--采购订单-->
          <v-tab-item transition="fade-transition">
            <v-card flat class="mt-8">
              <v-row>
                <v-col cols="12" offset-md="2" md="8">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-text-field
                        v-model="orderSearch.key"
                        type="text"
                        label="请输入您的订单编号"
                        outlined
                        hide-details
                        prepend-inner-icon="search"
                        @input="handleSearchOrder"
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="orderSearch.show" cols="12">
                      <div class="searchResult orderFadeIn">
                        <div>
                          <div class="resultTitle">搜索结果</div>
                          <div v-if="!isAuthenticated" class="loginTip">
                            <a href='/login'>登录</a> 查看你的订单
                          </div>
                          <div v-if="isAuthenticated">
                            <div v-for="(item) in orderSearch.result" :key="item.id" class="resultFont" @click="handleOrderDetail(item.id, 'order', item)">
                              {{ item.orderNumber || item.caseNumber }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-col>
                    <order-order-detail :showDialog="showOrderDialog" :orderId="currentOrderId" @dialogCloseEvent="showOrderDialog = false"></order-order-detail>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-card flat class="contactCustomer">
          <div class="contactCustomerTitle">在线视频客服</div>
          <div class="contactCustomerSubTitle">如您的问题未被解答，请在线呼叫产品专家，面对面的为您提供帮助。</div>
          <a href="https://baseavs.udesk.cn/h5/?sdkAppId=psSRYZX3iY8NF5hm&code=" target="_blank">
            <div class="contactCustomerBtn">
              <img src="/img/icons/camera.png" alt="" />
              <div class="contactCustomerBtnTxt">拨打视频获取支持</div>
            </div>
          </a>
          <div class="contactCustomerTip">我们的工作时间为：每周一至周五 9:00～18:00</div>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="mt-8 mb-4">
        <p class="text-center page-h1 mt-4">线上服务申报</p>
      </v-col>
      <v-col cols="12" class="mb-4">
        <p class="text-center text-h6 font-weight-medium mb-2">
          如问题已解决并需要获得维修服务，您可以在线进行服务申报。
        </p>
      </v-col>
      <v-col cols="12" class="text-center mb-12">
        <v-menu v-if="!isAuthenticated" transition="scroll-y-transition" min-width="100" :nudge-width="100" top offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              x-large
              depressed
              color="primary"
              class="rounded-xl large-btn"
              v-bind="attrs"
              v-on="on"
            >
              马上申报服务
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              请登录您的专属账户
            </v-card-text>
            <v-card-actions class="text-center">
              <v-btn
                :to="{ path: '/account' }"
                depressed
                plat
                >注册</v-btn>
              <v-btn
                :to="{ path: '/login' }"
                depressed
                plat
                >登录</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        <v-btn
          v-if="isAuthenticated"
          x-large
          depressed
          color="primary"
          class="rounded-xl large-btn"
          v-log="'GET_SUPPORT_WITH_SERVICE'"
          @click="showServiceDialog = true"
        >
          马上申报服务
        </v-btn>
      </v-col>
      <contact-service :showDialog="showServiceDialog" @dialogCloseEvent="showServiceDialog = false"></contact-service>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
.page-h1 {
  font-weight: 600;
  font-size: 56px;
  line-height: 62px;
}
.page-h2 {
  font-weight: 500;
  font-size: 48px;
}
.page-h3 {
  font-weight: 500;
  font-size: 36px;
  line-height: 31px;
}
.page-h4 {
  font-weight: 500;
  font-size: 26px;
}
.page-h5 {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
}
.page-h6 {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
}
.page-tab {
  font-weight: 500;
  font-size: 26px;
}

.page-subtitle {
  font-weight: 500;
  font-size: 22px;
}
.page-content {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
}
.page-m-content {
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
}

.page-ms-content {
  font-weight: 300;
  font-size: 12px;
  line-height: 30px;
}
.page-btn {
  font-weight: 400;
  font-size: 24px;
}

.page-list {
  li {
    list-style: none;
  }
}

.activity-title {
  margin-left: auto;
  margin-right: auto;
}
.large-btn {
  width: 300px;
  font-size: 24px;
}
.tab-title {
  font-weight: 500;
  font-size: 24px;
  padding: 10px 40px;
  border-bottom: 1px solid #d9d9d9;
}

.tab-mobile-title {
  padding: 10px 10px;
  border-bottom: 1px solid #d9d9d9;
}

.grey-block {
  background-color: #f5f5f7;
  color: #0573d9;
  border-radius: 24px;
  padding: 30px 40px;
}

.supportBanner {
  width: 100%;
  height: 324px;
  margin: 20px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background: linear-gradient(135deg, #f5f5f7, #d0e8ff);
}

.searchResult {
  position: relative;
  z-index: 2;
  margin: 0 auto;
  padding: 40px 55px 40px 55px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #D0D0D0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  // opacity: 0;
  // height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  .resultTitle {
    font-weight: 300;
    font-size: 16px;
    color: rgba(151, 151, 151, 0.60);
    line-height: 31px;
  }

  .resultFont {
    font-weight: 400;
    font-size: 16px;
    color: #1D1D1F;
    line-height: 31px;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        cursor: pointer;
        color: #0071D9;
    }
  }
}

.sugList {
  width: 100%;
  margin: 0 auto;

  .selectItem {
    color: #999;
    font-size: 12px;
    margin-top: 20px;
    padding-left: 10px;
  }

  .sugListItem {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    // margin-top: 20px;
    padding: 20px;
    border-bottom: 1px solid rgba(242, 244, 247, 1);

    &:hover {
      cursor: pointer;
      background-color: rgba(242, 244, 247, 0.2);
    }

    img {
      height: 50px;
      width: 50px;
      margin-right: 10px;
    }

    .sugTop {
      margin-bottom: 10px;
      font-weight: 500;
      font-family: "PingFangSC-Semibold";
    }

    .sugBottom {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
    }
  }
}

.contactCustomer {
  width: 100%;
  height: 817px;
  margin: 76px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("/static/get-support/knowbg.png") no-repeat;
  background-size: contain;
  &:hover {
      cursor: pointer;
  }

  .contactCustomerTitle {
      margin: 76px 0 31px 0;
      font-weight: 600;
      font-size: 56px;
      color: #1D1D1F;
      line-height: 62px;
      font-family: "PingFangSC-Medium";
  }

  .contactCustomerSubTitle {
      margin-bottom: 45px;
      font-weight: 500;
      font-size: 22px;
      color: #1D1D1F;
      font-family: "PingFangSC-Medium";
  }

  .contactCustomerBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 280px;
      height: 60px;
      background: #0573D9;
      border-radius: 40px;
      margin-bottom: 25px;

      img {
          width: 28px;
          height: 20px;
          margin-right: 10px;
      }

      .contactCustomerBtnTxt {
          font-weight: 500;
          font-size: 20px;
          color: #FFFFFF;
          line-height: 31px;
          font-family: "PingFangSC-Medium";
      }
  }

  .contactCustomerTip {
      font-weight: 400;
      font-size: 18px;
      color: #0573D9;
      line-height: 30px;
  }
}

@media screen and (max-width:600px){
  .contactCustomer {
    height: 400px;
    .contactCustomerTitle {
        margin: 15px 0 8px 0;
        font-weight: 600;
        font-size: 30px;
    }

    .contactCustomerSubTitle {
      margin: 0 20px 10px;
      font-weight: 500;
      font-size: 16px;
    }

    .contactCustomerBtn {
      margin-top: 170px;
    }
  }
}
</style>

<script>
import { SET_ERROR } from "@/store/mutations.type";
import {
  mapGetters
} from "vuex";
import {
  SEARCH_KNOWLEDGE,
  FETCH_KNOWLEDGE_LIST,
  SEARCH_CASE_COLLECT,
  FETCH_ORDER_LIST,
  FETCH_JUMP_TOKEN
} from "@/store/actions.type";
import store from "@/store";

export default {
  data() {
    return {
      showServiceDialog: false,
      knowSearch: {
        key: '',
        show: false,
        quickLinks: [],
        suggestions: [],
        showSuggestion: false,
        suggestionText: '',
        suggestionList: [],
      },
      serviceSearch: {
        key: '',
        show: false,
        result: [],
      },
      orderSearch: {
        key: '',
        show: false,
        result: [],
      },
      currentOrderId: null,
      showOrderDialog: false,
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'serviceList']),
  },
  methods: {
    handleSearchKnow(e) {
      if (this.knowSearch.key.length > 1) {
        store.dispatch(SEARCH_KNOWLEDGE, {key: this.knowSearch.key})
          .then((data) => {
            if (data.data.code !== 200) {
              store.commit(SET_ERROR, {msg: data.data.message});
              return;
            }
            
            this.knowSearch.quickLinks = data.data.result.quickLinks;
            this.knowSearch.suggestions = data.data.result.suggestions;
          })
          .catch((err) => {
            store.commit(SET_ERROR, {msg: err.response.data.message});
          });
      }
      this.knowSearch.show = this.knowSearch.key.length > 1;
    },
    handleNavigate(id) {
      this.$router.push(`/get-support/detail/${id}`);
    },
    handleSelect(item) {
      this.knowSearch.show = false;
      this.knowSearch.suggestionText = item;

      const params = {
        key: item,
        videoFlag: true,
        pageNo: 1,
        pageSize: 10
      }

      const params_2 = {
        key: item,
        videoFlag: false,
        pageNo: 1,
        pageSize: 10
      }

      let p = Promise.all([store.dispatch(FETCH_KNOWLEDGE_LIST, params), store.dispatch(FETCH_KNOWLEDGE_LIST, params_2)])

      p.then(arr => {
        const result = []
        arr.forEach(res => {
          result.push(...res.data.result.knowledge)
        })
        this.knowSearch.suggestionList = result;
        this.knowSearch.showSuggestion = true;
      }, e => {
        store.commit(SET_ERROR, {msg: err.response.data.message});
      })
    },
    handleSearchService() {
      if (this.serviceSearch.key.length > 1) {
        store.dispatch(SEARCH_CASE_COLLECT, {key: this.serviceSearch.key, pageNo: 1, pageSize: 10})
          .then((data) => {
            if (data.data.code !== 200) {
              store.commit(SET_ERROR, {msg: data.data.message});
              return;
            }
            
            const { serviceList, repairList } = data.data.result;
            let combinedList = [];

            // 如果repairList非空，则将其元素添加到combinedList中，并设置type属性
            if (repairList.length) {
              combinedList.push(...repairList.map(item => ({ ...item, type: 'repair' })));
            }

            // 如果serviceList非空，则将其元素添加到combinedList中，并设置type属性
            if (serviceList.length) {
              combinedList.push(...serviceList.map(item => ({ ...item, type: 'service' })));
            }

            // 将处理过的combinedList设置到orders状态或变量中
            this.serviceSearch.result = combinedList;
            console.log(this.serviceSearch.result);
          })
          .catch((err) => {
            store.commit(SET_ERROR, {msg: err.response.data.message});
          });
      }
      this.serviceSearch.show = this.serviceSearch.key.length > 1;
    },
    handleSearchOrder() {
      if (this.orderSearch.key.length > 1) {
        store.dispatch(FETCH_ORDER_LIST, {key: this.orderSearch.key, pageNo: 1, pageSize: 10})
          .then((data) => {
            if (data.data.code !== 200) {
              store.commit(SET_ERROR, {msg: data.data.message});
              return;
            }
            
            const { records } = data.data.result;
            
            // 将处理过的combinedList设置到orders状态或变量中
            this.orderSearch.result = records;
          })
          .catch((err) => {
            store.commit(SET_ERROR, {msg: err.response.data.message});
          });
      }
      this.orderSearch.show = this.orderSearch.key.length > 1;
    },
    handleOrderDetail(id, type, item) {
      if (type === 'order') {
        this.currentOrderId = id;
        this.showOrderDialog = true;
        return;
      } else {
        store.dispatch(FETCH_JUMP_TOKEN)
          .then((data) => {
            if (data.data.code !== 200) {
              store.commit(SET_ERROR, {msg: data.data.message});
              return;
            }
            
            const baseUrl = process.env.VUE_APP_ESP_URL;
            const token = data.data.result;
            if (type === 'service') {
              window.open(`${baseUrl}/workbench/equip?token=${token}&key=${item.caseNumber}`, "_blank");
            } else {
              window.open(`${baseUrl}/workbench/repair?token=${token}&key=${item.caseNumber}`, "_blank");
            }
          })
          .catch((err) => {
            store.commit(SET_ERROR, {msg: err.response.data.message});
          });
      }
    },
  },
}
</script>
