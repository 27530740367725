<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-card flat>
          <v-img src="/static/sales-support/sales_support_banner.png" class="white--text align-end">
          </v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mt-16 mb-4">
        <p class="text-center text-h4 text-md-h3 font-weight-bold mt-4">销售支持</p>
      </v-col>
      <v-col cols="12" class="mb-16">
        <p class="text-center text-md-h5 my-4 grey--text text-pretty">
          我们提供线上商城及线下商店两种灵活的采购方式，<br />
          囊括专业的售前咨询及售后服务，企业可以灵活定制个性化的采购方案及相关支持。
        </p>
      </v-col>
      <v-col cols="12" class="text-center mb-12">
        <v-btn
          x-large
          depressed
          color="primary"
          class="rounded-xl large-btn"
          v-log="'SALES_SUPPORT_WITH_CONTACT'"
          @click="contactDialog = true"
        >
          与我们取得联系
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-tabs 
          centered 
          plain 
          transition="fade-transition" 
          prev-icon="chevron_left"
          next-icon="chevron_right"
          show-arrows
        >
          <v-tab :class="[$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']">
            ESC企业解决方案中心
          </v-tab>
          <v-tab :class="[$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']">
            ESP企业服务平台
          </v-tab>
          <v-tab :class="[$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']">
            EC企业员工优选商城
          </v-tab>

          <!--ESC企业解决方案中心-->
          <v-tab-item transition="fade-transition">
            <v-card flat>
              <v-card-title class="mt-16 mb-4">
                <span class="margin-center text-h4 text-md-h3 font-weight-bold">ESC企业解决方案中心</span>
              </v-card-title>
              <v-card-text class="mt-4 mb-4">
                <v-row>
                  <v-col cols="12" class="text-center text-md-h5 my-4 grey--text text-pretty">
                    <p>
                      Apple 企业解决方案中心，<br />
                      一个集产品零售、设备维修、解决方案于一身的全新的体验中心。<br />
                      把 Apple 企业级服务带到企业及员工身边，<br />
                      用生活中爱不释手的产品，为大家提供耳目一新的工作体验。
                    </p>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-space-around my-8">
                    <div class="text-center">
                      <img class="support-image" src="/static/sales-support/s1.png" />
                      <span class="font-weight-bold">产品体验</span>
                    </div>
                    <div class="text-center">
                      <img class="support-image" src="/static/sales-support/s2.png" />
                      <span class="font-weight-bold">解决方案</span>
                    </div>
                    <div class="text-center">
                      <img class="support-image" src="/static/sales-support/s3.png" />
                      <span class="font-weight-bold">技术培训</span>
                    </div>
                    <div class="text-center">
                      <img class="support-image" src="/static/sales-support/s4.png" />
                      <span class="font-weight-bold">售后维修</span>
                    </div>
                  </v-col>
                  <v-col cols="12" class="text-md-h5 my-4 grey--text">
                    <p>
                      覆盖全国的 13 个大型科技园区的企业解决方案中心，<br />
                      致力为为高新园区的每一位企业客户和企业员工提供。 产品销售、维修和方案交流于一体的一站式服务
                    </p>
                  </v-col>
                </v-row>
                <v-card-title class="mt-16 mb-4 text-h4">
                  <span class="margin-center text-h4 text-md-h3 font-weight-bold">ESC覆盖城市及地址</span>
                </v-card-title>
                <v-row class="mt-4 mb-4">
                  <v-col cols="12" class="page-content">
                    <v-tabs centered plain
                      prev-icon="chevron_left"
                      next-icon="chevron_right"
                      show-arrows 
                      v-model="activeTab">
                      <v-tab
                        v-for="(cc, index) in cityContacts"
                        :key="index"
                      >
                        {{ cc.city }}
                      </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="activeTab">
                      <v-tab-item
                        v-for="(cc, index) in cityContacts"
                        :key="index"
                      >
                        <v-card
                          flat
                        >
                          <v-card-text 
                            v-for="(c, ci) in cc.contacts" 
                            :key="ci"
                          >
                            <v-row class="py-8">
                              <v-col cols="12" class="page-h5 py-1">
                                {{ c.title }}
                              </v-col>
                              <v-col cols="12" class="page-content py-1">
                                地址:{{ c.address }}
                              </v-col>
                              <v-col v-if="c.phone !== null" cols="12" class="page-content py-1">
                                电话:{{ c.phone }}
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!--ESP企业服务平台-->
          <v-tab-item transition="fade-transition">
            <v-card flat>
              <v-card-title class="mt-16 mb-4 text-h3">
                <span class="margin-center text-h4 text-md-h3 font-weight-bold">ESP企业服务平台</span>
              </v-card-title>
              <v-card-text>
                <p class="text-center text-md-h5 my-4 grey--text text-pretty">
                  企业级服务的专属平台<br />
                  高效省时的 IT 服务，随您定制的解决方案。<br />
                  企业服务平台，改变传统移动化服务模式，<br />
                  建立智能化、流程化、系统化的服务新标准，解放IT生产力， 
                  提供移动设备的全生命周期服务，让一切变得井然有序。
                </p>
              </v-card-text>
              <v-card-text>
                <v-row>
                  <v-col cols="2" class="text-center">
                    <img class="support-image" src="/static/sales-support/e1.png" />
                    <span>订单管理</span>
                  </v-col>
                  <v-col cols="2" class="text-center">
                    <img class="support-image" src="/static/sales-support/e2.png" />
                    <span>资产管理</span>
                  </v-col>
                  <v-col cols="2" class="text-center">
                    <img class="support-image" src="/static/sales-support/e3.png" />
                    <span>远程诊断服务</span>
                  </v-col>
                  <v-col cols="2" class="text-center">
                    <img class="support-image" src="/static/sales-support/e4.png" />
                    <span>设备运维服务</span>
                  </v-col>
                  <v-col cols="2" class="text-center">
                    <img class="support-image" src="/static/sales-support/e5.png" />
                    <span>维修申报服务</span>
                  </v-col>
                  <v-col cols="2" class="text-center">
                    <img class="support-image" src="/static/sales-support/e6.png" />
                    <span>知识库及咨询</span>
                  </v-col>
                  <v-col cols="12" class="text-md-h5 my-4 grey--text">
                    <p>
                      提供一站式 ESP 云端服务，简化服务流程，提升服务体验！
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!--EC企业员工优选商城-->
          <v-tab-item transition="fade-transition">
            <v-card flat>
              <v-card-title class="mt-16 mb-4">
                <span class="margin-center text-h4 text-md-h3 font-weight-bold">EC企业员工优选商城</span>
              </v-card-title>
              <v-card-text class="mt-4 mb-4">
                <v-row>
                  <v-col cols="12" class="text-center text-md-h5 my-4 grey--text text-pretty">
                    <p>
                      Employee Choice 员工之选商城 （EC）是针对企业员工选购 Apple 产品的专属平台，<br />
                      由 Apple 授权经销商提供专业支持和统一服务，为大家提供专属福利。
                    </p>
                  </v-col>
                  <v-col cols="12" class="page-h4">
                    专属福利
                  </v-col>
                  <v-col cols="3" class="text-center my-8">
                    <img class="support-image" src="/static/sales-support/c1.png" />
                    <span>官方正品及活动</span>
                  </v-col>
                  <v-col cols="3" class="text-center my-8">
                    <img class="support-image" src="/static/sales-support/c2.png" />
                    <span>员工专属内购价格</span>
                  </v-col>
                  <v-col cols="3" class="text-center my-8">
                    <img class="support-image" src="/static/sales-support/c3.png" />
                    <span>驻场设备清洁和检测</span>
                  </v-col>
                  <v-col cols="3" class="text-center my-8">
                    <img class="support-image" src="/static/sales-support/c4.png" />
                    <span>产品使用定制培训</span>
                  </v-col>
                  <v-col cols="12" class="page-h4">
                    购买流程
                  </v-col>
                  <v-col cols="12" class="page-h5">
                    1.扫描二维码关注「 EC 员工之选 」公众号<br /><br />
                    2.从弹出的欢迎语点击 登录<br /><br />
                    3.使用企业邮箱登录后开始选购
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <v-img src="/static/sales-support/b1.png" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <contact-dialog :showDialog="contactDialog" type="SALES_SUPPORT" @dialogCloseEvent="contactDialog = false"></contact-dialog>
  </v-container>
</template>

<style lang="scss" scoped>
.page-h1 {
  font-weight: 600;
  font-size: 56px;
  line-height: 62px;
}
.page-h2 {
  font-weight: 500;
  font-size: 48px;
}
.page-h3 {
  font-weight: 500;
  font-size: 36px;
  line-height: 31px;
}
.page-h4 {
  font-weight: 500;
  font-size: 26px;
}
.page-h5 {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
}
.page-tab {
  font-weight: 500;
  font-size: 26px;
}

.page-subtitle {
  font-weight: 500;
  font-size: 22px;
}
.page-content {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
}

.page-btn {
  font-weight: 400;
  font-size: 24px;
}

.page-list {
  li {
    list-style: none;
  }
}

.margin-center {
  margin-left: auto;
  margin-right: auto;
}
.large-btn {
  width: 300px;
  font-size: 24px;
}
.tab-title {
  font-weight: 500;
  font-size: 24px;
  padding: 10px 40px;
  border-bottom: 1px solid #d9d9d9;
}

.tab-mobile-title {
  padding: 10px 10px;
  border-bottom: 1px solid #d9d9d9;
}

.grey-block {
  background-color: #f5f5f7;
  color: #0573d9;
  border-radius: 24px;
  padding: 30px 40px;
}

.support-image {
  height: 66px;
  object-fit: contain;
  display: block;
  margin: 0 auto 14px;
}

@media screen and (max-width:600px){
  .support-image {
    height: 34px;
  }
  .text-pretty {
    word-break: keep-all;
  }
}
</style>

<script>
export default {
  data() {
    return {
      contactDialog: false,
      activeTab: null,
      cityContacts: [
        {
          city: '北京',
          contacts: [
            {
              title: '倍升互联-北京中关村企业解决方案中心',
              address: '北京市海淀区东北旺西路8号院35号楼104室',
              phone: '010-82896880'
            },
            {
              title: '倍升互联-北京望京企业解决方案中心',
              address: '北京市朝阳区创远路34号院融新科技中心GH商业楼L1-05号',
              phone: '010-84870105'
            }
          ]
        },
        {
          city: '上海',
          contacts: [
            {
              title: '倍升互联-上海张江企业解决方案中心',
              address: '上海市浦东新区张江路368号1号楼102A倍升互联',
              phone: '021-50790358'
            },
            {
              title: '倍升互联-上海虹桥企业解决方案中心',
              address: '上海市长宁区金钟路968号 11 号楼 205B 室',
              phone: '021-61260917'
            }
          ]
        },
        {
          city: '苏州',
          contacts: [
            {
              title: '倍升互联-苏州企业解决方案中心',
              address: '江苏省苏州市朱家湾8号天安云谷2号楼A幢405',
              phone: '0512-68833720'
            }
          ]
        },
        {
          city: '杭州',
          contacts: [
            {
              title: '倍升互联-杭州企业解决方案中心',
              address: '浙江省杭州市余杭区五常街道文一西路998号海创园5幢104-2-1',
              phone: '0571-26230371'
            }
          ]
        },
        {
          city: '成都',
          contacts: [
            {
              title: '倍升互联-成都企业解决方案中心',
              address: '成都市天府软件园菁蓉国际广场 4A号楼206室',
              phone: '028-61527900'
            }
          ]
        },
        {
          city: '西安',
          contacts: [
            {
              title: '倍升互联-西安企业解决方案中心',
              address: '陕西省西安市雁塔区南三环辅路北100米旺座曲江AB座一层',
              phone: '029-81209960'
            }
          ]
        },
        {
          city: '宁波',
          contacts: [
            {
              title: '倍升互联-宁波企业解决方案中心',
              address: '浙江省宁波市鄞州区宁东路901号宁波报业传媒大厦A座1F西（1-4）',
              phone: '0574-88326786'
            }
          ]
        },
        {
          city: '石家庄',
          contacts: [
            {
              title: '倍升互联-石家庄企业解决方案中心',
              address: '石家庄市裕华区建华大街南二环交口中冶盛世广场A座一层C102A',
              phone: '0311-85258787'
            }
          ]
        },
        {
          city: '深圳',
          contacts: [
            {
              title: '倍升互联-深圳企业解决方案中心',
              address: '深圳市南山区西丽街道留仙大道创智云城A5栋一期151、152号',
              phone: '0755-22676026'
            }
          ]
        },
        {
          city: '天津',
          contacts: [
            {
              title: '倍升互联-天津企业解决方案中心',
              address: '天津市西青区滨海高新区华苑产业园开华道20号南开科技大厦主楼203',
              phone: '022-58883250'
            }
          ]
        },
        {
          city: '厦门',
          contacts: [
            {
              title: '倍升互联-厦门企业解决方案中心',
              address: '厦门市思明区软件园二期望海路23-1号708室',
              phone: null
            }
          ]
        }
      ]
    }
  }
}
</script>
