var render = function render(){
  var _vm$filter_list$find, _vm$filter_list$find2, _vm$filter_list$find3, _vm$filter_list$find4, _vm$filter_list$find5, _vm$filter_list$find6, _vm$filter_list$find7, _vm$filter_list$find8, _vm$filter_list$find9, _vm$filter_list$find10, _vm$filter_list$find11;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-col', {
    staticClass: "d-flex justify-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n1",
    attrs: {
      "size": "48",
      "color": "black"
    }
  }, [_vm._v("apple")]), _c('span', {
    staticClass: "text-h3 font-weight-regular",
    staticStyle: {
      "white-space": "pre-wrap"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.pageInfo.categoryName)
    }
  })], 1), _c('v-col', {
    staticClass: "d-flex justify-center my-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-h4 text-md-h3 font-weight-bold",
    staticStyle: {
      "white-space": "pre-wrap"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.pageInfo.explainInfo)
    }
  })]), _c('v-col', {
    staticClass: "d-flex justify-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-h5 font-weight-thin text-center",
    staticStyle: {
      "white-space": "pre-wrap"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.pageInfo.describeInfo)
    }
  })])], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-flex justify-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "src": _vm.pageInfo.imgUrl,
      "max-height": "450"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "id": "categoryArea"
    }
  }, [_c('v-col', {
    staticClass: "d-flex justify-center mt-12",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-h5 font-weight-bold text-center"
  }, [_vm._v("按类别浏览")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', {
    staticClass: "bordered-tabs",
    attrs: {
      "centered": "",
      "grow": "",
      "show-arrows": "",
      "color": "black"
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c('v-tab', {
    staticClass: "font-weight-light",
    attrs: {
      "to": {
        path: '/product/' + encodeURIComponent(_vm.pageInfo.categoryName.toLowerCase()),
        query: {
          brandId: _vm.pageInfo.brandId,
          firstCategoryId: _vm.pageInfo.id
        }
      }
    }
  }, [_vm._v(" 全部 ")]), _vm._l(_vm.pageInfo.childrenList, function (item, index) {
    return _c('v-tab', {
      key: 'category_tab_' + index,
      staticClass: "font-weight-light",
      attrs: {
        "to": {
          path: '/product/' + encodeURIComponent(_vm.pageInfo.categoryName.toLowerCase()) + '/' + encodeURIComponent(item.categoryName.toLowerCase()),
          query: {
            brandId: _vm.pageInfo.brandId,
            firstCategoryId: _vm.pageInfo.id,
            secondCategoryId: item.id
          },
          hash: '#categoryArea'
        }
      }
    }, [_vm._v(" " + _vm._s(item.categoryName) + " ")]);
  })], 2)], 1)], 1), _vm.pageInfo.categoryName.toLowerCase() === 'mac' ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3",
      "lg": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "内存",
      "items": (_vm$filter_list$find = _vm.filter_list.find(function (filter) {
        return filter.name === '内存';
      })) === null || _vm$filter_list$find === void 0 ? void 0 : _vm$filter_list$find.attributesValueList,
      "item-text": "valueInfo",
      "item-value": "valueId",
      "return-object": "",
      "clearable": ""
    },
    on: {
      "change": function change($event) {
        return _vm.getList();
      }
    },
    model: {
      value: _vm.query.memory,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "memory", $$v);
      },
      expression: "query.memory"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3",
      "lg": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "芯片",
      "items": (_vm$filter_list$find2 = _vm.filter_list.find(function (filter) {
        return filter.name === '芯片';
      })) === null || _vm$filter_list$find2 === void 0 ? void 0 : _vm$filter_list$find2.attributesValueList,
      "item-text": "valueInfo",
      "item-value": "valueId",
      "return-object": "",
      "clearable": ""
    },
    on: {
      "change": function change($event) {
        return _vm.getList();
      }
    },
    model: {
      value: _vm.query.chip,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "chip", $$v);
      },
      expression: "query.chip"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3",
      "lg": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "处理器",
      "items": (_vm$filter_list$find3 = _vm.filter_list.find(function (filter) {
        return filter.name === '处理器';
      })) === null || _vm$filter_list$find3 === void 0 ? void 0 : _vm$filter_list$find3.attributesValueList,
      "item-text": "valueInfo",
      "item-value": "valueId",
      "return-object": "",
      "clearable": ""
    },
    on: {
      "change": function change($event) {
        return _vm.getList();
      }
    },
    model: {
      value: _vm.query.processor,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "processor", $$v);
      },
      expression: "query.processor"
    }
  })], 1), _vm.price_limit ? _c('v-col', {
    staticClass: "align-content-center",
    attrs: {
      "cols": "12",
      "md": "3",
      "lg": "3"
    }
  }, [_c('v-slider', {
    attrs: {
      "color": "black",
      "track-color": "grey",
      "step": "100",
      "thumb-label": "",
      "hide-details": "",
      "min": _vm.price_limit.minPrice,
      "max": _vm.price_limit.maxPrice
    },
    on: {
      "change": function change($event) {
        return _vm.getList();
      }
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_vm._v("¥" + _vm._s(_vm.price_limit.minPrice))];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_vm._v("¥" + _vm._s(_vm.price_limit.maxPrice))];
      },
      proxy: true
    }], null, false, 2875467457),
    model: {
      value: _vm.query.price,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "price", $$v);
      },
      expression: "query.price"
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm.pageInfo.categoryName.toLowerCase() === 'ipad' ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3",
      "lg": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "存储容量",
      "items": (_vm$filter_list$find4 = _vm.filter_list.find(function (filter) {
        return filter.name === '存储容量';
      })) === null || _vm$filter_list$find4 === void 0 ? void 0 : _vm$filter_list$find4.attributesValueList,
      "item-text": "valueInfo",
      "item-value": "valueId",
      "return-object": "",
      "clearable": ""
    },
    on: {
      "change": function change($event) {
        return _vm.getList();
      }
    },
    model: {
      value: _vm.query.storage,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "storage", $$v);
      },
      expression: "query.storage"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3",
      "lg": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "外观",
      "items": (_vm$filter_list$find5 = _vm.filter_list.find(function (filter) {
        return filter.name === '外观';
      })) === null || _vm$filter_list$find5 === void 0 ? void 0 : _vm$filter_list$find5.attributesValueList,
      "item-text": "valueInfo",
      "item-value": "valueId",
      "return-object": "",
      "clearable": ""
    },
    on: {
      "change": function change($event) {
        return _vm.getList();
      }
    },
    model: {
      value: _vm.query.outward,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "outward", $$v);
      },
      expression: "query.outward"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3",
      "lg": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "屏幕尺寸",
      "items": (_vm$filter_list$find6 = _vm.filter_list.find(function (filter) {
        return filter.name === '屏幕尺寸';
      })) === null || _vm$filter_list$find6 === void 0 ? void 0 : _vm$filter_list$find6.attributesValueList,
      "item-text": "valueInfo",
      "item-value": "valueId",
      "return-object": "",
      "clearable": ""
    },
    on: {
      "change": function change($event) {
        return _vm.getList();
      }
    },
    model: {
      value: _vm.query.screensize,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "screensize", $$v);
      },
      expression: "query.screensize"
    }
  })], 1), _vm.price_limit ? _c('v-col', {
    staticClass: "align-content-center",
    attrs: {
      "cols": "12",
      "md": "3",
      "lg": "3"
    }
  }, [_c('v-slider', {
    attrs: {
      "color": "black",
      "track-color": "grey",
      "step": "100",
      "thumb-label": "",
      "hide-details": "",
      "min": _vm.price_limit.minPrice,
      "max": _vm.price_limit.maxPrice
    },
    on: {
      "change": function change($event) {
        return _vm.getList();
      }
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_vm._v("¥" + _vm._s(_vm.price_limit.minPrice))];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_vm._v("¥" + _vm._s(_vm.price_limit.maxPrice))];
      },
      proxy: true
    }], null, false, 2875467457),
    model: {
      value: _vm.query.price,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "price", $$v);
      },
      expression: "query.price"
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm.pageInfo.categoryName.toLowerCase() === 'iphone' ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "存储容量",
      "items": (_vm$filter_list$find7 = _vm.filter_list.find(function (filter) {
        return filter.name === '存储容量';
      })) === null || _vm$filter_list$find7 === void 0 ? void 0 : _vm$filter_list$find7.attributesValueList,
      "item-text": "valueInfo",
      "item-value": "valueId",
      "return-object": "",
      "clearable": ""
    },
    on: {
      "change": function change($event) {
        return _vm.getList();
      }
    },
    model: {
      value: _vm.query.storage,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "storage", $$v);
      },
      expression: "query.storage"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "颜色",
      "items": (_vm$filter_list$find8 = _vm.filter_list.find(function (filter) {
        return filter.name === '颜色';
      })) === null || _vm$filter_list$find8 === void 0 ? void 0 : _vm$filter_list$find8.attributesValueList,
      "item-text": "valueInfo",
      "item-value": "valueId",
      "return-object": "",
      "clearable": ""
    },
    on: {
      "change": function change($event) {
        return _vm.getList();
      }
    },
    model: {
      value: _vm.query.color,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "color", $$v);
      },
      expression: "query.color"
    }
  })], 1), _vm.price_limit ? _c('v-col', {
    staticClass: "align-content-center",
    attrs: {
      "cols": "12",
      "md": "4",
      "lg": "4"
    }
  }, [_c('v-slider', {
    attrs: {
      "color": "black",
      "track-color": "grey",
      "step": "100",
      "thumb-label": "",
      "hide-details": "",
      "min": _vm.price_limit.minPrice,
      "max": _vm.price_limit.maxPrice
    },
    on: {
      "change": function change($event) {
        return _vm.getList();
      }
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_vm._v("¥" + _vm._s(_vm.price_limit.minPrice))];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_vm._v("¥" + _vm._s(_vm.price_limit.maxPrice))];
      },
      proxy: true
    }], null, false, 2875467457),
    model: {
      value: _vm.query.price,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "price", $$v);
      },
      expression: "query.price"
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm.pageInfo.categoryName.toLowerCase() === 'watch' ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3",
      "lg": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "连接",
      "items": (_vm$filter_list$find9 = _vm.filter_list.find(function (filter) {
        return filter.name === '连接';
      })) === null || _vm$filter_list$find9 === void 0 ? void 0 : _vm$filter_list$find9.attributesValueList,
      "item-text": "valueInfo",
      "item-value": "valueId",
      "return-object": "",
      "clearable": ""
    },
    on: {
      "change": function change($event) {
        return _vm.getList();
      }
    },
    model: {
      value: _vm.query.connect,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "connect", $$v);
      },
      expression: "query.connect"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3",
      "lg": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "外观",
      "items": (_vm$filter_list$find10 = _vm.filter_list.find(function (filter) {
        return filter.name === '外观';
      })) === null || _vm$filter_list$find10 === void 0 ? void 0 : _vm$filter_list$find10.attributesValueList,
      "item-text": "valueInfo",
      "item-value": "valueId",
      "return-object": "",
      "clearable": ""
    },
    on: {
      "change": function change($event) {
        return _vm.getList();
      }
    },
    model: {
      value: _vm.query.outward,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "outward", $$v);
      },
      expression: "query.outward"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3",
      "lg": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "表壳尺寸",
      "items": (_vm$filter_list$find11 = _vm.filter_list.find(function (filter) {
        return filter.name === '表壳尺寸';
      })) === null || _vm$filter_list$find11 === void 0 ? void 0 : _vm$filter_list$find11.attributesValueList,
      "item-text": "valueInfo",
      "item-value": "valueId",
      "return-object": "",
      "clearable": ""
    },
    on: {
      "change": function change($event) {
        return _vm.getList();
      }
    },
    model: {
      value: _vm.query.casesize,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "casesize", $$v);
      },
      expression: "query.casesize"
    }
  })], 1), _vm.price_limit ? _c('v-col', {
    staticClass: "align-content-center",
    attrs: {
      "cols": "12",
      "md": "3",
      "lg": "3"
    }
  }, [_c('v-slider', {
    attrs: {
      "color": "black",
      "track-color": "grey",
      "step": "100",
      "thumb-label": "",
      "hide-details": "",
      "min": _vm.price_limit.minPrice,
      "max": _vm.price_limit.maxPrice
    },
    on: {
      "change": function change($event) {
        return _vm.getList();
      }
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_vm._v("¥" + _vm._s(_vm.price_limit.minPrice))];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_vm._v("¥" + _vm._s(_vm.price_limit.maxPrice))];
      },
      proxy: true
    }], null, false, 2875467457),
    model: {
      value: _vm.query.price,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "price", $$v);
      },
      expression: "query.price"
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm.pageInfo.categoryName.toLowerCase() === 'airpods' ? _c('v-row') : _vm._e(), _vm.pageInfo.categoryName.toLowerCase() === '配件' ? _c('v-row') : _vm._e(), _c('v-row', _vm._l(_vm.product_list, function (item, index) {
    return _c('v-col', {
      key: 'product_' + index,
      attrs: {
        "cols": "12",
        "md": "3",
        "lg": "3"
      }
    }, [_c('v-card', {
      attrs: {
        "flat": "",
        "hover": "",
        "to": {
          path: '/product/' + encodeURIComponent(item.firstCategory.toLowerCase()) + '/' + encodeURIComponent(item.secondCategory.toLowerCase()) + '/' + encodeURIComponent(item.productName.toLowerCase()),
          query: {
            brandId: item.brandId,
            firstCategoryId: item.firstCategoryId,
            secondCategoryId: item.secndCatoegoryId,
            id: item.id
          }
        }
      }
    }, [_c('v-img', {
      staticClass: "mb-n4",
      attrs: {
        "src": item === null || item === void 0 ? void 0 : item.skuPicList[0],
        "height": "160px",
        "contain": ""
      }
    }), _c('v-card-title', {
      staticClass: "justify-center"
    }, [_c('span', {
      staticClass: "col-12 text-truncate text-center"
    }, [_vm._v(_vm._s(item.productName))]), _c('span', {
      staticClass: "mt-n4 subtitle-2 col-12 text-truncate grey--text text-center"
    }, [_vm._v(_vm._s(item.skuName))]), _c('p', {
      staticClass: "text-h4 primary--text col-12 text-center"
    }, [_vm._v("¥" + _vm._s(item.salesPrice))]), _c('p', {
      staticClass: "mt-n8 subtitle-2 primary--text col-12 text-center"
    }, [_vm._v("租赁价 ¥" + _vm._s(item.leasePrice))])])], 1)], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }