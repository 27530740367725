<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-card flat>
          <v-img src="/static/after-sales/after_sale_banner.png" class="white--text align-end">
          </v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mt-16 mb-4">
        <p class="text-center text-h4 text-md-h3 font-weight-bold mt-4">售后和管理</p>
      </v-col>
      <v-col cols="12" class="mb-16">
        <p class="text-center text-md-h5 my-4 grey--text text-pretty">
          倍升互联同样提供专业便捷的售后服务及管理，<br />
          实时线上线下的专业级软硬件支持，为企业省去后顾之忧。
        </p>
      </v-col>
      <v-col cols="12" class="text-center mb-12">
        <v-menu v-if="!isAuthenticated" transition="scroll-y-transition" min-width="100" :nudge-width="100" top offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              x-large
              depressed
              color="primary"
              class="rounded-xl large-btn"
              v-bind="attrs"
              v-on="on"
            >
              马上申报服务
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              请登录您的专属账户
            </v-card-text>
            <v-card-actions class="text-center">
              <v-btn
                :to="{ path: '/account' }"
                depressed
                plat
                >注册</v-btn>
              <v-btn
                :to="{ path: '/login' }"
                depressed
                plat
                >登录</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        <v-btn
          v-if="isAuthenticated"
          x-large
          depressed
          color="primary"
          class="rounded-xl large-btn"
          v-log="'AFTER_SALES_WITH_SERVICE'"
          @click="showServiceDialog = true"
        >
          马上申报服务
        </v-btn>
        <contact-service :showDialog="showServiceDialog" @dialogCloseEvent="showServiceDialog = false"></contact-service>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-tabs
          centered
          plain
          transition="fade-transition"
          prev-icon="chevron_left"
          next-icon="chevron_right"
          show-arrows
        >
          <v-tab :class="[$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']">
            运营维护
          </v-tab>
          <v-tab :class="[$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']">
            设备部署
          </v-tab>
          <v-tab :class="[$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']">
            检测维修
          </v-tab>

          <!--运营维护-->
          <v-tab-item transition="fade-transition">
            <v-card flat>
              <v-card-title class="mt-16 mb-4 text-h3">
                <span class="activity-title text-h4 text-md-h3 font-weight-bold">运营维护</span>
              </v-card-title>
              <v-card-subtitle class="text-center page-subtitle">
                <span>倍升互联提供的服务选择</span>
              </v-card-subtitle>

              <v-card-text class="mt-4 mb-4">
                <v-row>
                  <v-col cols="12" class="page-h4 mt-4 mb-4">
                    <span>移动设备全生命周期服务</span>
                  </v-col>
                  <v-col cols="12" class="page-content">
                    <p>
                      即从新设备采购到搭建移动化办公环境，再到设备使用期间的运维管理，直至退出使用，设备整个生命期间的一站式服务。<br />
                      采用现代化方法来管理设备生命周期，企业不仅可以放心地让员工进行更新, 还能增强数据安全性、提高生产力。最大限度地延长设备正常运行时间并提升员工满意度。
                    </p>
                  </v-col>
                </v-row>

                <v-card flat>
                  <v-card flat class="d-flex justify-space-between align-center operImgList">
                    <img class="operImg" src="/static/after-sales/s1.png" />
                    <img class="operImg" src="/static/after-sales/s2.png" />
                    <img class="operImg" src="/static/after-sales/s3.png" />
                    <img class="operImg" src="/static/after-sales/s4.png" />
                    <img class="operImg" src="/static/after-sales/s5.png" />
                  </v-card>
                  <v-card flat>
                    <!-- <img class="operLine" src="/static/after-sales/oper_line.png" /> -->
                    <v-stepper flat :vertical="true">
                      <v-stepper-header class="elevation-0">
                        <v-stepper-step step="" color="#b2b2b2" class="text-center">
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="" color="#b2b2b2">
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="" color="#b2b2b2">
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="" color="#b2b2b2">
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="" color="#b2b2b2">
                        </v-stepper-step>
                      </v-stepper-header>
                    </v-stepper>
                  </v-card>
                  <v-card flat class="d-flex justify-space-between">
                    <v-card flat>
                      <span :class="[$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']">设备采购</span>
                      <p :class="[$vuetify.breakpoint.mobile ? 'page-ms-content' : 'page-content']">
                        成熟度评估<br />
                        方案设计<br />
                        硬件采购<br />
                        设备租赁
                      </p>
                    </v-card>
                    <v-card flat>
                      <span :class="[$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']">激活/部署</span>
                      <p :class="[$vuetify.breakpoint.mobile ? 'page-ms-content' : 'page-content']">
                        APP 安装<br />
                        资产管理<br />
                        系统设置<br />
                        EMM 注册
                      </p>
                    </v-card>
                    <v-card flat>
                      <span :class="[$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']">发放/培训</span>
                      <p :class="[$vuetify.breakpoint.mobile ? 'page-ms-content' : 'page-content']">
                        全国范围<br />
                        集中发放<br />
                        上门发放<br />
                        用户指导
                      </p>
                    </v-card>
                    <v-card flat>
                      <span :class="[$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']">运维/管理</span>
                      <p :class="[$vuetify.breakpoint.mobile ? 'page-ms-content' : 'page-content']">
                        热线支持<br />
                        诊断排障<br />
                        备机服务<br />
                        ESC/ASP
                      </p>
                    </v-card>
                    <v-card flat>
                      <span :class="[$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']">设备回收</span>
                      <p :class="[$vuetify.breakpoint.mobile ? 'page-ms-content' : 'page-content']">
                        数据擦除<br />
                        残值回收<br />
                        设备销毁<br />
                        设备捐赠
                      </p>
                    </v-card>
                  </v-card>
                </v-card>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!--设备部署-->
          <v-tab-item transition="fade-transition">
            <v-card flat>
              <v-card-title class="mt-16 mb-4 text-h3">
                <span class="activity-title text-h4 text-md-h3 font-weight-bold">设备部署</span>
              </v-card-title>
              <v-card-text class="page-content">
                <p>
                  我们一直在帮助成长型企业有效地管理他们的设备。从将新的设备资产与现有 IT 基础设施集成，到确保所有设备都得到妥善管理和安全。我们帮助您确定并执行最佳的部署工作流程，以满足您的业务需求。<br /><br />
                  您无需在设备上手动操作，即享受高级别的控制权限和安全级别，并确定所有用户在拿到设备时，开箱即用！在部署过程的每一步我们都将为您提供支持。
                </p>
              </v-card-text>

              <v-card-text class="mt-4 mb-4">
                <v-row>
                  <v-col cols="12" md="4" class="page-h5 text-center">
                    <span><v-img class="image-deploy" style="margin: auto;" src="/static/after-sales/d1.png" /></span>
                    <span style="line-height: 40px;">零接触部署</span>
                  </v-col>
                  <v-col cols="12" md="4" class="page-h5 text-center">
                    <v-img class="image-deploy" style="margin: auto;" src="/static/after-sales/d2.png" />
                    <span style="line-height: 40px;">自动化部署</span>
                  </v-col>
                  <v-col cols="12" md="4" class="page-h5 text-center">
                    <v-img class="image-deploy" style="margin: auto;" src="/static/after-sales/d3.png" />
                    <span style="line-height: 40px;">自动化部署</span>
                  </v-col>
                </v-row>
                <v-row class="mt-16">
                  <v-col cols="12" class="page-h5">
                    <span>初始化部署服务</span>
                  </v-col>
                  <v-col cols="12" class="page-content">
                    <p>
                      设备初始化、设备监管、Apple ID 申请、MDM 账号激活、批量注册、自动化部署、软件预装、系统设置、资产标签、系统升级。
                    </p>
                  </v-col>
                  <v-col cols="12" class="page-h5">
                    <span>集中发放</span>
                  </v-col>
                  <v-col cols="12" class="page-content">
                    <p>
                      专业工程师到客户现场，组织会议集中发放，并提供简单培训及技术支持完成发放。
                    </p>
                  </v-col>
                  <v-col cols="12" class="page-h5">
                    <span>市区内上门发放</span>
                  </v-col>
                  <v-col cols="12" class="page-content">
                    <p>
                      为客户安排工程师上门提供设备发放，并提供技术方面的解答。
                    </p>
                  </v-col>
                  <v-col cols="12" class="page-h5">
                    <span>用户自提</span>
                  </v-col>
                  <v-col cols="12" class="page-content">
                    <p>
                      用户可选择前往倍升互联服务网点领取已部署的设备。
                    </p>
                  </v-col>
                  <v-col cols="12" class="page-h5">
                    <span>产品培训</span>
                  </v-col>
                  <v-col cols="12" class="page-content">
                    <p>
                      为客户提供办公软件培训及技术支持。
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!--检测维修-->
          <v-tab-item transition="fade-transition">
            <v-card flat>
              <v-card-title class="mt-16 mb-4 text-h3">
                <span class="activity-title text-h4 text-md-h3 font-weight-bold">检测维修</span>
              </v-card-title>
              <v-row>
                <v-col cols="12">
                  <v-img src="/static/after-sales/in_banner.png" class="white--text"></v-img>
                </v-col>
              </v-row>

              <v-card-text class="mt-4 mb-4">
                <v-row>
                  <v-col cols="12" class="page-content">
                    <p>
                      我们是 Apple 授权服务商，拥有经验丰富的专业检测维修团队，随时随地可以帮您解决产品检测和维修上的任何问题。无论是电池、屏幕还是内部工作问题，还是进行升级、数据传输、数据恢复甚至健康检查，只要您需要帮助，我们随时为您提供服务。
                    </p>
                  </v-col>
                  <v-col cols="12" class="page-content text-center">
                    <p>
                      标准硬件维修 / 企业定制服务 / 增值服务方案
                    </p>
                  </v-col>
                  <v-col cols="3" class="page-content text-center">
                    <img class="image-main" src="/static/after-sales/i1.png" />
                    <p>
                      Apple认证工程师
                    </p>
                  </v-col>
                  <v-col cols="3" class="page-content text-center">
                    <img class="image-main" src="/static/after-sales/i2.png" />
                    <p>
                      专业工具
                    </p>
                  </v-col>
                  <v-col cols="3" class="page-content text-center">
                    <img class="image-main" src="/static/after-sales/i3.png" />
                    <p>
                      官方系统
                    </p>
                  </v-col>
                  <v-col cols="3" class="page-content text-center">
                    <img class="image-main" src="/static/after-sales/i4.png" />
                    <p>
                      标准维修流程
                    </p>
                  </v-col>

                  <v-col cols="4" class="page-content text-center">
                    <img class="image-main" src="/static/after-sales/i5.png" />
                    <p>
                      安全与隐私
                    </p>
                  </v-col>
                  <v-col cols="4" class="page-content text-center">
                    <img class="image-main" src="/static/after-sales/i6.png" />
                    <p>
                      服务与质量管控
                    </p>
                  </v-col>
                  <v-col cols="4" class="page-content text-center">
                    <img class="image-main" src="/static/after-sales/i7.png" />
                    <p>
                      官方统一价格
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
.page-h1 {
  font-weight: 600;
  font-size: 56px;
  line-height: 62px;
}
.page-h2 {
  font-weight: 500;
  font-size: 48px;
}
.page-h3 {
  font-weight: 500;
  font-size: 36px;
  line-height: 31px;
}
.page-h4 {
  font-weight: 500;
  font-size: 26px;
}
.page-h5 {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
}
.page-h6 {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
}
.page-tab {
  font-weight: 500;
  font-size: 26px;
}

.page-subtitle {
  font-weight: 500;
  font-size: 22px;
}
.page-content {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
}
.page-m-content {
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
}

.page-ms-content {
  font-weight: 300;
  font-size: 12px;
  line-height: 30px;
}
.page-btn {
  font-weight: 400;
  font-size: 24px;
}

.page-list {
  li {
    list-style: none;
  }
}

.activity-title {
  margin-left: auto;
  margin-right: auto;
}
.large-btn {
  width: 300px;
  font-size: 24px;
}
.tab-title {
  font-weight: 500;
  font-size: 24px;
  padding: 10px 40px;
  border-bottom: 1px solid #d9d9d9;
}

.tab-mobile-title {
  padding: 10px 10px;
  border-bottom: 1px solid #d9d9d9;
}

.grey-block {
  background-color: #f5f5f7;
  color: #0573d9;
  border-radius: 24px;
  padding: 30px 40px;
}

.v-stepper__step {
  &:nth-child(1) {
    padding: 24px 24px 16px 0;
  }
}

.operImgList {
  width: 910px;
  margin: 0 auto;
}

.operImg {
  height: 98px;
}

.operLine {
  width: 95%;
  height: 20px;
  margin: 20px 20px;
}

.image-deploy {
  width: 250px;
  height: 220px;
  margin: 20px 20px 0 20px;
  border: 1px solid rgba(0, 0, 0, .1);
}

.image-main {
  height: 80px;
}

@media screen and (max-width:600px){
  .operImg {
    height: 60px;
  }

  .image-main {
    height: 60px;
  }

  .text-pretty {
    word-break: keep-all;
  }
}
</style>

<script>
import {
  mapGetters
} from "vuex";

export default {
  data() {
    return {
      showServiceDialog: false,
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
}
</script>
