<template>
  <v-card>
    <v-container>
      <v-card-title class="text-h5 justify-center font-weight-black">
        你的购物袋共计 {{cart_list.productCount}} 件商品， 总计 RMB {{cart_list.priceTotal | formatMoney}}
        <v-spacer></v-spacer>
        <v-btn depressed rounded color="primary" v-if="!$vuetify.breakpoint.mobile" @click="openContact()">提交订单</v-btn>
      </v-card-title>
      <v-card-text v-if="isAuthenticated">
        <v-row v-for="(item, index) in cart_list.cartProductList" :key="'cart_card_'+ index" class="mt-4">
          <v-col cols="12" md="2" lg="2"><v-img contain :src="item.skuList[0].picUrlList[0]" max-width="150px" max-height="150px"></v-img></v-col>
          <v-col cols="12" md="5" lg="5">
            <p class="col-12 text-truncate text-h6 ml-n3">{{item.skuList[0].skuName}}</p>
            <v-row no-gutters v-for="(attr, j) in item.skuList[0].attrList" :key="'attr_'+ index + '_'+ j">
              <v-col cols="6" lg="2" md="2" class="subtitle-2">{{ attr.attrName }}</v-col>
              <v-col cols="6" lg="10" md="10">{{ attr.attrValue }}</v-col>
            </v-row>
          </v-col>
          <v-col cols="6" md="2" lg="2">
            <v-text-field
              v-model="item.count"
              type="number"
              :value="item.count"
              :min="0"
              prepend-inner-icon="remove"
              append-icon="add"
              style="max-width: 100px"
              @change="setProductCount('change', index)"
              @click:append="setProductCount('add', index)"
              @click:prepend-inner="item.count > 1 ? setProductCount('remove', index) : ''"
            ></v-text-field>
            <v-chip v-if="item.purchaseType === 0" small>{{item.leasePayMethod}}</v-chip>
            <v-chip v-if="item.purchaseType === 0" small class="mx-2">{{ item.leasePeriod }}月</v-chip>
          </v-col>
          <v-col cols="6" md="3" lg="3" class="text-end">
            <p v-if="item.purchaseType === 1" class="text-h6">RMB {{item.skuList[0].enterpriseSalesPrice * item.count | formatMoney}} </p>
            <p v-else class="text-h6">RMB {{item.skuList[0].enterpriseLeasePrice * item.count | formatMoney}}/月</p>
            <p v-if="item.purchaseType === 1" class="subtitle-2">租赁可享RMB {{item.skuList[0].enterpriseLeasePrice}}/月（24期）起</p>
            <p v-else class="subtitle-2">采购可享RMB {{item.skuList[0].enterpriseSalesPrice | formatMoney}}</p>
            <v-btn plain :ripple="false" color="primary" @click="removeProduct(item.cartId)">移除</v-btn>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="subtitle-1 font-weight-bold">租赁商品金额</v-col>
          <v-col cols="6" class="text-end subtitle-1">
            <p>合计每月RMB {{cart_list.leasePriceTotalMonthPrice | formatMoney}}/月</p>
            <p>总租金RMB {{cart_list.leasePriceTotalPrice | formatMoney}}</p>
          </v-col>
          <v-col cols="6" class="subtitle-1 font-weight-bold">采购商品金额</v-col>
          <v-col cols="6" class="text-end subtitle-1">
            <p>RMB {{cart_list.salesPriceTotal | formatMoney}}</p>
          </v-col>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="6" class="text-h5 font-weight-bold">总计</v-col>
          <v-col cols="6" class="text-end text-h5 font-weight-bold">
            <p>RMB {{cart_list.priceTotal | formatMoney}}</p>
          </v-col>
          <v-col cols="12" class="text-end">
            <v-btn depressed rounded color="primary" :block="$vuetify.breakpoint.mobile" @click="openContact()">提交订单</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else>无数据</v-card-text>
      <v-bottom-sheet v-model="dlgContact" persistent>
        <v-card>
          <v-container>
            <v-form v-model="valid" @submit.prevent="submit">
              <v-card-title>
                提交订单
                <v-spacer></v-spacer>
                <v-btn icon @click="closeContact()"><v-icon>close</v-icon></v-btn>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.userNameInput"
                      :rules="[rules.required]"
                      type="text"
                      label="姓名"
                      hint="请输入您的姓名"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.emailInput"
                      :rules="[rules.required, rules.email]"
                      type="text"
                      label="邮箱"
                      hint="请输入电子邮箱"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.telInput"
                      :rules="[rules.required, rules.phonenum]"
                      type="text"
                      label="电话"
                      hint="请输入联系电话"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.enterpriseFullNameInput"
                      :rules="[rules.required]"
                      type="text"
                      label="公司"
                      hint="请输入您的公司"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="editedItem.remark"
                      :rules="[rules.required]"
                      type="text"
                      label="需求"
                      hint="请输入您的需求"
                      outlined
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" class="d-flex align-center">
                    <v-checkbox
                      v-model="editedItem.agree"
                      :rules="[rules.agree]"
                    >
                      <template v-slot:label>
                        请
                        <a target="_blank" href="/privacy" @click.stop> 点击这里 </a>
                        查看并确认您已阅读并同意我们的条款和条件及隐私政策
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeContact()">取消</v-btn>
                <v-btn depressed rounded color="primary" @click="submit" :disabled="!valid">提交</v-btn>
              </v-card-actions>
            </v-form>
          </v-container>
        </v-card>
      </v-bottom-sheet>
    </v-container>
  </v-card>
</template>
<script>
import {mapGetters} from "vuex";
import store from "@/store";
import {
  FETCH_CART_LIST,
  DEL_PROD_CART,
  ADD_PROD_CART,
  CREATE_NEW_ORDER
} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";

export default {
  data() {
    return {
      dlgContact: false,
      editedItem: {
        type: 0,
        emailInput: '',
        enterpriseFullNameInput: '',
        remark: '',
        telInput: '',
        userNameInput: '',
        agree: false
      },
      valid: true,
      rules: {
        agree: value => (value === 0 || !!value) || '请勾选同意条款.',
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
      },
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'cart_list']),
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      if (this.isAuthenticated) store.dispatch(FETCH_CART_LIST, '')
    },
    removeProduct(id) {
      store.dispatch(DEL_PROD_CART, id)
        .then(() => {
          store.dispatch(FETCH_CART_LIST, '')
        })
    },
    setProductCount(operate, index) {
      const objProduct = this.cart_list.cartProductList[index]
      const objOperate = {
        "purchaseType": objProduct.purchaseType,
        "operateType": null,
        "count": 1,
        "skuId": objProduct.skuList[0].id,
        "spuId": objProduct.id,
        "leasePayMethod": objProduct?.leasePayMethod || null,
        "leasePeriod": objProduct?.leasePeriod || null
      }
      if (operate === 'add') {
        objOperate.operateType = 'ADD'
        this.cart_list.cartProductList[index].count++
      }
      if (operate === 'remove') {
        objOperate.operateType = 'SUBTRACT'
        this.cart_list.cartProductList[index].count--
      }
      if (operate === 'change') {
        if (this.cart_list.cartProductList[index].count > 0) {
          objOperate.operateType = 'SET'
          objOperate.count =+ this.cart_list.cartProductList[index].count
        }
      }
      if (objOperate.operateType) {
        store.dispatch(ADD_PROD_CART, objOperate)
          .then(() => {
            this.initialize()
          })
      } else {
        store.commit(SET_ERROR, {msg: '请输入0以上数字', color: 'primary'});
      }

    },
    closeCard() {
      this.$emit('Close')
    },
    openContact() {
      this.dlgContact = true
    },
    closeContact() {
      this.dlgContact = false
    },
    submit() {
      const createData = Object.assign({}, this.editedItem)
      const productList = this.cart_list.cartProductList.map(item => {
        return {
          cartId: item.cartId,
          count: item.count,
          enterpriseLeasePrice: item.skuList[0].enterpriseLeasePrice,
          enterpriseMarketPrice: item.skuList[0].enterpriseMarketPrice,
          enterpriseSalesPrice: item.skuList[0].enterpriseSalesPrice,
          leasePrice: item.skuList[0].leasePrice,
          marketPrice: item.skuList[0].marketPrice,
          purchaseType: item.purchaseType,
          salesPrice: item.skuList[0].salesPrice,
          skuId: item.skuList[0].id,
          spuId: item.id,
        }
      })
      createData.productRequestList = productList

      store.dispatch(CREATE_NEW_ORDER, createData)
        .then(() => {
          this.$router.push('/order-list').catch(err => {
            if (err.name !== 'NavigationDuplicated') {
              throw err;
            }
          });
          window.location.reload();
        })
    },
  }
}
</script>
<style lang="scss" scoped>
ul li {
  list-style: none;
}
</style>
