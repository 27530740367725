var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-img', {
    staticClass: "white--text align-end",
    attrs: {
      "src": "/static/activity/act-banner.png"
    }
  })], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "mt-16 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-center text-h4 text-md-h3 font-weight-bold mt-4"
  }, [_vm._v("活动和培训")])]), _c('v-col', {
    staticClass: "mb-16",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-center text-md-h5 my-4 grey--text text-pretty"
  }, [_vm._v(" 我们提供专业且丰富的行业分享，及移动设备管理培训。"), _c('br'), _vm._v(" 帮助企业IT人员快速对企业设备问题提供支持和相应，强力赋能企业效率 ")])]), _c('v-col', {
    staticClass: "text-center mb-12",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    directives: [{
      name: "log",
      rawName: "v-log",
      value: 'ACTIVITY_WITH_CONTACT',
      expression: "'ACTIVITY_WITH_CONTACT'"
    }],
    staticClass: "rounded-xl large-btn",
    attrs: {
      "x-large": "",
      "depressed": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.contactDialog = true;
      }
    }
  }, [_vm._v(" 与我们取得联系 ")])], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('v-tabs', {
    attrs: {
      "centered": "",
      "plain": "",
      "transition": "fade-transition",
      "prev-icon": "chevron_left",
      "next-icon": "chevron_right",
      "show-arrows": ""
    }
  }, [_c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']
  }, [_vm._v("Apple 培训")]), _c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']
  }, [_vm._v("Jamf 培训")]), _c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']
  }, [_vm._v("市场和活动")]), _c('v-tab-item', {
    attrs: {
      "transition": _vm.fade - _vm.transition
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    class: ['mt-16', 'mb-16', _vm.$vuetify.breakpoint.mobile ? 'text-h5' : 'text-h3']
  }, [_c('span', {
    staticClass: "activity-title text-h5 text-md-h4 font-weight-bold"
  }, [_vm._v("Apple Professional Training")])]), _c('v-card-subtitle', {
    staticClass: "text-center activity-subtitle text-h6 mb-12"
  }, [_vm._v(" 无论你是 Help Desk 人员，还是在企业中管理设备， Apple Professional Training 项目的 IT 课程都为你提供了这些角色所需的知识和技能， 帮助你了解掌握如何支持和大规模部署管理 Apple 设备的内容。 ")]), _c('v-card-title', [_c('span', {
    staticClass: "activity-title text-h4 text-md-h3 font-weight-bold mb-4"
  }, [_vm._v("Apple 设备支持")])]), _c('v-divider'), _c('v-card-text', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/activity/h1.png"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('span', {
    staticClass: "page-h5"
  }, [_vm._v("课程内容")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('p', {
    staticClass: "page-content"
  }, [_vm._v("介绍用于为组织内的 Mac、iPhone 和 iPad 用户提供支持的工具和服务。展示如何为 Apple 设备提供支持和进行故障诊断的最佳方法。")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('span', {
    staticClass: "page-h5"
  }, [_vm._v("考试认证")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('p', {
    staticClass: "text-md-h5 my-2 grey--text"
  }, [_vm._v("Apple Certified Support Professional 认证")])])], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-list', {
    attrs: {
      "two-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']
  }, [_vm._v("2024 年 8 月 9 日 - 8 月 11 日")]), _c('v-list-item-subtitle', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']
  }, [_vm._v("北京 ｜ 9:00 - 18:00")])], 1), _c('v-list-item-action', [_c('v-btn', {
    staticClass: "page-h5",
    attrs: {
      "color": "primary",
      "x-large": ""
    },
    on: {
      "click": function click($event) {
        _vm.contactDialog = true;
      }
    }
  }, [_vm._v("报名")])], 1)], 1), _c('v-divider'), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']
  }, [_vm._v("2024 年 9 月 20 日 - 9 月 22 日")]), _c('v-list-item-subtitle', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']
  }, [_vm._v("北京 ｜ 9:00 - 18:00")])], 1), _c('v-list-item-action', [_c('v-btn', {
    staticClass: "page-h5",
    attrs: {
      "color": "primary",
      "x-large": ""
    },
    on: {
      "click": function click($event) {
        _vm.contactDialog = true;
      }
    }
  }, [_vm._v("报名")])], 1)], 1)], 1)], 1), _c('v-card-title', [_c('span', {
    staticClass: "activity-title text-h4 text-md-h3 font-weight-bold mb-4"
  }, [_vm._v("Apple 设备管理与部署")])]), _c('v-divider'), _c('v-card-text', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/activity/h2.png"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-h6",
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('span', {
    staticClass: "page-h5"
  }, [_vm._v("课程内容")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('p', {
    staticClass: "page-content"
  }, [_vm._v("介绍用于为组织内的 Mac、iPhone 和 iPad 用户提供支持的工具和服务。展示如何为 Apple 设备提供支持和进行故障诊断的最佳方法。")])]), _c('v-col', {
    staticClass: "text-h6",
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('span', {
    staticClass: "page-h5"
  }, [_vm._v("考试认证")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('p', {
    staticClass: "page-content"
  }, [_vm._v("Apple Certified Support Professional 认证")])])], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-list', {
    attrs: {
      "two-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']
  }, [_vm._v("2024 年 8 月 9 日 - 8 月 11 日")]), _c('v-list-item-subtitle', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']
  }, [_vm._v("北京 ｜ 9:00 - 18:00")])], 1), _c('v-list-item-action', [_c('v-btn', {
    staticClass: "page-h5",
    attrs: {
      "color": "primary",
      "x-large": ""
    },
    on: {
      "click": function click($event) {
        _vm.contactDialog = true;
      }
    }
  }, [_vm._v("报名")])], 1)], 1), _c('v-divider'), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']
  }, [_vm._v("2024 年 9 月 20 日-9 月 22 日")]), _c('v-list-item-subtitle', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']
  }, [_vm._v("北京 ｜ 9:00 - 18:00")])], 1), _c('v-list-item-action', [_c('v-btn', {
    staticClass: "page-h5",
    attrs: {
      "color": "primary",
      "x-large": ""
    },
    on: {
      "click": function click($event) {
        _vm.contactDialog = true;
      }
    }
  }, [_vm._v("报名")])], 1)], 1)], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "transition": _vm.fade - _vm.transition
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "mt-16 text-h3"
  }, [_c('span', {
    staticClass: "activity-title text-h4 text-md-h3 font-weight-bold"
  }, [_vm._v("Jamf 培训")])]), _c('v-card-subtitle', {
    staticClass: "text-center activity-subtitle text-h5 mb-12"
  }, [_vm._v(" 由于专注于 Apple 生态系统，全球的行业领导者选择 Jamf： ")]), _c('v-card-subtitle', {
    staticClass: "activity-subtitle text-h6 mb-2"
  }, [_vm._v(" Jamf 是唯一可远程连接、管理和保护 Apple 用户、设备和服务的大规模 Apple 企业管理解决方案，提供以 Jamf Pro 管理 macOS、iOS 与 tvOS 的实操培训，提高生产力、协助使用者达成更多、 掌握最佳实践。持续提升技术人员的技能，帮助您使用 apple 工作达到巅峰。 ")]), _c('v-img', {
    attrs: {
      "src": "/static/activity/h3.png"
    }
  }), _c('v-card-subtitle', {
    staticClass: "activity-subtitle text-h6 mb-2"
  }, [_vm._v(" Jamf 独特的培训方法 ")]), _c('v-card-text', [_c('p', {
    staticClass: "page-content"
  }, [_vm._v("Jamf 培训课程中的回应教学法，提供以 Jamf Pro 管理 macOS、iOS 与 tvOS 的实操培训。现在就成为您机构内不可或缺的一员，花更少的时间弄清楚如何做某事，而花更多的时间去完成它。")])]), _c('v-card-subtitle', {
    staticClass: "activity-subtitle text-h6 mb-2"
  }, [_vm._v(" Jamf 培训课程有助于 ")]), _c('v-card-text', [_c('p', {
    staticClass: "page-content"
  }, [_vm._v(" 1 、提高生产力"), _c('br'), _vm._v(" 2、赋能最终客户"), _c('br'), _vm._v(" 3、掌握最佳实践"), _c('br')])]), _c('v-divider'), _c('v-card-text', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/activity/h4.png"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-h6",
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('span', {
    staticClass: "page-h5"
  }, [_vm._v("课程内容")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('p', {
    staticClass: "page-content"
  }, [_vm._v("介绍用于为组织内的 Mac、iPhone 和 iPad 用户提供支持的工具和服务。展示如何为 Apple 设备提供支持和进行故障诊断的最佳方法。")])]), _c('v-col', {
    staticClass: "text-h6",
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('span', {
    staticClass: "page-h5"
  }, [_vm._v("考试认证")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('p', {
    staticClass: "page-content"
  }, [_vm._v("Apple Certified Support Professional 认证")])])], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-list', {
    attrs: {
      "two-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']
  }, [_vm._v("2024 年 8 月 9 日 - 8 月 11 日")]), _c('v-list-item-subtitle', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']
  }, [_vm._v("北京 ｜ 9:00 - 18:00")])], 1), _c('v-list-item-action', [_c('v-btn', {
    staticClass: "page-h5",
    attrs: {
      "color": "primary",
      "x-large": ""
    },
    on: {
      "click": function click($event) {
        _vm.contactDialog = true;
      }
    }
  }, [_vm._v("报名")])], 1)], 1), _c('v-divider'), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']
  }, [_vm._v("2024 年 9 月 20 日 - 9 月 22 日")]), _c('v-list-item-subtitle', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']
  }, [_vm._v("北京 ｜ 9:00 - 18:00")])], 1), _c('v-list-item-action', [_c('v-btn', {
    staticClass: "page-h5",
    attrs: {
      "color": "primary",
      "x-large": ""
    },
    on: {
      "click": function click($event) {
        _vm.contactDialog = true;
      }
    }
  }, [_vm._v("报名")])], 1)], 1)], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "transition": _vm.fade - _vm.transition
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "mt-16 text-h3"
  }, [_c('span', {
    staticClass: "activity-title text-h4 text-md-h3 font-weight-bold"
  }, [_vm._v("市场活动")])]), _c('v-card-subtitle', {
    staticClass: "text-center activity-subtitle text-h5 mb-12"
  }, [_vm._v(" Apple 在商务中的应用 ")]), _c('v-card-title', {
    staticClass: "mt-16 text-h4"
  }, [_c('span', {
    staticClass: "activity-title"
  }, [_vm._v("iOS 助力保险行业车商业务研讨会")])]), _c('v-divider'), _c('v-img', {
    attrs: {
      "src": "/static/activity/h5.png"
    }
  }), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2"
    }
  }, [_c('span', {
    staticClass: "page-h5"
  }, [_vm._v("活动内容")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "10",
      "lg": "10"
    }
  }, [_c('p', {
    staticClass: "page-content"
  }, [_vm._v("Apple 企业团队与企业解决方案合作伙伴，邀您共同探讨在汽车行业剧变、车险综合改革的巨大挑战下，如何利用新技术及行业解决方案帮助保险公司和汽车品牌拓展业务的多维度价值，创新业务销售场景，加速保险行业与汽车行业深度融合的价值再造。")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2"
    }
  }, [_c('span', {
    staticClass: "page-h5"
  }, [_vm._v("参与对象")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "10",
      "lg": "10"
    }
  }, [_c('p', {
    staticClass: "page-content"
  }, [_vm._v("本次研讨会针对保险公司、车商企业的业务部门和 IT 部门的相关负责人及领导团队成员。")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2"
    }
  }, [_c('b', {
    staticClass: "page-h5"
  }, [_vm._v("活动时间")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "10",
      "lg": "10"
    }
  }, [_c('p', {
    staticClass: "page-content"
  }, [_vm._v("2024 年 12 月 3 日 ｜ 14:00 - 17:00 13:30 签到注册")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2"
    }
  }, [_c('b', {
    staticClass: "page-h5"
  }, [_vm._v("活动地址")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "10",
      "lg": "10"
    }
  }, [_c('p', {
    staticClass: "page-content"
  }, [_vm._v("北京市朝阳区建国门外大街1号，中国大饭店，一层，宴会厅B")])]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('v-btn', {
    staticClass: "page-h5",
    attrs: {
      "color": "primary",
      "x-large": ""
    },
    on: {
      "click": function click($event) {
        _vm.contactDialog = true;
      }
    }
  }, [_vm._v("报名")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('contact-dialog', {
    attrs: {
      "showDialog": _vm.contactDialog,
      "type": "ACTIVITY_TRAINING"
    },
    on: {
      "dialogCloseEvent": function dialogCloseEvent($event) {
        _vm.contactDialog = false;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }