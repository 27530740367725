var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-img', {
    staticClass: "white--text align-end",
    attrs: {
      "src": "/static/mac-as-choice/choice_banner.png"
    }
  })], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "mt-16 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-center text-h4 text-md-h3 font-weight-bold mt-4"
  }, [_vm._v("Mac as Choice")])]), _c('v-col', {
    staticClass: "mb-16",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-center text-md-h5 my-4 grey--text"
  }, [_vm._v(" 专为计划选择 Mac 的企业提供四大保障，八项服务。"), _c('br'), _vm._v(" 帮助企业从员工体验，兼容测试，效能表现，租赁采购 四个方面快速完成部署，"), _c('br'), _vm._v(" 让企业看到效能的更多可能。选择 Mac 实力加成 企业效能加满。 ")])]), _c('v-col', {
    staticClass: "text-center mb-12",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    directives: [{
      name: "log",
      rawName: "v-log",
      value: 'MAC_AS_CHOICE_WITH_CONTACT',
      expression: "'MAC_AS_CHOICE_WITH_CONTACT'"
    }],
    staticClass: "rounded-xl large-btn",
    attrs: {
      "x-large": "",
      "depressed": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.contactDialog = true;
      }
    }
  }, [_vm._v(" 点击留资加入项目 ")])], 1)], 1), _c('v-row', {
    staticClass: "choiceList"
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('span', {
    staticClass: "page-h5"
  }, [_vm._v(" 轻松上手 全面支持"), _c('br'), _vm._v(" （Manage & Use） ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "5",
      "md": "5"
    }
  }, [_c('img', {
    attrs: {
      "src": "/static/mac-as-choice/m1.png"
    }
  }), _c('div', {
    staticClass: "page-h6 my-4"
  }, [_vm._v("IT培训及认证")]), _c('div', {
    staticClass: "page-content2 mb-6"
  }, [_vm._v(" 我们为IT人员提供专业培训及全球统一的Apple认证， 深度了解Mac设备，快速部署以响应企业需求。 ")])]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2",
      "md": "2"
    }
  }), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "5",
      "md": "5"
    }
  }, [_c('img', {
    attrs: {
      "src": "/static/mac-as-choice/m2.png"
    }
  }), _c('div', {
    staticClass: "page-h6 my-4"
  }, [_vm._v("企业员工课堂")]), _c('div', {
    staticClass: "page-content2 mb-6"
  }, [_vm._v(" 企业员工也可获得专业的Apple设备培训， 快速实现从上手到高手的进阶。 ")])]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('span', {
    staticClass: "page-h5"
  }, [_vm._v(" 测试无忧 效能加固"), _c('br'), _vm._v(" （Compatibility） ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "5",
      "md": "5"
    }
  }, [_c('img', {
    attrs: {
      "src": "/static/mac-as-choice/m3.png"
    }
  }), _c('div', {
    staticClass: "page-h6 my-4"
  }, [_vm._v("企业测试机")]), _c('div', {
    staticClass: "page-content2 mb-6"
  }, [_vm._v(" 我们为企业提供测试机申领服务， 以便于IT 部门迅速了解软件与企业日常工作的适配度。 ")])]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2",
      "md": "2"
    }
  }), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "5",
      "md": "5"
    }
  }, [_c('img', {
    attrs: {
      "src": "/static/mac-as-choice/m4.png"
    }
  }), _c('div', {
    staticClass: "page-h6 my-4"
  }, [_vm._v("兼容与验证")]), _c('div', {
    staticClass: "page-content2 mb-6"
  }, [_vm._v(" 通过一系列行之有效的验证方法 判断 Mac设备是否兼容日常应用，是否适配企业工作。 ")])]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('span', {
    staticClass: "page-h5"
  }, [_vm._v(" 体验升级 优效保证"), _c('br'), _vm._v(" （Better Performance） ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "5",
      "md": "5"
    }
  }, [_c('img', {
    attrs: {
      "src": "/static/mac-as-choice/m5.png"
    }
  }), _c('div', {
    staticClass: "page-h6 my-4"
  }, [_vm._v("体验机申请")]), _c('div', {
    staticClass: "page-content2 mb-6"
  }, [_vm._v(" 我们为企业提供体验机申领的服务， 通过体验机的测试调配优化升级使用体验。 ")])]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2",
      "md": "2"
    }
  }), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "5",
      "md": "5"
    }
  }, [_c('img', {
    attrs: {
      "src": "/static/mac-as-choice/m6.png"
    }
  }), _c('div', {
    staticClass: "page-h6 my-4"
  }, [_vm._v("TEI优效报告")]), _c('div', {
    staticClass: "page-content2 mb-6"
  }, [_vm._v(" 通过标准化的测试和基准， 为企业生成生成官方TEI 报告，最大化企业日常效率。 ")])]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('span', {
    staticClass: "page-h5"
  }, [_vm._v(" 租赁服务 灵活可选"), _c('br'), _vm._v(" （Affordability） ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "5",
      "md": "5"
    }
  }, [_c('img', {
    attrs: {
      "src": "/static/mac-as-choice/m7.png"
    }
  }), _c('div', {
    staticClass: "page-h6 my-4"
  }, [_vm._v("DaaS")]), _c('div', {
    staticClass: "page-content2 mb-6"
  }, [_vm._v(" 我们为企业提供灵活可选的数据服务， 提升IT效率，优化企业产能。 ")])]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2",
      "md": "2"
    }
  }), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "5",
      "md": "5"
    }
  }, [_c('img', {
    attrs: {
      "src": "/static/mac-as-choice/m8.png"
    }
  }), _c('div', {
    staticClass: "page-h6 my-4"
  }, [_vm._v("按需租赁")]), _c('div', {
    staticClass: "page-content2 mb-6"
  }, [_vm._v(" 我们为企业提供按需租赁服务， 以及相关配套的一系列增值服务。让企业资产管理更加灵活。 ")])])], 1), _c('contact-dialog', {
    attrs: {
      "showDialog": _vm.contactDialog,
      "type": "MAC_AS_CHOICE"
    },
    on: {
      "dialogCloseEvent": function dialogCloseEvent($event) {
        _vm.contactDialog = false;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }