<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="mt-16 mb-2 text-center">
        <span class="text-h6 font-weight-bold mt-4">
          {{ knowledgeDetail.title }}
        </span>
      </v-col>
      <v-col v-if="knowledgeDetail.isVideo" cols="12" class="text-small">
        <vue-plyr>
          <video
            controls
            playsinline
          >
            <source
              :src="knowledgeDetail.videoKey" 
              type="video/mp4"
            />
          </video>
        </vue-plyr>
      </v-col>
      <v-col v-if="!knowledgeDetail.isVideo" cols="12" class="text-small">
        <p v-html="knowledgeDetail.context"></p>
      </v-col>
      <v-col cols="12" class="mt-2">
        <span>发布日期： {{ knowledgeDetail.updateTime }}</span>
      </v-col>
      <v-col cols="12" class="my-2">
        <a href="/get-support"><v-icon>chevron_left</v-icon></a>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
body {
  font-family: PingFangSC-Regular, Microsoft Yahei;
}
.page-h1 {
  font-weight: 600;
  font-size: 56px;
  line-height: 62px;
}
.page-h2 {
  font-weight: 500;
  font-size: 48px;
}
.page-h3 {
  font-weight: 500;
  font-size: 36px;
  line-height: 31px;
}
.page-h4 {
  font-weight: 500;
  font-size: 26px;
}
.page-h5 {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
}
.page-tab {
  font-weight: 500;
  font-size: 26px;
}

.page-subtitle {
  font-weight: 500;
  font-size: 22px;
}
.page-content {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
}

.page-btn {
  font-weight: 400;
  font-size: 24px;
}

.page-list {
  li {
    list-style: none;
  }
}

.activity-title {
  margin-left: auto;
  margin-right: auto;
}
.large-btn {
  width: 300px;
  font-size: 24px;
}
.tab-title {
  font-weight: 500;
  font-size: 24px;
  padding: 10px 40px;
  border-bottom: 1px solid #d9d9d9;
}

.tab-mobile-title {
  padding: 10px 10px;
  border-bottom: 1px solid #d9d9d9;
}

.grey-block {
  background-color: #f5f5f7;
  color: #0573d9;
  border-radius: 24px;
  padding: 30px 40px;
}

.support-image {
  height: 66px;
  object-fit: contain;
  display: block;
  margin: 0 auto 14px;
}

@media screen and (max-width:600px){
  .support-image {
    height: 34px;
  }
}
</style>

<script>
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_KNOWLEDGE_DETAIL
} from "@/store/actions.type";
import store from "@/store";

export default {
  data() {
    return {
      knowledgeDetail: {}
    }
  },
  methods: {
    loadDetail(id) {
      store.dispatch(FETCH_KNOWLEDGE_DETAIL, id)
        .then((data) => {
          if (data.data.code !== 200) {
            store.commit(SET_ERROR, {msg: data.data.message});
            return;
          }
          
          this.knowledgeDetail = data.data.result;
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    }
  },
  created() {
    this.loadDetail(this.$route.params.id);
  }
}
</script>
