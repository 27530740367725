var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-breadcrumbs', {
    staticClass: "mb-8",
    attrs: {
      "items": _vm.pageBread
    },
    scopedSlots: _vm._u([{
      key: "divider",
      fn: function fn() {
        return [_c('v-icon', [_vm._v("chevron_right")])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-breadcrumbs-item', {
          attrs: {
            "disabled": item.disabled,
            "to": item.to,
            "link": true,
            "exact": true
          }
        }, [_c('span', {
          class: item.disabled ? 'grey--text' : 'black--text'
        }, [_vm._v(_vm._s(item.text))])])];
      }
    }])
  }), _c('v-row', [_c('v-col', {
    staticClass: "px-4",
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('v-carousel', {
    attrs: {
      "delimiter-icon": "remove",
      "height": "320px"
    },
    model: {
      value: _vm.bannersList,
      callback: function callback($$v) {
        _vm.bannersList = $$v;
      },
      expression: "bannersList"
    }
  }, _vm._l(_vm.banners, function (item, i) {
    return _c('v-carousel-item', {
      key: 'carousel_' + i,
      attrs: {
        "src": item,
        "reverse-transition": "fade-transition",
        "transition": "fade-transition"
      }
    });
  }), 1), _c('v-slide-group', {
    staticClass: "pa-4",
    attrs: {
      "show-arrows": ""
    },
    model: {
      value: _vm.bannersThumbnail,
      callback: function callback($$v) {
        _vm.bannersThumbnail = $$v;
      },
      expression: "bannersThumbnail"
    }
  }, _vm._l(_vm.banners, function (n, j) {
    return _c('v-slide-item', {
      key: 'thumbnail_' + n,
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref2) {
          var active = _ref2.active,
            toggle = _ref2.toggle;
          return [_c('v-img', {
            staticClass: "mx-2 v-list-item--link",
            attrs: {
              "src": n,
              "contain": "",
              "max-height": "100px",
              "max-width": "100px"
            },
            on: {
              "click": function click($event) {
                return _vm.setBanners(j);
              }
            }
          })];
        }
      }], null, true)
    });
  }), 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('p', {
    staticClass: "text-h4 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.product_info.productName))]), _vm._l(_vm.attrSummary, function (attr, index) {
    return _c('P', {
      key: 'summary_text_' + index,
      staticClass: "subtitle-2"
    }, [_vm._v(_vm._s(attr))]);
  }), _c('v-divider', {
    staticClass: "my-4"
  }), _vm._l(_vm.product_info.attributesList, function (attr, index) {
    return _c('v-row', {
      key: 'attr_' + index
    }, [attr.name === '颜色' ? _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('v-row', [_c('v-col', {
      staticClass: "subtitle-2 mb-n4",
      attrs: {
        "cols": "12",
        "md": "12",
        "lg": "12"
      }
    }, [_vm._v(_vm._s(attr.name))]), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "12",
        "lg": "12"
      }
    }, _vm._l(attr.attributesValueList, function (v, j) {
      return _c('v-btn', {
        key: 'attr_value_' + '_' + index + '_' + j,
        attrs: {
          "color": _vm.attributes[v.nameId] === v.valueId ? 'primary' : 'white',
          "depressed": "",
          "outlined": "",
          "fab": "",
          "small": ""
        },
        on: {
          "click": function click($event) {
            return _vm.setSelectValue(v);
          }
        }
      }, [v.valueInfo === '深空黑色' ? _c('v-icon', {
        attrs: {
          "x-large": "",
          "color": "#727375"
        }
      }, [_vm._v("circle")]) : _vm._e(), v.valueInfo === '深空灰色' ? _c('v-icon', {
        attrs: {
          "x-large": "",
          "color": "#727375"
        }
      }, [_vm._v("circle")]) : _vm._e(), v.valueInfo === '银色' ? _c('v-icon', {
        attrs: {
          "x-large": "",
          "color": "#dfe0e2"
        }
      }, [_vm._v("circle")]) : _vm._e(), v.valueInfo === '黑色钛金属' ? _c('v-icon', {
        attrs: {
          "x-large": "",
          "color": "#3f4144"
        }
      }, [_vm._v("circle")]) : _vm._e(), v.valueInfo === '白色钛金属' ? _c('v-icon', {
        attrs: {
          "x-large": "",
          "color": "#f1efe8"
        }
      }, [_vm._v("circle")]) : _vm._e(), v.valueInfo === '原色钛金属' ? _c('v-icon', {
        attrs: {
          "x-large": "",
          "color": "#aca698"
        }
      }, [_vm._v("circle")]) : _vm._e(), v.valueInfo === '蓝色钛金属' ? _c('v-icon', {
        attrs: {
          "x-large": "",
          "color": "#3e4250"
        }
      }, [_vm._v("circle")]) : _vm._e()], 1);
    }), 1)], 1)], 1) : _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('v-row', [_c('v-col', {
      staticClass: "subtitle-2 mb-n4",
      attrs: {
        "cols": "12",
        "md": "12",
        "lg": "12"
      }
    }, [_vm._v(_vm._s(attr.name))]), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "12",
        "lg": "12"
      }
    }, _vm._l(attr.attributesValueList, function (v, j) {
      return _c('v-btn', {
        key: 'attr_value_' + '_' + index + '_' + j,
        staticClass: "rounded-lg mb-2 justify-start subtitle-1",
        attrs: {
          "color": _vm.attributes[v.nameId] === v.valueId ? 'primary' : 'grey darken-3',
          "depressed": "",
          "outlined": "",
          "block": "",
          "height": "75"
        },
        on: {
          "click": function click($event) {
            return _vm.setSelectValue(v);
          }
        }
      }, [_vm._v(_vm._s(v.valueInfo))]);
    }), 1)], 1)], 1)], 1);
  })], 2)], 1), _c('v-row', [_c('v-col', [_c('pre', {
    staticStyle: {
      "white-space": "pre-wrap"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.product_info.picTextDescription)
    }
  })])], 1), _vm.product_info.id ? _c('product-price-bar', {
    attrs: {
      "skuInfo": _vm.currentSKU,
      "spuId": _vm.product_info.id
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }