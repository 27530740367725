<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-card flat>
          <v-img src="/static/mac-as-choice/choice_banner.png" class="white--text align-end">
          </v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mt-16 mb-4">
        <p class="text-center text-h4 text-md-h3 font-weight-bold mt-4">Mac as Choice</p>
      </v-col>
      <v-col cols="12" class="mb-16">
        <p class="text-center text-md-h5 my-4 grey--text">
          专为计划选择 Mac 的企业提供四大保障，八项服务。<br />
          帮助企业从员工体验，兼容测试，效能表现，租赁采购 四个方面快速完成部署，<br />
          让企业看到效能的更多可能。选择 Mac 实力加成 企业效能加满。
        </p>
      </v-col>
      <v-col cols="12" class="text-center mb-12">
        <v-btn
          x-large
          depressed
          color="primary"
          class="rounded-xl large-btn"
          v-log="'MAC_AS_CHOICE_WITH_CONTACT'"
          @click="contactDialog = true"
        >
          点击留资加入项目
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="choiceList">
      <v-col cols="12" md="12" lg="12" class="text-center">
        <span class="page-h5">
          轻松上手 全面支持<br />
          （Manage & Use）
        </span>
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>
      <v-col cols="5" md="5" class="text-center">
        <img src="/static/mac-as-choice/m1.png" />
        <div class="page-h6 my-4">IT培训及认证</div>
        <div class="page-content2 mb-6">
          我们为IT人员提供专业培训及全球统一的Apple认证，
          深度了解Mac设备，快速部署以响应企业需求。
        </div>
      </v-col>
      <v-col cols="2" md="2" class="text-center"></v-col>
      <v-col cols="5" md="5" class="text-center">
        <img src="/static/mac-as-choice/m2.png" />
        <div class="page-h6 my-4">企业员工课堂</div>
        <div class="page-content2 mb-6">
          企业员工也可获得专业的Apple设备培训，
          快速实现从上手到高手的进阶。
        </div>
      </v-col>

      <v-col cols="12" md="12" lg="12" class="text-center">
        <span class="page-h5">
          测试无忧 效能加固<br />
          （Compatibility）
        </span>
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>
      <v-col cols="5" md="5" class="text-center">
        <img src="/static/mac-as-choice/m3.png" />
        <div class="page-h6 my-4">企业测试机</div>
        <div class="page-content2 mb-6">
          我们为企业提供测试机申领服务，
          以便于IT 部门迅速了解软件与企业日常工作的适配度。
        </div>
      </v-col>
      <v-col cols="2" md="2" class="text-center"></v-col>
      <v-col cols="5" md="5" class="text-center">
        <img src="/static/mac-as-choice/m4.png" />
        <div class="page-h6 my-4">兼容与验证</div>
        <div class="page-content2 mb-6">
          通过一系列行之有效的验证方法
          判断 Mac设备是否兼容日常应用，是否适配企业工作。 
        </div>
      </v-col>

      <v-col cols="12" md="12" lg="12" class="text-center">
        <span class="page-h5">
          体验升级 优效保证<br />
          （Better Performance）
        </span>
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>
      <v-col cols="5" md="5" class="text-center">
        <img src="/static/mac-as-choice/m5.png" />
        <div class="page-h6 my-4">体验机申请</div>
        <div class="page-content2 mb-6">
          我们为企业提供体验机申领的服务，
          通过体验机的测试调配优化升级使用体验。
        </div>
      </v-col>
      <v-col cols="2" md="2" class="text-center"></v-col>
      <v-col cols="5" md="5" class="text-center">
        <img src="/static/mac-as-choice/m6.png" />
        <div class="page-h6 my-4">TEI优效报告</div>
        <div class="page-content2 mb-6">
          通过标准化的测试和基准，
          为企业生成生成官方TEI 报告，最大化企业日常效率。
        </div>
      </v-col>

      <v-col cols="12" md="12" lg="12" class="text-center">
        <span class="page-h5">
          租赁服务 灵活可选<br />
          （Affordability）
        </span>
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>
      <v-col cols="5" md="5" class="text-center">
        <img src="/static/mac-as-choice/m7.png" />
        <div class="page-h6 my-4">DaaS</div>
        <div class="page-content2 mb-6">
          我们为企业提供灵活可选的数据服务，
          提升IT效率，优化企业产能。
        </div>
      </v-col>
      <v-col cols="2" md="2" class="text-center"></v-col>
      <v-col cols="5" md="5" class="text-center">
        <img src="/static/mac-as-choice/m8.png" />
        <div class="page-h6 my-4">按需租赁</div>
        <div class="page-content2 mb-6">
          我们为企业提供按需租赁服务，
          以及相关配套的一系列增值服务。让企业资产管理更加灵活。
        </div>
      </v-col>
    </v-row>
    <contact-dialog :showDialog="contactDialog" type="MAC_AS_CHOICE" @dialogCloseEvent="contactDialog = false"></contact-dialog>
  </v-container>
</template>

<style lang="scss" scoped>
.page-h1 {
  font-weight: 600;
  font-size: 56px;
  line-height: 62px;
}
.page-h2 {
  font-weight: 500;
  font-size: 48px;
}
.page-h3 {
  font-weight: 500;
  font-size: 36px;
  line-height: 31px;
}
.page-h4 {
  font-weight: 500;
  font-size: 26px;
}
.page-h5 {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
}

.page-h6 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}
.page-tab {
  font-weight: 500;
  font-size: 26px;
}

.page-subtitle {
  font-weight: 500;
  font-size: 22px;
}
.page-content {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
}

.page-content2 {
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
}

.page-btn {
  font-weight: 400;
  font-size: 24px;
}

.page-list {
  li {
    list-style: none;
  }
}

.activity-title {
  margin-left: auto;
  margin-right: auto;
}
.large-btn {
  width: 300px;
  font-size: 24px;
}
.tab-title {
  font-weight: 500;
  font-size: 24px;
  padding: 10px 40px;
  border-bottom: 1px solid #d9d9d9;
}

.tab-mobile-title {
  padding: 10px 10px;
  border-bottom: 1px solid #d9d9d9;
}

.grey-block {
  background-color: #f5f5f7;
  color: #0573d9;
  border-radius: 24px;
  padding: 30px 40px;
}

.support-image {
  height: 66px;
  object-fit: contain;
  display: block;
  margin: 0 auto 14px;
}

.choiceList {
  img {
    height: 70px;
  }
}

@media screen and (max-width:600px){
  .support-image {
    height: 34px;
  }

  .choiceList {
    img {
      height: 50px;
    }
  }
}
</style>

<script>
export default {
  data() {
    return {
      contactDialog: false,
    }
  }
}
</script>
