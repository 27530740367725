<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <v-icon size="48" color="black" class="mt-n1">apple</v-icon>
        <span class="text-h3 font-weight-regular" style="white-space: pre-wrap" v-html="pageInfo.categoryName"></span>
      </v-col>
      <v-col cols="12" class="d-flex justify-center my-4">
        <p class="text-h4 text-md-h3 font-weight-bold" style="white-space: pre-wrap" v-html="pageInfo.explainInfo"></p>
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <p class="text-h5 font-weight-thin text-center" style="white-space: pre-wrap" v-html="pageInfo.describeInfo"></p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <v-img contain :src="pageInfo.imgUrl" max-height="450"></v-img>
      </v-col>
    </v-row>
    <v-row id="categoryArea">
      <v-col cols="12" class="d-flex justify-center mt-12">
        <p class="text-h5 font-weight-bold text-center">按类别浏览</p>
      </v-col>
      <v-col cols="12">
        <v-tabs v-model="activeTab" centered grow show-arrows color="black" class="bordered-tabs">
          <v-tab
            class="font-weight-light"
            :to="{ path: '/product/'+ encodeURIComponent(pageInfo.categoryName.toLowerCase()), query: { brandId: pageInfo.brandId, firstCategoryId: pageInfo.id } }"
          >
            全部
          </v-tab>
          <v-tab
            v-for="(item, index) in pageInfo.childrenList"
            :key="'category_tab_'+ index"
            class="font-weight-light"
            :to="{ path: '/product/'+ encodeURIComponent(pageInfo.categoryName.toLowerCase()) + '/' + encodeURIComponent(item.categoryName.toLowerCase()), query: { brandId: pageInfo.brandId, firstCategoryId: pageInfo.id, secondCategoryId: item.id }, hash: '#categoryArea' }"
          >
            {{item.categoryName}}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row v-if="pageInfo.categoryName.toLowerCase() === 'mac'">
      <v-col cols="6" md="3" lg="3">
        <v-select
          v-model="query.memory"
          label="内存"
          :items="filter_list.find(filter => filter.name === '内存')?.attributesValueList"
          item-text="valueInfo"
          item-value="valueId"
          return-object
          clearable
          @change="getList()"
        >
        </v-select>
      </v-col>
      <v-col cols="6" md="3" lg="3">
        <v-select
          v-model="query.chip"
          label="芯片"
          :items="filter_list.find(filter => filter.name === '芯片')?.attributesValueList"
          item-text="valueInfo"
          item-value="valueId"
          return-object
          clearable
          @change="getList()"
        ></v-select>
      </v-col>
      <v-col cols="6" md="3" lg="3">
        <v-select
          v-model="query.processor"
          label="处理器"
          :items="filter_list.find(filter => filter.name === '处理器')?.attributesValueList"
          item-text="valueInfo"
          item-value="valueId"
          return-object
          clearable
          @change="getList()"
        ></v-select>
      </v-col>
      <v-col cols="12" md="3" lg="3" class="align-content-center" v-if="price_limit">
        <v-slider
          v-model="query.price"
          color="black"
          track-color="grey"
          step="100"
          thumb-label
          hide-details
          :min="price_limit.minPrice"
          :max="price_limit.maxPrice"
          @change="getList()"
        >
          <template v-slot:prepend>¥{{price_limit.minPrice}}</template>
          <template v-slot:append>¥{{price_limit.maxPrice}}</template>
        </v-slider>
      </v-col>
    </v-row>
    <v-row v-if="pageInfo.categoryName.toLowerCase() === 'ipad'">
      <v-col cols="6" md="3" lg="3">
        <v-select
          v-model="query.storage"
          label="存储容量"
          :items="filter_list.find(filter => filter.name === '存储容量')?.attributesValueList"
          item-text="valueInfo"
          item-value="valueId"
          return-object
          clearable
          @change="getList()"
        >
        </v-select>
      </v-col>
      <v-col cols="6" md="3" lg="3">
        <v-select
          v-model="query.outward"
          label="外观"
          :items="filter_list.find(filter => filter.name === '外观')?.attributesValueList"
          item-text="valueInfo"
          item-value="valueId"
          return-object
          clearable
          @change="getList()"
        ></v-select>
      </v-col>
      <v-col cols="6" md="3" lg="3">
        <v-select
          v-model="query.screensize"
          label="屏幕尺寸"
          :items="filter_list.find(filter => filter.name === '屏幕尺寸')?.attributesValueList"
          item-text="valueInfo"
          item-value="valueId"
          return-object
          clearable
          @change="getList()"
        ></v-select>
      </v-col>
      <v-col cols="12" md="3" lg="3" class="align-content-center" v-if="price_limit">
        <v-slider
          v-model="query.price"
          color="black"
          track-color="grey"
          step="100"
          thumb-label
          hide-details
          :min="price_limit.minPrice"
          :max="price_limit.maxPrice"
          @change="getList()"
        >
          <template v-slot:prepend>¥{{price_limit.minPrice}}</template>
          <template v-slot:append>¥{{price_limit.maxPrice}}</template>
        </v-slider>
      </v-col>
    </v-row>
    <v-row v-if="pageInfo.categoryName.toLowerCase() === 'iphone'">
      <v-col cols="6" md="4" lg="4">
        <v-select
          v-model="query.storage"
          label="存储容量"
          :items="filter_list.find(filter => filter.name === '存储容量')?.attributesValueList"
          item-text="valueInfo"
          item-value="valueId"
          return-object
          clearable
          @change="getList()"
        >
        </v-select>
      </v-col>
      <v-col cols="6" md="4" lg="4">
        <v-select
          v-model="query.color"
          label="颜色"
          :items="filter_list.find(filter => filter.name === '颜色')?.attributesValueList"
          item-text="valueInfo"
          item-value="valueId"
          return-object
          clearable
          @change="getList()"
        ></v-select>
      </v-col>
      <v-col cols="12" md="4" lg="4" class="align-content-center" v-if="price_limit">
        <v-slider
          v-model="query.price"
          color="black"
          track-color="grey"
          step="100"
          thumb-label
          hide-details
          :min="price_limit.minPrice"
          :max="price_limit.maxPrice"
          @change="getList()"
        >
          <template v-slot:prepend>¥{{price_limit.minPrice}}</template>
          <template v-slot:append>¥{{price_limit.maxPrice}}</template>
        </v-slider>
      </v-col>
    </v-row>
    <v-row v-if="pageInfo.categoryName.toLowerCase() === 'watch'">
      <v-col cols="6" md="3" lg="3">
        <v-select
          v-model="query.connect"
          label="连接"
          :items="filter_list.find(filter => filter.name === '连接')?.attributesValueList"
          item-text="valueInfo"
          item-value="valueId"
          return-object
          clearable
          @change="getList()"
        >
        </v-select>
      </v-col>
      <v-col cols="6" md="3" lg="3">
        <v-select
          v-model="query.outward"
          label="外观"
          :items="filter_list.find(filter => filter.name === '外观')?.attributesValueList"
          item-text="valueInfo"
          item-value="valueId"
          return-object
          clearable
          @change="getList()"
        ></v-select>
      </v-col>
      <v-col cols="6" md="3" lg="3">
        <v-select
          v-model="query.casesize"
          label="表壳尺寸"
          :items="filter_list.find(filter => filter.name === '表壳尺寸')?.attributesValueList"
          item-text="valueInfo"
          item-value="valueId"
          return-object
          clearable
          @change="getList()"
        ></v-select>
      </v-col>
      <v-col cols="12" md="3" lg="3" class="align-content-center" v-if="price_limit">
        <v-slider
          v-model="query.price"
          color="black"
          track-color="grey"
          step="100"
          thumb-label
          hide-details
          :min="price_limit.minPrice"
          :max="price_limit.maxPrice"
          @change="getList()"
        >
          <template v-slot:prepend>¥{{price_limit.minPrice}}</template>
          <template v-slot:append>¥{{price_limit.maxPrice}}</template>
        </v-slider>
      </v-col>
    </v-row>
    <v-row v-if="pageInfo.categoryName.toLowerCase() === 'airpods'">
    </v-row>
    <v-row v-if="pageInfo.categoryName.toLowerCase() === '配件'">
    </v-row>
    <v-row>
      <v-col cols="12" md="3" lg="3" v-for="(item, index) in product_list" :key="'product_'+ index">
        <v-card flat hover :to="{ path: '/product/'+ encodeURIComponent(item.firstCategory.toLowerCase()) + '/' + encodeURIComponent(item.secondCategory.toLowerCase()) +'/'+ encodeURIComponent(item.productName.toLowerCase()) , query: { brandId: item.brandId, firstCategoryId: item.firstCategoryId, secondCategoryId: item.secndCatoegoryId, id: item.id } }">
          <v-img :src="item?.skuPicList[0]" height="160px" contain class="mb-n4"></v-img>
          <v-card-title class="justify-center">
            <span class="col-12 text-truncate text-center">{{item.productName}}</span>
            <span class="mt-n4 subtitle-2 col-12 text-truncate grey--text text-center">{{item.skuName}}</span>
            <p class="text-h4 primary--text col-12 text-center">¥{{item.salesPrice}}</p>
            <p class="mt-n8 subtitle-2 primary--text col-12 text-center">租赁价 ¥{{item.leasePrice}}</p>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import store from "@/store";
import {
  mapGetters
} from "vuex";
import {
  FETCH_PROD_FILTER_LIST,
  FETCH_PROD_PRICE_LIMIT,
  FETCH_PROD_LIST
} from "@/store/actions.type";
export default {
  data() {
    return {
      activeTab: null,
      query: {},
    }
  },
  watch: {
    '$route.query': {
      immediate: false,
      handler() {
        this.initPageData()
      }
    },
  },
  computed: {
    ...mapGetters(['category', 'filter_list', 'price_limit', 'product_list']),
    pageInfo() {
      const pageId = this.$route.query?.firstCategoryId || 0
      return this.category.find(item => item.id === parseInt(pageId))
    },
  },
  created() {
    this.initPageData();
  },
  methods: {
    //初始化页面：获取过滤条件、价格区间
    initPageData() {
      store.dispatch(FETCH_PROD_FILTER_LIST, this.$route.query?.firstCategoryId || 0)
      store.dispatch(FETCH_PROD_PRICE_LIMIT, {
        firstCategoryId: this.$route.query?.firstCategoryId,
        secondCategoryId: this.$route.query?.secondCategoryId,
      }).then(() => {
        this.$forceUpdate()
        this.query.price = this.price_limit?.maxPrice
        this.getList();
      })
    },
    //获取数据列表
    getList() {
      let objQuery = {
        attrList: [],
        brandId: this.$route.query?.brandId,
        firstCategoryId: this.$route.query?.firstCategoryId,
        secondCategoryId: this.$route.query?.secondCategoryId,
        pageNo: 1,
        pageSize: 9
      }

      for (const field in this.query) {
        if (field === 'price') {
          objQuery.price = this.query.price
        } else {
          if (this.query[field]) {
            objQuery.attrList.push({
              attrNameId: this.query[field].nameId,
              attrValueId: this.query[field].valueId
            })
          }
        }
      }
      store.dispatch(FETCH_PROD_LIST, objQuery)
    }
  }

};
</script>
<style scoped>
.bordered-tabs >>> .v-slide-group__content {
  border-bottom: 1px solid #0000001e;
}
.v-tab {
  text-transform: none !important;
}
</style>
