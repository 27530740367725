<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="mt-16 mb-4">
        <p class="text-center text-h4 text-md-h3 font-weight-bold mt-4">
          订单
        </p>
      </v-col>
      <v-col cols="12" class="mb-12">
        <v-tabs 
          centered 
          plain 
          transition="fade-transition" 
          prev-icon="chevron_left"
          next-icon="chevron_right"
          show-arrows
          v-model="tabType"
          @change="handleTabChange"
        >
          <v-tab href="#intention" :class="[$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']">
            意向单
          </v-tab>
          <v-tab href="#purchase_order" :class="[$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']">
            采购订单
          </v-tab>
          <v-tab href="#service_order" :class="[$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']">
            服务工单
          </v-tab>
          <v-tab href="#repair_order" :class="[$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']">
            维修工单
          </v-tab>

          <!--意向单-->
          <v-tab-item value="intention" transition="fade-transition">
            <v-card flat>
              <v-card-text class="mt-12 mb-4">
                <v-row>
                  <v-col cols="12">
                    <v-data-table
                      :headers="intentionHeaders"
                      :items="intentionList"
                      :items-per-page="20"
                      hide-default-footer
                      show-expand
                      :single-expand="singleExpand"
                      :expanded.sync="expanded"
                      class="orderTable"
                      no-data-text="暂无数据"
                    >
                      <template v-slot:item.status="{ item }">
                        <span v-if="item.status == 1" style="color: green;">处理中</span>
                        <span v-if="item.status == 4" style="color: green;">已完成</span>
                      </template>
                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                          <v-data-table
                            :headers="intentionSubHeaders"
                            :items="item.subOrderList"
                            hide-default-footer
                            class="orderTable my-4"
                            no-data-text="暂无数据"
                          >
                            <template v-slot:item.productName="{ item }">
                              <v-row no-gutters class="intentionProduct">
                                <v-col cols="12" md="2" class="image"><img width="100%" :src="item.picUrl.split(',')[0]" /></v-col>
                                <v-col cols="12" md="10">
                                  <v-row no-gutters>
                                    <v-col cols="12"><span class="title">{{ item.productName }}</span></v-col>
                                    <v-col cols="12"><p>{{ item.skuName }}</p></v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </template>
                            <template v-slot:item.purchaseType="{ item }">
                              <span v-if="item.purchaseType === 1">采购</span>
                              <span v-if="item.purchaseType !== 1">租赁（{{ item?.leasePeriod }}月/{{ item?.leasePayMethod }}）</span>
                            </template>
                            <template v-slot:item.salesPrice="{ item }">
                              <span v-if="item.purchaseType === 1">{{ item.salesPrice }}</span>
                              <span v-if="item.purchaseType !== 1">{{ item.leasePrice }} 月</span>
                            </template>
                          </v-data-table>
                        </td>
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-col cols="12" class="mt-0 pt-0">
                    <v-pagination
                      v-model="intentionPage.pageNo"
                      @input="handleIntentionPage"
                      :length="intentionPage.pageCount"
                      total-visible="7"
                      class="orderPage"
                    ></v-pagination>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!--采购订单-->
          <v-tab-item value="purchase_order" transition="fade-transition">
            <v-card flat>
              <v-card-text class="mt-12 mb-4">
                <v-row>
                  <v-col cols="12">
                    <v-data-table
                      :headers="orderHeaders"
                      :items="orderList"
                      :items-per-page="orderPage.pageSize"
                      hide-default-footer
                      class="orderTable"
                      no-data-text="暂无数据"
                    >
                      <template v-slot:item.status="{ item }">
                        <span v-if="item.status == 0" style="color: #000;">待执⾏</span>
                        <span v-if="item.status == 1" style="color: #1890FF;">处理中</span>
                        <span v-if="item.status == 2" style="color: #ccc;">已取消</span>
                        <span v-if="item.status == 3" style="color: #f50;">已拒绝</span>
                        <span v-if="item.status == 4" style="color: #87d068;">已完成</span>
                      </template>
                      <template v-slot:item.operate="{ item }">
                        <v-btn text @click="handleOrderDetail(item.id)">详情</v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-col cols="12" class="mt-0 pt-0">
                    <v-pagination
                      v-model="orderPage.pageNo"
                      @input="handleOrderPage"
                      :length="orderPage.pageCount"
                      total-visible="7"
                      class="orderPage"
                    ></v-pagination>
                  </v-col>
                </v-row>
                <order-order-detail :showDialog="showOrderDialog" :orderId="currentOrderId" @dialogCloseEvent="showOrderDialog = false"></order-order-detail>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!--服务工单-->
          <v-tab-item value="service_order" transition="fade-transition">
            <v-card flat>
              <v-card-text class="mt-12 mb-4">
                <v-row>
                  <v-col cols="12">
                    <v-data-table
                      :headers="serviceHeaders"
                      :items="serviceList"
                      :items-per-page="20"
                      hide-default-footer
                      class="orderTable"
                      no-data-text="暂无数据"
                    >
                      <template v-slot:item.status="{ item }">
                        <span v-if="item.status == 0" style="color: #000;">待受理</span>
                        <span v-if="item.status == 1" style="color: #1890FF;">处理中</span>
                        <span v-if="item.status == 2" style="color: #ccc;">已取消</span>
                        <span v-if="item.status == 3" style="color: #f50;">已拒绝</span>
                        <span v-if="item.status == 4" style="color: #87d068;">已完成</span>
                      </template>
                      <template v-slot:item.operate="{ item }">
                        <v-btn text @click="handleServiceDetail(item.caseNumber)">详情</v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-col cols="12" class="mt-0 pt-0">
                    <v-pagination
                      v-model="servicePage.pageNo"
                      @input="handleServicePage"
                      :length="servicePage.pageCount"
                      total-visible="7"
                      class="orderPage"
                    ></v-pagination>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!--维修工单-->
          <v-tab-item value="repair_order" transition="fade-transition">
            <v-card flat>
              <v-card-text class="mt-12 mb-4">
                <v-row>
                  <v-col cols="12">
                    <v-data-table
                      :headers="repairHeaders"
                      :items="repairList"
                      :items-per-page="20"
                      hide-default-footer
                      class="orderTable"
                      no-data-text="暂无数据"
                    >
                      <template v-slot:item.status="{ item }">
                        <span v-if="item.status == 0" style="color: #000;">待受理</span>
                        <span v-if="item.status == 1" style="color: #1890FF;">处理中</span>
                        <span v-if="item.status == 2" style="color: #ccc;">已取消</span>
                        <span v-if="item.status == 3" style="color: #f50;">已拒绝</span>
                        <span v-if="item.status == 4" style="color: #87d068;">已完成</span>
                      </template>
                      <template v-slot:item.returnsChannel="{ item }">
                        <span v-if="item.returnsChannel == 'express'">快递</span>
                        <span v-else>自取</span>
                      </template>
                      <template v-slot:item.operate="{ item }">
                        <v-btn text @click="handleRepairDetail(item.caseNumber)">详情</v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-col cols="12" class="mt-0 pt-0">
                    <v-pagination
                      v-model="repairPage.pageNo"
                      @input="handleRepairPage"
                      :length="repairPage.pageCount"
                      total-visible="7"
                      class="orderPage"
                    ></v-pagination>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
.page-h1 {
  font-weight: 600;
  font-size: 56px;
  line-height: 62px;
}
.page-h2 {
  font-weight: 500;
  font-size: 48px;
}
.page-h3 {
  font-weight: 500;
  font-size: 36px;
  line-height: 31px;
}
.page-h4 {
  font-weight: 500;
  font-size: 26px;
}
.page-h5 {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
}
.page-tab {
  font-weight: 500;
  font-size: 26px;
}

.page-subtitle {
  font-weight: 500;
  font-size: 22px;
}
.page-content {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
}

.page-btn {
  font-weight: 400;
  font-size: 24px;
}

.page-list {
  li {
    list-style: none;
  }
}

.activity-title {
  margin-left: auto;
  margin-right: auto;
}
.large-btn {
  width: 300px;
  font-size: 24px;
}
.tab-title {
  font-weight: 500;
  font-size: 24px;
  padding: 10px 40px;
  border-bottom: 1px solid #d9d9d9;
}

.tab-mobile-title {
  padding: 10px 10px;
  border-bottom: 1px solid #d9d9d9;
}
.grey-block {
  background-color: #f5f5f7;
  color: #0573d9;
  border-radius: 24px;
  padding: 30px 40px;
}

.mdm-image {
  height: 60px;
  width: 60px;
  object-fit: contain;
}
.jamf-image {
  height: 59px;
  margin-bottom: 20px;
}

.vm-image {
  width: 124px;
}

.orderTable {
  .v-data-table-header {
    background-color: #DCDCDC;
  }
}

.orderPage {
  float: right;
}

.intentionProduct {
  width: 400px;
  .image {
    display: flex;
    img {
      margin: auto 0;
      padding-right: 10px;
    }
  }

  .title {
    font-size: 15px !important;
    font-weight: 500;
  }
}

@media screen and (max-width:600px){
  .mdm-image {
    height: 30px;
    width: 30px;
  }
  .jamf-image {
    height: 40px;
  }

  .vm-image {
    width: 50px;
  }

  .intentionProduct {
    width: 200px;
  }
}
</style>

<script>
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_INTENT_LIST, 
  FETCH_ORDER_LIST,
  FETCH_SERVICE_ORDER_LIST,
  FETCH_REPAIR_ORDER_LIST,
  FETCH_JUMP_TOKEN
} from "@/store/actions.type";
import store from "@/store";

export default {
  data() {
    return {
      tabType: 'intention',
      intentionHeaders: [
        {
          text: '订单编号',
          align: 'start',
          sortable: false,
          value: 'orderNo',
        },
        { text: '下单时间', value: 'orderTime' },
        { text: '下单企业', value: 'enterpriseFullNameInput' },
        { text: '下单人', value: 'userNameInput' },
        { text: '下单人联系方式', value: 'telInput', sortable: false },
        { text: '商品数量', value: 'productCount' },
        { text: '订单金额', value: 'orderAmount' },
      ],
      intentionList: [],
      intentionPage: {
        pageNo: 1,
        pageSize: 10,
        pageCount: 1,
        total: 0,
      },
      expanded: [],
      singleExpand: false,
      intentionSubHeaders: [
        {
          text: '商品信息',
          align: 'start',
          sortable: false,
          value: 'productName',
        },
        { text: '采购方式', value: 'purchaseType' },
        { text: '数量', value: 'buyCount' },
        { text: '单价(RMB)', value: 'salesPrice' },
        { text: '金额', value: 'subOrderAmount' },
      ],
      orderHeaders: [
        {
          text: 'ESP订单编号',
          align: 'start',
          sortable: false,
          value: 'orderNumber',
        },
        { text: '销售姓名', value: 'salesName' },
        { text: '状态', value: 'status' },
        { text: '订单总金额', value: 'total' },
        { text: '订单包含产品数量', value: 'totalQuantity' },
        { text: '操作', value: 'operate', sortable: false },
      ],
      orderList: [],
      orderPage: {
        pageNo: 1,
        pageSize: 10,
        pageCount: 1,
        total: 0,
      },
      currentOrderId: null,
      showOrderDialog: false,
      serviceHeaders: [
        {
          text: 'ESP⼯单编号',
          align: 'start',
          sortable: false,
          value: 'caseNumber',
          width: '200px',
        },
        { text: '所属服务', value: 'catalog', width: '100px', },
        { text: '状态', value: 'status', width: '80px', },
        { text: '创建⼈姓名', value: 'creator', width: '120px', },
        { text: '创建时间', value: 'createTime', width: '200px', },
        { text: '更新时间', value: 'updateTime', width: '200px', },
        { text: '操作', value: 'operate', sortable: false, width: '80px', },
      ],
      serviceList: [],
      servicePage: {
        pageNo: 1,
        pageSize: 10,
        pageCount: 1,
        total: 0,
      },
      repairHeaders: [
        {
          text: 'ESP⼯单编号',
          align: 'start',
          sortable: false,
          value: 'caseNumber',
          width: '200px',
        },
        { text: '所属服务', value: 'catalog', width: '100px', },
        { text: '状态', value: 'status', width: '80px', },
        { text: '创建⼈', value: 'creator', width: '100px', },
        { text: '寄回地址', value: 'returnsAddress', width: '200px', },
        { text: '寄回方式', value: 'returnsChannel', width: '80px', },
        { text: '寄回接收人', value: 'returnsContactName', width: '100px', },
        { text: '寄回联系电话', value: 'returnsContactPhone', width: '100px', },
        { text: '创建时间', value: 'createTime', width: '200px', },
        { text: '更新时间', value: 'updateTime', width: '200px', },
        { text: '操作', value: 'operate', sortable: false, width: '80px', },
      ],
      repairList: [],
      repairPage: {
        pageNo: 1,
        pageSize: 10,
        pageCount: 1,
        total: 0,
      },
      currentRepairId: null,
      showRepairDialog: false,
    };
  },
  methods: {
    handleTabChange(item) {
      switch (item) {
        case 'intention':
          this.intentionPage.page = 1;
          this.handleIntentionPage();
          break;
        case 'purchase_order':
          this.orderPage.page = 1;
          this.handleOrderPage();
          break;
        case 'service_order':
          this.servicePage.page = 1;
          this.handleServicePage();
          break;
        case 'repair_order':
          this.repairPage.page = 1;
          this.handleRepairPage();
          break;
      }
    },
    handleIntentionPage() {
      store.dispatch(FETCH_INTENT_LIST, this.intentionPage)
        .then((data) => {
          if (data.data.code !== 200) {
            store.commit(SET_ERROR, {msg: data.data.message});
            return;
          }
          
          this.intentionList = data.data.result.records;
          this.intentionPage.pageCount = data.data.result.pages;
          this.intentionPage.total = data.data.result.total;
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
    handleOrderPage() {
      store.dispatch(FETCH_ORDER_LIST, this.orderPage)
        .then((data) => {
          if (data.data.code !== 200) {
            store.commit(SET_ERROR, {msg: data.data.message});
            return;
          }
          
          this.orderList = data.data.result.records;
          this.orderPage.pageCount = data.data.result.pages;
          this.orderPage.total = data.data.result.total;
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
    handleServicePage() {
      store.dispatch(FETCH_SERVICE_ORDER_LIST, this.servicePage)
        .then((data) => {
          if (data.data.code !== 200) {
            store.commit(SET_ERROR, {msg: data.data.message});
            return;
          }
          
          this.serviceList = data.data.result.records;
          this.servicePage.pageCount = data.data.result.pages;
          this.servicePage.total = data.data.result.total;
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
    handleRepairPage() {
      store.dispatch(FETCH_REPAIR_ORDER_LIST, this.repairPage)
        .then((data) => {
          if (data.data.code !== 200) {
            store.commit(SET_ERROR, {msg: data.data.message});
            return;
          }
          
          this.repairList = data.data.result.records;
          this.repairPage.pageCount = data.data.result.pages;
          this.repairPage.total = data.data.result.total;
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
    handleOrderDetail(id) {
      this.currentOrderId = id;
      this.showOrderDialog = true;
    },
    handleServiceDetail(id) {
      store.dispatch(FETCH_JUMP_TOKEN)
        .then((data) => {
          if (data.data.code !== 200) {
            store.commit(SET_ERROR, {msg: data.data.message});
            return;
          }
          
          const baseUrl = process.env.VUE_APP_ESP_URL;
          const token = data.data.result;
          window.open(`${baseUrl}/workbench/equip?token=${token}&key=${id}`, "_blank");
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
    handleRepairDetail(id) {
      // this.currentRepairId = id;
      // this.showRepairDialog = true;
      store.dispatch(FETCH_JUMP_TOKEN)
        .then((data) => {
          if (data.data.code !== 200) {
            store.commit(SET_ERROR, {msg: data.data.message});
            return;
          }
          
          const baseUrl = process.env.VUE_APP_ESP_URL;
          const token = data.data.result;
          window.open(`${baseUrl}/workbench/repair?token=${token}&key=${id}`, "_blank");
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
  },
  mounted() {
    this.tabType = this.$route.query.type || this.tabType;
    this.handleTabChange(this.tabType);
  }
}
</script>
