<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-card flat>
          <v-img src="/static/account/login-banner.png" class="white--text align-end">
          </v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mt-16 mb-4">
        <p class="text-center text-h4 text-md-h3 font-weight-bold mt-4">登录账户</p>
      </v-col>
      <v-col cols="12" class="mb-16">
        <p class="text-center text-md-h5 my-4 grey--text">
          登录后您可查询服务订单的进度，查看最新的优惠及培训活动等信息。
        </p>
      </v-col>
      <v-col cols="12" class="text-center mb-12">
        <v-form v-model="validForm" @submit.prevent="submit">
          <v-row>
            <v-col cols="12" md="8" offset-md="2" class="my-0 py-0 text-center">
              <v-text-field
                name="username"
                v-model="loginForm.username"
                :rules="[rules.required]"
                label="用户名"
                hide-details
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="8" offset-md="2" class="my-0 pb-0 text-center">
              <v-text-field
                name="password"
                v-model="loginForm.password"
                :rules="[rules.required]"
                type="password"
                label="密码"
                hide-details
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" class="my-4 py-4 text-center">
              <v-btn
                x-large
                depressed
                :loading="loading"
                :disabled="loading"
                class="rounded-xl large-btn"
                @click="handleLogin"
              >
                登录
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col cols="12" class="mb-16 text-center">
        没有账户？ <a href="/account">立即创建</a> 你的专属服务账户。
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
.page-h1 {
  font-weight: 600;
  font-size: 56px;
  line-height: 62px;
}
.page-h2 {
  font-weight: 500;
  font-size: 48px;
}
.page-h3 {
  font-weight: 500;
  font-size: 36px;
  line-height: 31px;
}
.page-h4 {
  font-weight: 500;
  font-size: 26px;
}
.page-h5 {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
}
.page-tab {
  font-weight: 500;
  font-size: 26px;
}

.page-subtitle {
  font-weight: 500;
  font-size: 22px;
}
.page-content {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
}

.page-btn {
  font-weight: 400;
  font-size: 24px;
}

.page-list {
  li {
    list-style: none;
  }
}

.activity-title {
  margin-left: auto;
  margin-right: auto;
}
.large-btn {
  width: 300px;
  font-size: 24px;
}
.tab-title {
  font-weight: 500;
  font-size: 24px;
  padding: 10px 40px;
  border-bottom: 1px solid #d9d9d9;
}

.tab-mobile-title {
  padding: 10px 10px;
  border-bottom: 1px solid #d9d9d9;
}
.grey-block {
  background-color: #f5f5f7;
  color: #0573d9;
  border-radius: 24px;
  padding: 30px 40px;
}

.mdm-image {
  height: 60px;
  width: 60px;
  object-fit: contain;
}
.jamf-image {
  height: 59px;
  margin-bottom: 20px;
}

.vm-image {
  width: 124px;
}

@media screen and (max-width:600px){
  .mdm-image {
    height: 30px;
    width: 30px;
  }
  .jamf-image {
    height: 40px;
  }

  .vm-image {
    width: 50px;
  }
}
</style>

<script>
import { SET_ERROR } from "@/store/mutations.type";
import {
  LOGIN
} from "@/store/actions.type";
import store from "@/store";

export default {
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
      },
      validForm: true,
      loginForm: {
        username: '',
        password: '',
        userType: 1,
      },
      otherQuery: {},
      loading: false,
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true
    }
  },
  methods: {
    handleLogin() {
      if (!this.validForm) {
        return;
      }

      this.loading = true;

      store.dispatch(LOGIN, this.loginForm)
        .then(() => {
          this.loading = false;
          this.$store.commit(SET_ERROR, {msg: '登录成功', color: 'primary'});
          this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    }
  }
}
</script>
