var render, staticRenderFns
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.0_cache-loader@4.1.0_webpack@4.47.0__css-loader@3.6._lt4lsz5e2aihibmjrnrcojslke/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports