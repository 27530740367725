var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-img', {
    staticClass: "white--text align-end",
    attrs: {
      "src": "/static/account/login-banner.png"
    }
  })], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "mt-16 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-center text-h4 text-md-h3 font-weight-bold mt-4"
  }, [_vm._v("创建专属服务账户")])]), _c('v-col', {
    staticClass: "mb-16",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-center text-md-h5 my-4 grey--text"
  }, [_vm._v(" 注册后您可通过邮件或短信掌握服务订单的进度，以及获取最新的优惠及培训活动等信息。 ")])]), _c('v-col', {
    staticClass: "text-center mb-12",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.validForm,
      callback: function callback($$v) {
        _vm.validForm = $$v;
      },
      expression: "validForm"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "6",
      "md": "4",
      "offset-md": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "name": "surname",
      "rules": [_vm.rules.required],
      "label": "姓氏*",
      "outlined": ""
    },
    model: {
      value: _vm.registerForm.surname,
      callback: function callback($$v) {
        _vm.$set(_vm.registerForm, "surname", $$v);
      },
      expression: "registerForm.surname"
    }
  })], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "name": "name",
      "rules": [_vm.rules.required],
      "label": "名字*",
      "outlined": ""
    },
    model: {
      value: _vm.registerForm.name,
      callback: function callback($$v) {
        _vm.$set(_vm.registerForm, "name", $$v);
      },
      expression: "registerForm.name"
    }
  })], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "8",
      "offset-md": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "name": "phone",
      "rules": [_vm.rules.required, _vm.rules.phonenum],
      "label": "电话号码*",
      "outlined": ""
    },
    model: {
      value: _vm.registerForm.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.registerForm, "phone", $$v);
      },
      expression: "registerForm.phone"
    }
  })], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "8",
      "offset-md": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "name": "email",
      "rules": [_vm.rules.required, _vm.rules.email],
      "label": "example@mail.com*",
      "outlined": ""
    },
    model: {
      value: _vm.registerForm.email,
      callback: function callback($$v) {
        _vm.$set(_vm.registerForm, "email", $$v);
      },
      expression: "registerForm.email"
    }
  })], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "8",
      "offset-md": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "name": "username",
      "rules": [_vm.rules.required],
      "label": "用户名*",
      "outlined": ""
    },
    model: {
      value: _vm.registerForm.username,
      callback: function callback($$v) {
        _vm.$set(_vm.registerForm, "username", $$v);
      },
      expression: "registerForm.username"
    }
  })], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "8",
      "offset-md": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "name": "password",
      "rules": [_vm.rules.required, _vm.rules.sixLength, _vm.rules.password],
      "type": "password",
      "label": "密码*",
      "outlined": ""
    },
    model: {
      value: _vm.registerForm.password,
      callback: function callback($$v) {
        _vm.$set(_vm.registerForm, "password", $$v);
      },
      expression: "registerForm.password"
    }
  })], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "8",
      "offset-md": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "name": "confirmPassword",
      "rules": [_vm.rules.required, _vm.rules.sixLength, _vm.rules.password],
      "type": "password",
      "label": "确认密码*",
      "outlined": ""
    },
    model: {
      value: _vm.registerForm.confirmPassword,
      callback: function callback($$v) {
        _vm.$set(_vm.registerForm, "confirmPassword", $$v);
      },
      expression: "registerForm.confirmPassword"
    }
  })], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "8",
      "offset-md": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "name": "enterpriseFullName",
      "rules": [_vm.rules.required],
      "label": "公司全称*",
      "outlined": ""
    },
    model: {
      value: _vm.registerForm.enterpriseFullName,
      callback: function callback($$v) {
        _vm.$set(_vm.registerForm, "enterpriseFullName", $$v);
      },
      expression: "registerForm.enterpriseFullName"
    }
  })], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "8",
      "offset-md": "2"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "name": "provinceCode",
      "items": _vm.provinces,
      "item-text": "name",
      "item-value": "code",
      "label": "省",
      "outlined": ""
    },
    on: {
      "change": _vm.provinceChanged
    },
    model: {
      value: _vm.registerForm.provinceCode,
      callback: function callback($$v) {
        _vm.$set(_vm.registerForm, "provinceCode", $$v);
      },
      expression: "registerForm.provinceCode"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "name": "cityCode",
      "items": _vm.cities,
      "item-text": "name",
      "item-value": "code",
      "label": "市",
      "outlined": ""
    },
    on: {
      "change": _vm.cityChanged
    },
    model: {
      value: _vm.registerForm.cityCode,
      callback: function callback($$v) {
        _vm.$set(_vm.registerForm, "cityCode", $$v);
      },
      expression: "registerForm.cityCode"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "name": "areaCode",
      "items": _vm.areas,
      "item-text": "name",
      "item-value": "code",
      "label": "区",
      "outlined": ""
    },
    model: {
      value: _vm.registerForm.areaCode,
      callback: function callback($$v) {
        _vm.$set(_vm.registerForm, "areaCode", $$v);
      },
      expression: "registerForm.areaCode"
    }
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "8",
      "offset-md": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "name": "contactAddress",
      "rules": [_vm.rules.required],
      "label": "企业详细地址*",
      "outlined": ""
    },
    model: {
      value: _vm.registerForm.contactAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.registerForm, "contactAddress", $$v);
      },
      expression: "registerForm.contactAddress"
    }
  })], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "8",
      "offset-md": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "name": "contactName",
      "rules": [_vm.rules.required],
      "label": "企业联系人*",
      "outlined": ""
    },
    model: {
      value: _vm.registerForm.contactName,
      callback: function callback($$v) {
        _vm.$set(_vm.registerForm, "contactName", $$v);
      },
      expression: "registerForm.contactName"
    }
  })], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "8",
      "offset-md": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "name": "contactPhone",
      "rules": [_vm.rules.required, _vm.rules.phonenum],
      "label": "企业联系人电话*",
      "outlined": ""
    },
    model: {
      value: _vm.registerForm.contactPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.registerForm, "contactPhone", $$v);
      },
      expression: "registerForm.contactPhone"
    }
  })], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "6",
      "md": "4",
      "offset-md": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "name": "deptName",
      "label": "部门",
      "outlined": ""
    },
    model: {
      value: _vm.registerForm.deptName,
      callback: function callback($$v) {
        _vm.$set(_vm.registerForm, "deptName", $$v);
      },
      expression: "registerForm.deptName"
    }
  })], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "name": "jobTitle",
      "label": "职位",
      "outlined": ""
    },
    model: {
      value: _vm.registerForm.jobTitle,
      callback: function callback($$v) {
        _vm.$set(_vm.registerForm, "jobTitle", $$v);
      },
      expression: "registerForm.jobTitle"
    }
  })], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "8",
      "offset-md": "2"
    }
  }, [_c('v-text-field', {
    attrs: {
      "name": "licenseKey",
      "label": "社会统一信用代码",
      "outlined": ""
    },
    model: {
      value: _vm.registerForm.licenseKey,
      callback: function callback($$v) {
        _vm.$set(_vm.registerForm, "licenseKey", $$v);
      },
      expression: "registerForm.licenseKey"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8",
      "offset-md": "2"
    }
  }, [_c('div', {
    staticClass: "filePanel v-text-field d-flex justify-space-between justify-center",
    on: {
      "click": _vm.handleFileSelect
    }
  }, [_c('label', [_vm._v(_vm._s(_vm.uploadName))]), _c('v-btn', {
    attrs: {
      "depressed": ""
    },
    on: {
      "click": _vm.handleFileSelect
    }
  }, [_vm._v(_vm._s(_vm.uploadBtn))]), _c('input', {
    ref: "uploadFile",
    attrs: {
      "type": "file",
      "hidden": ""
    },
    on: {
      "change": _vm.handleUpload
    }
  })], 1)]), _c('v-col', {
    staticClass: "my-4 py-4 text-center",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-btn', {
    staticClass: "rounded-xl large-btn",
    attrs: {
      "x-large": "",
      "depressed": "",
      "loading": _vm.loading,
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.handleRegister
    }
  }, [_vm._v(" 注册 ")])], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }