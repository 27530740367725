<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-card flat>
          <v-img src="/static/activity/act-banner.png" class="white--text align-end">
          </v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mt-16 mb-4">
        <p class="text-center text-h4 text-md-h3 font-weight-bold mt-4">活动和培训</p>
      </v-col>
      <v-col cols="12" class="mb-16">
        <p class="text-center text-md-h5 my-4 grey--text text-pretty">
          我们提供专业且丰富的行业分享，及移动设备管理培训。<br />
          帮助企业IT人员快速对企业设备问题提供支持和相应，强力赋能企业效率
        </p>
      </v-col>
      <v-col cols="12" class="text-center mb-12">
        <v-btn
          x-large
          depressed
          color="primary"
          class="rounded-xl large-btn"
          v-log="'ACTIVITY_WITH_CONTACT'"
          @click="contactDialog = true"
        >
          与我们取得联系
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-tabs 
          centered 
          plain 
          transition="fade-transition" 
          prev-icon="chevron_left"
          next-icon="chevron_right"
          show-arrows
        >
          <v-tab :class="[$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']">Apple 培训</v-tab>
          <v-tab :class="[$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']">Jamf 培训</v-tab>
          <v-tab :class="[$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']">市场和活动</v-tab>

          <v-tab-item :transition="fade-transition">
            <v-card flat>
              <v-card-title :class="['mt-16', 'mb-16', $vuetify.breakpoint.mobile ? 'text-h5' : 'text-h3']">
                <span class="activity-title text-h5 text-md-h4 font-weight-bold">Apple Professional Training</span>
              </v-card-title>
              <v-card-subtitle class="text-center activity-subtitle text-h6 mb-12">
                无论你是 Help Desk 人员，还是在企业中管理设备，
                Apple Professional Training 项目的 IT 课程都为你提供了这些角色所需的知识和技能，
                帮助你了解掌握如何支持和大规模部署管理 Apple 设备的内容。
              </v-card-subtitle>

              <v-card-title>
                <span class="activity-title text-h4 text-md-h3 font-weight-bold mb-4">Apple 设备支持</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="mt-4 mb-4">
                <v-row>
                  <v-col cols="12" md="6" lg="6">
                    <v-img src="/static/activity/h1.png"></v-img>
                  </v-col>
                  <v-col cols="12" md="6" lg="6">
                    <v-row>
                      <v-col cols="12" md="12" lg="12">
                        <span class="page-h5">课程内容</span>
                      </v-col>
                      <v-col cols="12" md="12" lg="12">
                        <p class="page-content">介绍用于为组织内的 Mac、iPhone 和 iPad 用户提供支持的工具和服务。展示如何为 Apple 设备提供支持和进行故障诊断的最佳方法。</p>
                      </v-col>
                      <v-col cols="12" md="12" lg="12">
                        <span class="page-h5">考试认证</span>
                      </v-col>
                      <v-col cols="12" md="12" lg="12">
                        <p class="text-md-h5 my-2 grey--text">Apple Certified Support Professional 认证</p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text>
                <v-list
                  two-line
                >
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title :class="[$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']">2024 年 8 月 9 日 - 8 月 11 日</v-list-item-title>
                      <v-list-item-subtitle :class="[$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']">北京 ｜ 9:00 - 18:00</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn color="primary" x-large class="page-h5" @click="contactDialog = true">报名</v-btn>
                    </v-list-item-action>
                  </v-list-item>

                  <v-divider></v-divider>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title :class="[$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']">2024 年 9 月 20 日 - 9 月 22 日</v-list-item-title>
                      <v-list-item-subtitle :class="[$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']">北京 ｜ 9:00 - 18:00</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn color="primary" x-large class="page-h5" @click="contactDialog = true">报名</v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>

              <v-card-title>
                <span class="activity-title text-h4 text-md-h3 font-weight-bold mb-4">Apple 设备管理与部署</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="mt-4 mb-4">
                <v-row>
                  <v-col cols="12" md="6" lg="6">
                    <v-img src="/static/activity/h2.png"></v-img>
                  </v-col>
                  <v-col cols="12" md="6" lg="6">
                    <v-row>
                      <v-col cols="12" md="12" lg="12" class="text-h6">
                        <span class="page-h5">课程内容</span>
                      </v-col>
                      <v-col cols="12" md="12" lg="12">
                        <p class="page-content">介绍用于为组织内的 Mac、iPhone 和 iPad 用户提供支持的工具和服务。展示如何为 Apple 设备提供支持和进行故障诊断的最佳方法。</p>
                      </v-col>
                      <v-col cols="12" md="12" lg="12" class="text-h6">
                        <span class="page-h5">考试认证</span>
                      </v-col>
                      <v-col cols="12" md="12" lg="12">
                        <p class="page-content">Apple Certified Support Professional 认证</p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text>
                <v-list
                  two-line
                >
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title :class="[$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']">2024 年 8 月 9 日 - 8 月 11 日</v-list-item-title>
                      <v-list-item-subtitle :class="[$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']">北京 ｜ 9:00 - 18:00</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn color="primary" x-large class="page-h5" @click="contactDialog = true">报名</v-btn>
                    </v-list-item-action>
                  </v-list-item>

                  <v-divider></v-divider>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title :class="[$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']">2024 年 9 月 20 日-9 月 22 日</v-list-item-title>
                      <v-list-item-subtitle :class="[$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']">北京 ｜ 9:00 - 18:00</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn color="primary" x-large class="page-h5" @click="contactDialog = true">报名</v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :transition="fade-transition">
            <v-card flat>
              <v-card-title class="mt-16 text-h3">
                <span class="activity-title text-h4 text-md-h3 font-weight-bold">Jamf 培训</span>
              </v-card-title>
              <v-card-subtitle class="text-center activity-subtitle text-h5 mb-12">
                由于专注于 Apple 生态系统，全球的行业领导者选择 Jamf：
              </v-card-subtitle>
              <v-card-subtitle class="activity-subtitle text-h6 mb-2">
                Jamf 是唯一可远程连接、管理和保护 Apple 用户、设备和服务的大规模 Apple 企业管理解决方案，提供以 Jamf Pro 管理 macOS、iOS 与 tvOS 的实操培训，提高生产力、协助使用者达成更多、 掌握最佳实践。持续提升技术人员的技能，帮助您使用 apple 工作达到巅峰。
              </v-card-subtitle>

              <v-img src="/static/activity/h3.png"></v-img>

              <v-card-subtitle class="activity-subtitle text-h6 mb-2">
                Jamf 独特的培训方法
              </v-card-subtitle>
              <v-card-text>
                <p class="page-content">Jamf 培训课程中的回应教学法，提供以 Jamf Pro 管理 macOS、iOS 与 tvOS 的实操培训。现在就成为您机构内不可或缺的一员，花更少的时间弄清楚如何做某事，而花更多的时间去完成它。</p>
              </v-card-text>

              <v-card-subtitle class="activity-subtitle text-h6 mb-2">
                Jamf 培训课程有助于
              </v-card-subtitle>
              <v-card-text>
                <p class="page-content">
                  1 、提高生产力<br />
                  2、赋能最终客户<br />
                  3、掌握最佳实践<br />
                </p>
              </v-card-text>

              <v-divider></v-divider>
              <v-card-text class="mt-4 mb-4">
                <v-row>
                  <v-col cols="12" md="6" lg="6">
                    <v-img src="/static/activity/h4.png"></v-img>
                  </v-col>
                  <v-col cols="12" md="6" lg="6">
                    <v-row>
                      <v-col cols="12" md="12" lg="12" class="text-h6">
                        <span class="page-h5">课程内容</span>
                      </v-col>
                      <v-col cols="12" md="12" lg="12">
                        <p class="page-content">介绍用于为组织内的 Mac、iPhone 和 iPad 用户提供支持的工具和服务。展示如何为 Apple 设备提供支持和进行故障诊断的最佳方法。</p>
                      </v-col>
                      <v-col cols="12" md="12" lg="12" class="text-h6">
                        <span class="page-h5">考试认证</span>
                      </v-col>
                      <v-col cols="12" md="12" lg="12">
                        <p class="page-content">Apple Certified Support Professional 认证</p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text>
                <v-list
                  two-line
                >
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title :class="[$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']">2024 年 8 月 9 日 - 8 月 11 日</v-list-item-title>
                      <v-list-item-subtitle :class="[$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']">北京 ｜ 9:00 - 18:00</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn color="primary" x-large class="page-h5" @click="contactDialog = true">报名</v-btn>
                    </v-list-item-action>
                  </v-list-item>

                  <v-divider></v-divider>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title :class="[$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']">2024 年 9 月 20 日 - 9 月 22 日</v-list-item-title>
                      <v-list-item-subtitle :class="[$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']">北京 ｜ 9:00 - 18:00</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn color="primary" x-large class="page-h5" @click="contactDialog = true">报名</v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item :transition="fade-transition">
            <v-card flat>
              <v-card-title class="mt-16 text-h3">
                <span class="activity-title text-h4 text-md-h3 font-weight-bold">市场活动</span>
              </v-card-title>
              <v-card-subtitle class="text-center activity-subtitle text-h5 mb-12">
                Apple 在商务中的应用
              </v-card-subtitle>
              <v-card-title class="mt-16 text-h4">
                <span class="activity-title">iOS 助力保险行业车商业务研讨会</span>
              </v-card-title>
              <v-divider></v-divider>

              <v-img src="/static/activity/h5.png"></v-img>

              <v-card-text>
                <v-row>
                  <v-col cols="12" md="2" lg="2">
                    <span class="page-h5">活动内容</span>
                  </v-col>
                  <v-col cols="12" md="10" lg="10">
                    <p class="page-content">Apple 企业团队与企业解决方案合作伙伴，邀您共同探讨在汽车行业剧变、车险综合改革的巨大挑战下，如何利用新技术及行业解决方案帮助保险公司和汽车品牌拓展业务的多维度价值，创新业务销售场景，加速保险行业与汽车行业深度融合的价值再造。</p>
                  </v-col>

                  <v-col cols="12" md="2" lg="2">
                    <span class="page-h5">参与对象</span>
                  </v-col>
                  <v-col cols="12" md="10" lg="10">
                    <p class="page-content">本次研讨会针对保险公司、车商企业的业务部门和 IT 部门的相关负责人及领导团队成员。</p>
                  </v-col>

                  <v-col cols="12" md="2" lg="2">
                    <b class="page-h5">活动时间</b>
                  </v-col>
                  <v-col cols="12" md="10" lg="10">
                    <p class="page-content">2024 年 12 月 3 日 ｜ 14:00 - 17:00 13:30 签到注册</p>
                  </v-col>

                  <v-col cols="12" md="2" lg="2">
                    <b class="page-h5">活动地址</b>
                  </v-col>
                  <v-col cols="12" md="10" lg="10">
                    <p class="page-content">北京市朝阳区建国门外大街1号，中国大饭店，一层，宴会厅B</p>
                  </v-col>

                  <v-col cols="12" md="12" lg="12" class="text-center">
                    <v-btn color="primary" x-large class="page-h5" @click="contactDialog = true">报名</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <contact-dialog :showDialog="contactDialog" type="ACTIVITY_TRAINING" @dialogCloseEvent="contactDialog = false"></contact-dialog>
  </v-container>
</template>

<style lang="scss" scoped>
.page-h1 {
  font-weight: 600;
  font-size: 56px;
  line-height: 62px;
}
.page-h2 {
  font-weight: 500;
  font-size: 48px;
}
.page-h3 {
  font-weight: 500;
  font-size: 36px;
  line-height: 31px;
}
.page-h4 {
  font-weight: 500;
  font-size: 26px;
}
.page-h5 {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
}
.page-h6 {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -2px;
}
.page-tab {
  font-weight: 500;
  font-size: 26px;
}

.page-subtitle {
  font-weight: 500;
  font-size: 22px;
}
.page-content {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
}
.page-m-content {
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
}

.page-ms-content {
  font-weight: 300;
  font-size: 12px;
  line-height: 30px;
}
.page-btn {
  font-weight: 400;
  font-size: 24px;
}

.page-list {
  li {
    list-style: none;
  }
}

.activity-title {
  margin-left: auto;
  margin-right: auto;
}
.large-btn {
  width: 300px;
  font-size: 24px;
}
.tab-title {
  font-weight: 500;
  font-size: 24px;
  padding: 10px 40px;
  border-bottom: 1px solid #d9d9d9;
}

.tab-mobile-title {
  padding: 10px 10px;
  border-bottom: 1px solid #d9d9d9;
}

@media screen and (max-width:600px){
  .text-pretty {
    word-break: keep-all;
  }
}
</style>

<script>
export default {
  data() {
    return {
      contactDialog: false,
    }
  }
}
</script>
