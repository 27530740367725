var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-img', {
    staticClass: "white--text align-end",
    attrs: {
      "src": "/static/sales-support/sales_support_banner.png"
    }
  })], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "mt-16 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-center text-h4 text-md-h3 font-weight-bold mt-4"
  }, [_vm._v("销售支持")])]), _c('v-col', {
    staticClass: "mb-16",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-center text-md-h5 my-4 grey--text text-pretty"
  }, [_vm._v(" 我们提供线上商城及线下商店两种灵活的采购方式，"), _c('br'), _vm._v(" 囊括专业的售前咨询及售后服务，企业可以灵活定制个性化的采购方案及相关支持。 ")])]), _c('v-col', {
    staticClass: "text-center mb-12",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    directives: [{
      name: "log",
      rawName: "v-log",
      value: 'SALES_SUPPORT_WITH_CONTACT',
      expression: "'SALES_SUPPORT_WITH_CONTACT'"
    }],
    staticClass: "rounded-xl large-btn",
    attrs: {
      "x-large": "",
      "depressed": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.contactDialog = true;
      }
    }
  }, [_vm._v(" 与我们取得联系 ")])], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('v-tabs', {
    attrs: {
      "centered": "",
      "plain": "",
      "transition": "fade-transition",
      "prev-icon": "chevron_left",
      "next-icon": "chevron_right",
      "show-arrows": ""
    }
  }, [_c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']
  }, [_vm._v(" ESC企业解决方案中心 ")]), _c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']
  }, [_vm._v(" ESP企业服务平台 ")]), _c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']
  }, [_vm._v(" EC企业员工优选商城 ")]), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "mt-16 mb-4"
  }, [_c('span', {
    staticClass: "margin-center text-h4 text-md-h3 font-weight-bold"
  }, [_vm._v("ESC企业解决方案中心")])]), _c('v-card-text', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-center text-md-h5 my-4 grey--text text-pretty",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" Apple 企业解决方案中心，"), _c('br'), _vm._v(" 一个集产品零售、设备维修、解决方案于一身的全新的体验中心。"), _c('br'), _vm._v(" 把 Apple 企业级服务带到企业及员工身边，"), _c('br'), _vm._v(" 用生活中爱不释手的产品，为大家提供耳目一新的工作体验。 ")])]), _c('v-col', {
    staticClass: "d-flex justify-space-around my-8",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('img', {
    staticClass: "support-image",
    attrs: {
      "src": "/static/sales-support/s1.png"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("产品体验")])]), _c('div', {
    staticClass: "text-center"
  }, [_c('img', {
    staticClass: "support-image",
    attrs: {
      "src": "/static/sales-support/s2.png"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("解决方案")])]), _c('div', {
    staticClass: "text-center"
  }, [_c('img', {
    staticClass: "support-image",
    attrs: {
      "src": "/static/sales-support/s3.png"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("技术培训")])]), _c('div', {
    staticClass: "text-center"
  }, [_c('img', {
    staticClass: "support-image",
    attrs: {
      "src": "/static/sales-support/s4.png"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("售后维修")])])]), _c('v-col', {
    staticClass: "text-md-h5 my-4 grey--text",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 覆盖全国的 13 个大型科技园区的企业解决方案中心，"), _c('br'), _vm._v(" 致力为为高新园区的每一位企业客户和企业员工提供。 产品销售、维修和方案交流于一体的一站式服务 ")])])], 1), _c('v-card-title', {
    staticClass: "mt-16 mb-4 text-h4"
  }, [_c('span', {
    staticClass: "margin-center text-h4 text-md-h3 font-weight-bold"
  }, [_vm._v("ESC覆盖城市及地址")])]), _c('v-row', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', {
    attrs: {
      "centered": "",
      "plain": "",
      "prev-icon": "chevron_left",
      "next-icon": "chevron_right",
      "show-arrows": ""
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, _vm._l(_vm.cityContacts, function (cc, index) {
    return _c('v-tab', {
      key: index
    }, [_vm._v(" " + _vm._s(cc.city) + " ")]);
  }), 1), _c('v-tabs-items', {
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, _vm._l(_vm.cityContacts, function (cc, index) {
    return _c('v-tab-item', {
      key: index
    }, [_c('v-card', {
      attrs: {
        "flat": ""
      }
    }, _vm._l(cc.contacts, function (c, ci) {
      return _c('v-card-text', {
        key: ci
      }, [_c('v-row', {
        staticClass: "py-8"
      }, [_c('v-col', {
        staticClass: "page-h5 py-1",
        attrs: {
          "cols": "12"
        }
      }, [_vm._v(" " + _vm._s(c.title) + " ")]), _c('v-col', {
        staticClass: "page-content py-1",
        attrs: {
          "cols": "12"
        }
      }, [_vm._v(" 地址:" + _vm._s(c.address) + " ")]), c.phone !== null ? _c('v-col', {
        staticClass: "page-content py-1",
        attrs: {
          "cols": "12"
        }
      }, [_vm._v(" 电话:" + _vm._s(c.phone) + " ")]) : _vm._e()], 1)], 1);
    }), 1)], 1);
  }), 1)], 1)], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "mt-16 mb-4 text-h3"
  }, [_c('span', {
    staticClass: "margin-center text-h4 text-md-h3 font-weight-bold"
  }, [_vm._v("ESP企业服务平台")])]), _c('v-card-text', [_c('p', {
    staticClass: "text-center text-md-h5 my-4 grey--text text-pretty"
  }, [_vm._v(" 企业级服务的专属平台"), _c('br'), _vm._v(" 高效省时的 IT 服务，随您定制的解决方案。"), _c('br'), _vm._v(" 企业服务平台，改变传统移动化服务模式，"), _c('br'), _vm._v(" 建立智能化、流程化、系统化的服务新标准，解放IT生产力， 提供移动设备的全生命周期服务，让一切变得井然有序。 ")])]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('img', {
    staticClass: "support-image",
    attrs: {
      "src": "/static/sales-support/e1.png"
    }
  }), _c('span', [_vm._v("订单管理")])]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('img', {
    staticClass: "support-image",
    attrs: {
      "src": "/static/sales-support/e2.png"
    }
  }), _c('span', [_vm._v("资产管理")])]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('img', {
    staticClass: "support-image",
    attrs: {
      "src": "/static/sales-support/e3.png"
    }
  }), _c('span', [_vm._v("远程诊断服务")])]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('img', {
    staticClass: "support-image",
    attrs: {
      "src": "/static/sales-support/e4.png"
    }
  }), _c('span', [_vm._v("设备运维服务")])]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('img', {
    staticClass: "support-image",
    attrs: {
      "src": "/static/sales-support/e5.png"
    }
  }), _c('span', [_vm._v("维修申报服务")])]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('img', {
    staticClass: "support-image",
    attrs: {
      "src": "/static/sales-support/e6.png"
    }
  }), _c('span', [_vm._v("知识库及咨询")])]), _c('v-col', {
    staticClass: "text-md-h5 my-4 grey--text",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 提供一站式 ESP 云端服务，简化服务流程，提升服务体验！ ")])])], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "mt-16 mb-4"
  }, [_c('span', {
    staticClass: "margin-center text-h4 text-md-h3 font-weight-bold"
  }, [_vm._v("EC企业员工优选商城")])]), _c('v-card-text', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-center text-md-h5 my-4 grey--text text-pretty",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" Employee Choice 员工之选商城 （EC）是针对企业员工选购 Apple 产品的专属平台，"), _c('br'), _vm._v(" 由 Apple 授权经销商提供专业支持和统一服务，为大家提供专属福利。 ")])]), _c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 专属福利 ")]), _c('v-col', {
    staticClass: "text-center my-8",
    attrs: {
      "cols": "3"
    }
  }, [_c('img', {
    staticClass: "support-image",
    attrs: {
      "src": "/static/sales-support/c1.png"
    }
  }), _c('span', [_vm._v("官方正品及活动")])]), _c('v-col', {
    staticClass: "text-center my-8",
    attrs: {
      "cols": "3"
    }
  }, [_c('img', {
    staticClass: "support-image",
    attrs: {
      "src": "/static/sales-support/c2.png"
    }
  }), _c('span', [_vm._v("员工专属内购价格")])]), _c('v-col', {
    staticClass: "text-center my-8",
    attrs: {
      "cols": "3"
    }
  }, [_c('img', {
    staticClass: "support-image",
    attrs: {
      "src": "/static/sales-support/c3.png"
    }
  }), _c('span', [_vm._v("驻场设备清洁和检测")])]), _c('v-col', {
    staticClass: "text-center my-8",
    attrs: {
      "cols": "3"
    }
  }, [_c('img', {
    staticClass: "support-image",
    attrs: {
      "src": "/static/sales-support/c4.png"
    }
  }), _c('span', [_vm._v("产品使用定制培训")])]), _c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 购买流程 ")]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 1.扫描二维码关注「 EC 员工之选 」公众号"), _c('br'), _c('br'), _vm._v(" 2.从弹出的欢迎语点击 登录"), _c('br'), _c('br'), _vm._v(" 3.使用企业邮箱登录后开始选购 ")]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/sales-support/b1.png"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('contact-dialog', {
    attrs: {
      "showDialog": _vm.contactDialog,
      "type": "SALES_SUPPORT"
    },
    on: {
      "dialogCloseEvent": function dialogCloseEvent($event) {
        _vm.contactDialog = false;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }