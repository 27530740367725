<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-card flat>
          <v-img src="/static/equipment-lease/equipment_lease_banner.png" class="white--text align-end">
          </v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mt-16 mb-4">
        <p class="text-center text-h4 text-md-h3 font-weight-bold mt-4">设备租赁</p>
      </v-col>
      <v-col cols="12" class="mb-16">
        <p class="text-center text-md-h5 my-4 grey--text text-pretty">
          按需租赁硬件及软件，以及相关配套的一系列增值服务。<br />
          让企业资产管理更加灵活，释放效能，优化产能。
        </p>
      </v-col>
      <v-col cols="12" class="text-center mb-12">
        <v-btn
          x-large
          depressed
          color="primary"
          class="rounded-xl large-btn"
          v-log="'EQUIPMENT_LEASE_WITH_CONTACT'"
          @click="contactDialog = true"
        >
          与我们取得联系
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-tabs 
          centered 
          plain 
          transition="fade-transition" 
          prev-icon="chevron_left"
          next-icon="chevron_right"
          show-arrows
        >
          <v-tab :class="[$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']">
            MDaaS 租赁服务
          </v-tab>
          <v-tab :class="[$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']">
            客户案例介绍
          </v-tab>

          <!-- MDaaS 租赁服务-->
          <v-tab-item transition="fade-transition">
            <v-card flat>
              <v-card-title class="mt-16 mb-4">
                <span class="activity-title text-h4 text-md-h3 font-weight-bold">MDaaS 租赁服务</span>
              </v-card-title>
              <v-card-title class="my-4">
                <span class="activity-title page-content font-weight-bold">重新定义租赁，助力企业资产高效运转</span>
              </v-card-title>

              <v-card-text class="mt-4 mb-4">
                <v-row>
                  <v-col cols="12" class="page-content">
                    <p>
                      我们专注于为不同行业提供定制化的硬件设备租赁以及 DaaS（Device as a Service）解决方案，
                      致力于提供全生命周期一站式租赁服务。<br />
                      我们的服务流程高效简便，付费模式灵活多样，旨在为企业大幅降低IT设备的运维成本，
                      优化客户的现金流动性，从而推动企业资产的高效管理与运用。
                    </p>
                  </v-col>
                  <v-col cols="4" class="text-center">
                    <img class="equip-image" src="/static/equipment-lease/e1.png">
                    <span :class="[$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']">优化资产配置</span>
                    <p :class="[$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']">
                      变采购为租赁<br />
                      释放流动资金
                    </p>
                  </v-col>
                  <v-col cols="4" class="page-h4 text-center">
                    <img class="equip-image" src="/static/equipment-lease/e2.png">
                    <span :class="[$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']">减轻运营压力</span>
                    <p :class="[$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']">
                      租赁配套服务<br />
                      人员压力减轻运维
                    </p>
                  </v-col>
                  <v-col cols="4" class="page-h4 text-center">
                    <img class="equip-image" src="/static/equipment-lease/e3.png">
                    <span :class="[$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']">批量换新设备</span>
                    <p :class="[$vuetify.breakpoint.mobile ? 'page-ms-content' : 'page-content']">
                      同等预算支出租赁<br />
                      可让更多员工换新设备
                    </p>
                  </v-col>
                  <v-col cols="12" class="page-h4">
                    1.移动设备，灵活选择
                  </v-col>
                  <v-col cols="12" class="page-content">
                    <p>
                      我们为客户提供全面丰富的 IT 硬件的产
                    </p>
                  </v-col>
                  <v-col cols="12" class="page-h4">
                    2.拥有方式，随心选择
                  </v-col>
                  <v-col cols="12" class="page-content">
                    <p>
                      彻底改变传统的设备采购方式，提高企业资金
                    </p>
                  </v-col>
                  <v-col cols="12" class="page-h4">
                    3.提升效率，减轻压力
                  </v-col>
                  <v-col cols="12" class="page-content">
                    <p>
                      高效的跨平台设备管理平台，对设备进行批量操作，进行部署、配置、应用下发和管理管控。提升企业IT管理效率，保障设备数据安全，减轻IT管控压力。
                    </p>
                  </v-col>
                  <v-col cols="12" class="page-h4">
                    4.运营维护，轻松打理
                  </v-col>
                  <v-col cols="12" class="page-content">
                    <p>
                      我们提供丰富多样的运维服务选项，帮您打理移动化设备的同时，在各个阶段为您提供支持，减轻您的运维压力，释放更多IT资源。
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-divider />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="page-h4 text-center mt-8 mb-6">
                    MDaaS企业客户服务旅程
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="d-flex justify-space-around my-4">
                    <div class="text-center">
                      <span :class="[$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']">产品选型</span>
                      <p :class="[$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']">
                        根据企业需求<br />
                        选择硬件设备
                      </p>
                    </div>
                    <div class="text-center">
                      <v-icon large class="vertical-center">chevron_right</v-icon>
                    </div>
                    <div class="text-center">
                      <span :class="[$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']">选择服务</span>
                      <p :class="[$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']">
                        根据企业需求<br />
                        选择适合的服务
                      </p>
                    </div>
                    <div class="text-center">
                      <v-icon large class="vertical-center">chevron_right</v-icon>
                    </div>
                    <div class="text-center">
                      <span :class="[$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']">远程部署</span>
                      <p :class="[$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']">
                        批量部署<br />
                        批量定制
                      </p>
                    </div>
                  </v-col>

                  <v-col cols="12" class="d-flex justify-space-around my-4">
                    <div class="text-center">
                      <span :class="[$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']">设备发放</span>
                      <p :class="[$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']">
                        可按客户需求指定地点<br />
                        并重新包装产品
                      </p>
                    </div>
                    <div class="text-center">
                      <v-icon large class="vertical-center">chevron_right</v-icon>
                    </div>
                    <div class="text-center">
                      <span :class="[$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']">MDM管理</span>
                      <p :class="[$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']">
                        资产管理、策略管理、安全登录<br />
                        内容管理、应用管理、设备管理
                      </p>
                    </div>
                    <div class="text-center">
                      <v-icon large class="vertical-center">chevron_right</v-icon>
                    </div>
                    <div class="text-center">
                      <span :class="[$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']">专享服务</span>
                      <p :class="[$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']">
                        专家团队提供专享<br />
                        硬件维修和热线服务
                      </p>
                    </div>
                  </v-col>

                  <v-col cols="12" class="d-flex justify-space-around my-4">
                    <div class="text-center">
                      <span :class="[$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']">现场服务</span>
                      <p :class="[$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']">
                        为客户提供现场服务，<br />
                        包括上门、巡检和驻场服务
                      </p>
                    </div>
                    <div class="text-center">
                      <v-icon large class="vertical-center">chevron_right</v-icon>
                    </div>
                    <div class="text-center">
                      <span :class="[$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']">设备期末处置</span>
                      <p :class="[$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']">
                        提供期末设备退回<br />
                        和数据清理
                      </p>
                    </div>
                    <div class="text-center">
                      <v-icon large class="vertical-center">chevron_right</v-icon>
                    </div>
                    <div class="text-center">
                      <span :class="[$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']">资产处置</span>
                      <p :class="[$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']">
                        所有退回的设备都会环保处置<br />
                        减少排放电子垃圾
                      </p>
                    </div>
                  </v-col>
                </v-row>

                <v-divider class="mb-8"/>
                <v-row>
                  <v-col cols="12" class="page-h4 my-8">
                    灵活租赁模式
                  </v-col>
                  <v-col cols="2">
                    <img class="lease-image" src="/static/equipment-lease/l1.png">
                  </v-col>
                  <v-col cols="10">
                    <span class="page-h5">固定租期</span>
                    <p class="page-content">
                      在固定的连续的时间内租赁，一般为1~3年，到期后，设备退还给出租方。
                    </p>
                  </v-col>
                  <v-col cols="2">
                    <img class="lease-image" src="/static/equipment-lease/l2.png">
                  </v-col>
                  <v-col cols="10">
                    <span class="page-h5">分期租赁</span>
                    <p class="page-content">
                      在固定的连续的时间内租赁，一般为1~3年，到期后，设备归承租方。
                    </p>
                  </v-col>
                  <v-col cols="2">
                    <img class="lease-image" src="/static/equipment-lease/l3.png">
                  </v-col>
                  <v-col cols="10">
                    <span class="page-h5">组合租赁</span>
                    <p class="page-content">
                      在租赁期内的不同阶段，可按照不同价格租赁，即分阶段定价;<br />
                      到期后，可选择买断设备或退回等;<br />
                      买断价格，分为事先商定的协议价和市场价;<br />
                      如提前买断，则视具体产品而具体协商，其测算原则为剩余租金加上设备残值。
                    </p>
                    <p class="page-h5">
                      *上述的组合租赁，属于深度定制化租赁，请联系销售顾问。
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!--客户案例介绍-->
          <v-tab-item transition="fade-transition">
            <v-card flat>
              <v-card-title class="mt-16 mb-4 text-h3">
                <span class="activity-title text-h4 text-md-h3 font-weight-bold">客户案例介绍</span>
              </v-card-title>

              <v-tabs 
                centered 
                plain 
                transition="fade-transition" 
                prev-icon="chevron_left"
                next-icon="chevron_right"
                show-arrows
              >
                <v-tab :class="$vuetify.breakpoint.mobile ? ['tab-mobile-title', 'text-h7'] : ['tab-title', 'text-h5']">
                  知名工业领域领导者
                </v-tab>
                <v-tab :class="$vuetify.breakpoint.mobile ? ['tab-mobile-title', 'text-h7'] : ['tab-title', 'text-h5']">
                  智能化汽车科技公司
                </v-tab>
                <v-tab :class="$vuetify.breakpoint.mobile ? ['tab-mobile-title', 'text-h7'] : ['tab-title', 'text-h5']">
                  知名清洁能源科技公司
                </v-tab>
                <v-tab :class="$vuetify.breakpoint.mobile ? ['tab-mobile-title', 'text-h7'] : ['tab-title', 'text-h5']">
                  知名电梯企业
                </v-tab>
                <v-tab :class="$vuetify.breakpoint.mobile ? ['tab-mobile-title', 'text-h7'] : ['tab-title', 'text-h5']">
                  大型综合企业集团
                </v-tab>

                <!--知名工业领域领导者-->
                <v-tab-item transition="fade-transition">
                  <v-card flat>
                    <v-card-text class="mt-4 mb-4">
                      <v-row>
                        <v-col cols="12" class="page-h4 mt-4 mb-4">
                          <span>客户介绍</span>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            客户为世界知名的工业和风能传动领域的引领者，是一家有着120多年历史积淀的企业，以高效、创新、稳定和优质的机械与电气零部件闻名于世，产品应用遍布世界各个角落。客户专注于齿轮箱的研制、开发和规模化生产，产品涵盖工业齿轮箱、风能齿轮箱、联轴器、状态检测系统等，广泛应用于煤炭、风电、水泥等行业。
                          </p>
                        </v-col>
                      </v-row>
                      <v-row class="mt-4 mb-4 grey-block">
                        <v-col cols="12" class="page-h4">
                          <span>项目背景</span>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            已经在使⽤租赁模式，希望能需找到⼀家既能提供租赁产品，同时能提供租赁 设备服务的服务商，来帮助客户提⾼ IT 运维能⼒，并提⾼客户满意度。
                          </p>
                        </v-col>
                      </v-row>
                      <v-row class="mt-4 mb-4 grey-block">
                        <v-col cols="12" class="page-h4">
                          <span>客户痛点</span>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            1、 集团预算机制变化<br />
                            2、 由于战略调整出现设备转换空窗期<br />
                            3、 租赁的设备缺乏管理和控制<br />
                            4、 租赁设备缺少常规运维和服务
                          </p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="page-h4 mt-4 mb-4">
                          <span>MDaaS 解决方案</span>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            1、 HP产品租赁，满⾜集团年初预算<br />
                            2、 三周内提供近1000台中⾼端设备，满⾜客户短期需求<br />
                            3、 随产品提供微软 AutoPilot 部署⼯具和 MDM 管理平台<br />
                            4、 提供设备运维、旧设备回收、资产管理平台、数据擦除
                          </p>
                        </v-col>
                      </v-row>
                      <v-row class="mt-4 mb-4 grey-block">
                        <v-col cols="12" class="page-h4">
                          <span>MDaaS 提供的价值</span>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            1、 HP产品租赁，满足集团年初预算满足财务政策<br />
                            2、 提升工作效率<br />
                            3、 技术支持<br />
                            4、 设备管理
                          </p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--智能化汽车科技公司-->
                <v-tab-item transition="fade-transition">
                  <v-card flat>
                    <v-card-text class="mt-4 mb-4">
                      <v-row>
                        <v-col cols="12" class="page-h4 mt-4 mb-4">
                          <span>客户介绍</span>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            客户公司作为汽⻋⾏业领先的汽⻋智能化科技公司，致⼒于持续打造⾏业领先 的智能⽹联⽣态开放平台，全⾯为⻋企赋能，创造更智能、更安全的出⾏体验。公 司以“让智慧出⾏驱动美好⽣活”为愿景，以“加速汽⻋智能化，创建⼈⻋新关系”为 使命，未来全球汽⻋智能化发展新格局。
                          </p>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <v-img src="/static/equipment-lease/s4.png" />
                        </v-col>
                      </v-row>
                      <v-row class="mt-4 mb-4 grey-block">
                        <v-col cols="12" class="page-h4">
                          <span>项目背景</span>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            为了应对外部环境的压⼒，同时解决内部不断增⻓的业务压⼒，该企业决定采⽤ 终端设备租赁及 IT ⼈员外包的⽅式来解决⼀次性资⾦投⼊过⼤的问题。
                          </p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="page-h4 mt-4 mb-4">
                          <span>客户痛点</span>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            1、 占⽤资⾦流问题<br />
                            客户员⼯总数超1万⼈，新增⼈员的设备缺⼝约近4000台，且都为 Macbook 及多个品牌的产品。<br />
                            2、 运维管理难度⼤<br />
                            客户在多地拥有多个分公司，员⼯数量众多，但是 IT 桌⾯运维⼈员仅为 6 ⼈，设备 部署压⼒巨⼤，维修协调困难。<br />
                            3、 管理经验弱<br />
                            内部 IT 桌⾯运维⼈员对于苹果设备接触较少，对于 Mac 设备的⽀持能⼒较弱，⽆ 法提供完善的⽀持。
                          </p>
                        </v-col>
                      </v-row>
                      <v-row class="mt-4 mb-4 grey-block">
                        <v-col cols="12" class="page-h4">
                          <span>MDaaS 解决方案</span>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          1、 以租代购
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            根据客户实际的需求定制组合租赁⽅案，不同需求匹配不同的产品。并提供随租随 还、意外免赔付等服务⽅案，优化了资⾦结构减少客户的⼀次性投⼊，将更多现⾦流投⼊到核⼼业 务中，提⾼市场竞争⼒。
                          </p>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          2、 驻场⼯程师
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            为客户提供驻场服务⼯程师，为其提供⾼效的 IT 运维服务，全⼒保障客户员⼯的 ⼯作效率，确保其将全部精⼒集中到业务发展中。
                          </p>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          3、 ABM + MDM
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            为客户提供 ABM + MDM 管理⽅案，协助客户 IT ⾼效便捷的管理 Apple 相关 的设备，确保苹果设备符合客户的 IT 安全政策。
                          </p>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          4、 专业培训
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            为最终⽤户提供专业的 Apple 设备的使⽤培训，帮助新员⼯更快的熟练使⽤ Apple 设备，提⾼⼯作效率，加快新员⼯迅速融⼊正常⼯作的进度。
                          </p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--知名清洁能源科技公司-->
                <v-tab-item transition="fade-transition">
                  <v-card flat>
                    <v-card-text class="mt-4 mb-4">
                      <v-row>
                        <v-col cols="12" class="page-h4 mt-4 mb-4">
                          <span>客户介绍</span>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            客户为国内知名清洁能源科技公司，是全球可信赖的清洁能源战略合 作伙伴，致⼒于推动能源变⾰，让⼈⼈可负担、可靠、可持续的能源惠及 全球，构建“可持续更美好”的未来。该客户深度聚焦能源开发、能源装 备、能源服务、能源应⽤四⼤领域，以强⼤科研创新和最佳业务实践，将 可再⽣能源的效率提升⾄新⾼度。
                          </p>
                        </v-col>
                        <v-col cols="12" md="6" class="page-content">
                          <v-img src="/static/equipment-lease/s5.png" />
                        </v-col>
                        <v-col cols="12" md="6" class="page-content">
                          <v-img src="/static/equipment-lease/s6.png" />
                        </v-col>
                      </v-row>
                      <v-row class="mt-4 mb-4 grey-block">
                        <v-col cols="12" class="page-h4">
                          <span>项目背景</span>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            为满足客户不断发展的业务需求，提高办公效率，客户计划进行办公电脑及工作站的租赁， 以确保员工拥有高性能的工作设备，支持各类办公应用和项目需求。
                          </p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="page-h4 mt-4 mb-4">
                          <span>客户痛点</span>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            1、 提升⼯作效率<br />
                            2、 降低采购成本<br />
                            3、 技术⽀持<br />
                            4、 设备管理
                          </p>
                        </v-col>
                      </v-row>
                      <v-row class="mt-4 mb-4 grey-block">
                        <v-col cols="12" class="page-h4">
                          <span>MDaaS 解决方案</span>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          1、 以租代购
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            为客户提供多品牌、⾼性能的全新电脑和⼯作站；并提供预安装部署和专业的 设备使⽤培训服务。
                          </p>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          2、 降低成本
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            帮助客户减少⼀次性采购成本，提供灵活便捷的租赁和服务⽅案，为客户降低 IT 设备维护和更新的负担。
                          </p>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          3、 技术⽀持
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            为客户提供硬件和软件维护，以及⽇常设备巡检保障设备的稳定性，并提供 备机服务降低设备故障对业务的影响，确保⼯作正常进⾏。
                          </p>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          4、 设备管理
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            为客户提供设备检测回收服务，并提供专业数据擦除服务，确保数据安全， 利⽤资产管理平台简化设备管理流程，提⾼⼯作效率。
                          </p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--知名电梯企业-->
                <v-tab-item transition="fade-transition">
                  <v-card flat>
                    <v-card-text class="mt-4 mb-4">
                      <v-row>
                        <v-col cols="12" class="page-h4 mt-4 mb-4">
                          <span>客户介绍</span>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            某国际知名电梯企业作为电梯和自动扶梯制造、安装和服务的全球领导者，在全球范围内维护着超过220万个客户单位，是业内最大的服务组合。我们会在世界上最具标志性的建筑、住宅和商业建筑、交通枢纽以及人们出行的任何地方接触到他。
                          </p>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <v-img src="/static/equipment-lease/s7.png" />
                        </v-col>
                      </v-row>
                      <v-row class="mt-4 mb-4 grey-block">
                        <v-col cols="12" class="page-h4">
                          <span>项目背景</span>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            作为全球领先的某知名电梯制造商，目前公司有近万台Apple设备用于员工办公、电梯巡检， 故障派单等保障性核心业务；随着设备生命周期到来，原有近10000台设备陆续需进行设备 回收和更换，且公司考虑轻资产租赁方式进行供应商选择。
                          </p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="page-h4 mt-4 mb-4">
                          <span>客户痛点</span>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            1、 采购需求<br />
                            购置大量资产，并不符合该制造商的战略需求，需要替代性的才买方案以符合其全球管理的决策。<br />
                            2、 管理 & 控制需求<br />
                            Flexibility + Security + Reduced TCO<br />
                            3、 服务需求<br />
                            缺少发放、运维、维修、回收等服务支持。
                          </p>
                        </v-col>
                      </v-row>
                      <v-row class="mt-4 mb-4 grey-block">
                        <v-col cols="12" class="page-h4">
                          <span>MDaaS 解决方案</span>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          1、 设备租赁
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            采用经营性租赁、分期付款的方式，以实现企业轻资产化，帮助该制造商实现开源节流的目标，以符合其全球战略管理决策
                          </p>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          2、 零接触部署 & MDM 服务
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            作为 Apple 官方 ABM 服务商，提供 ABM 全自动零接触部署方案的落地与实施，并且作为 VMware 金牌服务商，提供针对 WSO 平台的服务及咨询支持
                          </p>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          3、 运维管理 & 回收服务
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            作为 Apple 授权企业经销商及服务商，提供 Apple 设备全生命周期的运维保障服务，确保该制造商使用的设备在日常工作中正常运行，并且提供分布全国的售后支持服务 针对已经超过生命周期的设备，提供就设备的回收检测及专业的数据擦除服务，确保企业数据安全。
                          </p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--大型综合企业集团-->
                <v-tab-item transition="fade-transition">
                  <v-card flat>
                    <v-card-text class="mt-4 mb-4">
                      <v-row>
                        <v-col cols="12" class="page-h4 mt-4 mb-4">
                          <span>客户介绍</span>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            该集团是⼀家以有⾊材料智造、教育事业、健康产业（⽣态农业、医疗、养 ⽼）等多产业布局的国际化⼤型综合企业集团，总部位于杭州市。 现有员⼯ 25000多名，产业布局12个国家和地区，营销⽹络辐射全球。连续四 年上榜世界500强，连续⼗九年上榜中国企业500强。旗下的股份公司在亚洲、 美洲、欧洲设有22个⽣产基地、是全球铜管棒加⼯⾏业的标杆和领袖级企业， 教育集团是中国⺠办基础教育的标杆。
                          </p>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <v-img src="/static/equipment-lease/s8.png" />
                        </v-col>
                      </v-row>
                      <v-row class="mt-4 mb-4 grey-block">
                        <v-col cols="12" class="page-h4">
                          <span>项目背景</span>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            为了应对外部环境的压⼒，同时也为了更好实现集团轻资产、数字化管 理的需求，需要对集团内员⼯⽇常办公 IT 设备服务的获得⽅式进⾏变 更，以更好赋能员⼯，让员⼯办公设备和服务实现灵活的按需配置、按 使⽤付费。
                          </p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="page-h4 mt-4 mb-4">
                          <span>客户痛点</span>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            1、 终端更新需求<br />
                            客户员⼯总数超2万⼈，新增⼈员的设备缺⼝约近5000台，且在⽤设备⼤ 多数已经到达或者接近5年的使⽤⽣命周期。<br />
                            2、 运维管理需求<br />
                            客户涉及⾏业复杂、员⼯数量众多、终端使⽤场景复杂，但是 IT 桌⾯运 维⼈员仅⼗多⼈，设备部署更新压⼒巨⼤，技术⽀持响应缓慢。<br />
                            3、 旧设备、闲置设备回收需求<br />
                            有⼤量到达⽣命周期末的设备需要完成回收、数据清除、汇总、报告等。
                          </p>
                        </v-col>
                      </v-row>
                      <v-row class="mt-4 mb-4 grey-block">
                        <v-col cols="12" class="page-h4">
                          <span>MDaaS 解决方案</span>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          1、 设备租赁
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            根据客户实际的需求定制组合租赁⽅案，提供包括36期随租随还、意外免赔付等服 务⽅案，极⼤减少了客户的⼀次性投⼊成本，优化了现⾦流。
                          </p>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          2、 项⽬管理
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            为客户提供专职的项⽬管理⼈员，协助⽀持客户进⾏项⽬设备的部署、数据擦除、 回收等⼯作，确保项⽬的顺利交付。
                          </p>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          3、 驻场⼯程师
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            客户提供驻场服务⼯程师，为其提供⾼效的 IT 运维服务，全⼒保障客户员⼯的⼯ 作效率，确保其将全部精⼒集中到业务发展中。
                          </p>
                        </v-col>
                        <v-col cols="12" class="page-content">
                          4、 设备发放
                        </v-col>
                        <v-col cols="12" class="page-content">
                          <p>
                            为客户提供上⻔集中发放服务，同时为员⼯提供数据迁移咨询及新设备使⽤培训， 减轻了客户 IT 及项⽬组的负担，同时提⾼了最终⽤户的满意度。
                          </p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <contact-dialog :showDialog="contactDialog" type="DEVICE_LEASING" @dialogCloseEvent="contactDialog = false"></contact-dialog>
  </v-container>
</template>

<style lang="scss" scoped>
body {
  font-family: PingFangSC-Regular, Microsoft Yahei;
}

.page-h1 {
  font-weight: 600;
  font-size: 56px;
  line-height: 62px;
}
.page-h2 {
  font-weight: 500;
  font-size: 48px;
}
.page-h3 {
  font-weight: 500;
  font-size: 36px;
  line-height: 31px;
}
.page-h4 {
  font-weight: 500;
  font-size: 26px;
}
.page-h5 {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
}
.page-h6 {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
}
.page-tab {
  font-weight: 500;
  font-size: 26px;
}

.page-subtitle {
  font-weight: 500;
  font-size: 22px;
}
.page-content {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
}
.page-m-content {
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
}

.page-ms-content {
  font-weight: 300;
  font-size: 12px;
  line-height: 30px;
}

.page-btn {
  font-weight: 400;
  font-size: 24px;
}

.page-list {
  li {
    list-style: none;
  }
}

.activity-title {
  margin-left: auto;
  margin-right: auto;
}
.large-btn {
  width: 300px;
  font-size: 24px;
}
.tab-title {
  font-weight: 500;
  font-size: 24px;
  padding: 10px 40px;
  border-bottom: 1px solid #d9d9d9;
}

.tab-mobile-title {
  padding: 10px 10px;
  border-bottom: 1px solid #d9d9d9;
}

.grey-block {
  background-color: #f5f5f7;
  color: #0573d9;
  border-radius: 24px;
  padding: 30px 40px;
}

.equip-image {
  display: block;
  height: 88px;
  object-fit: contain;
  margin: 0 auto 32px;
}
.lease-image {
  height: 65px;
  object-fit: contain;
}

.vertical-center {
  height: 100%;
  margin: auto 0;
}

@media screen and (max-width:600px){
  .equip-image {
    height: 60px;
  }

  .lease-image {
    height: 40px;
  }

  .text-pretty {
    word-break: keep-all;
  }
}
</style>

<script>
export default {
  data() {
    return {
      contactDialog: false,
    }
  }
}
</script>
