var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-img', {
    staticClass: "white--text align-end",
    attrs: {
      "src": "/static/after-sales/after_sale_banner.png"
    }
  })], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "mt-16 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-center text-h4 text-md-h3 font-weight-bold mt-4"
  }, [_vm._v("售后和管理")])]), _c('v-col', {
    staticClass: "mb-16",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-center text-md-h5 my-4 grey--text text-pretty"
  }, [_vm._v(" 倍升互联同样提供专业便捷的售后服务及管理，"), _c('br'), _vm._v(" 实时线上线下的专业级软硬件支持，为企业省去后顾之忧。 ")])]), _c('v-col', {
    staticClass: "text-center mb-12",
    attrs: {
      "cols": "12"
    }
  }, [!_vm.isAuthenticated ? _c('v-menu', {
    attrs: {
      "transition": "scroll-y-transition",
      "min-width": "100",
      "nudge-width": 100,
      "top": "",
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "rounded-xl large-btn",
          attrs: {
            "x-large": "",
            "depressed": "",
            "color": "primary"
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" 马上申报服务 ")])];
      }
    }], null, false, 4074791369)
  }, [_c('v-card', [_c('v-card-text', [_vm._v(" 请登录您的专属账户 ")]), _c('v-card-actions', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    attrs: {
      "to": {
        path: '/account'
      },
      "depressed": "",
      "plat": ""
    }
  }, [_vm._v("注册")]), _c('v-btn', {
    attrs: {
      "to": {
        path: '/login'
      },
      "depressed": "",
      "plat": ""
    }
  }, [_vm._v("登录")])], 1)], 1)], 1) : _vm._e(), _vm.isAuthenticated ? _c('v-btn', {
    directives: [{
      name: "log",
      rawName: "v-log",
      value: 'AFTER_SALES_WITH_SERVICE',
      expression: "'AFTER_SALES_WITH_SERVICE'"
    }],
    staticClass: "rounded-xl large-btn",
    attrs: {
      "x-large": "",
      "depressed": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.showServiceDialog = true;
      }
    }
  }, [_vm._v(" 马上申报服务 ")]) : _vm._e(), _c('contact-service', {
    attrs: {
      "showDialog": _vm.showServiceDialog
    },
    on: {
      "dialogCloseEvent": function dialogCloseEvent($event) {
        _vm.showServiceDialog = false;
      }
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('v-tabs', {
    attrs: {
      "centered": "",
      "plain": "",
      "transition": "fade-transition",
      "prev-icon": "chevron_left",
      "next-icon": "chevron_right",
      "show-arrows": ""
    }
  }, [_c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']
  }, [_vm._v(" 运营维护 ")]), _c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']
  }, [_vm._v(" 设备部署 ")]), _c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']
  }, [_vm._v(" 检测维修 ")]), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "mt-16 mb-4 text-h3"
  }, [_c('span', {
    staticClass: "activity-title text-h4 text-md-h3 font-weight-bold"
  }, [_vm._v("运营维护")])]), _c('v-card-subtitle', {
    staticClass: "text-center page-subtitle"
  }, [_c('span', [_vm._v("倍升互联提供的服务选择")])]), _c('v-card-text', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "page-h4 mt-4 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("移动设备全生命周期服务")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 即从新设备采购到搭建移动化办公环境，再到设备使用期间的运维管理，直至退出使用，设备整个生命期间的一站式服务。"), _c('br'), _vm._v(" 采用现代化方法来管理设备生命周期，企业不仅可以放心地让员工进行更新, 还能增强数据安全性、提高生产力。最大限度地延长设备正常运行时间并提升员工满意度。 ")])])], 1), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card', {
    staticClass: "d-flex justify-space-between align-center operImgList",
    attrs: {
      "flat": ""
    }
  }, [_c('img', {
    staticClass: "operImg",
    attrs: {
      "src": "/static/after-sales/s1.png"
    }
  }), _c('img', {
    staticClass: "operImg",
    attrs: {
      "src": "/static/after-sales/s2.png"
    }
  }), _c('img', {
    staticClass: "operImg",
    attrs: {
      "src": "/static/after-sales/s3.png"
    }
  }), _c('img', {
    staticClass: "operImg",
    attrs: {
      "src": "/static/after-sales/s4.png"
    }
  }), _c('img', {
    staticClass: "operImg",
    attrs: {
      "src": "/static/after-sales/s5.png"
    }
  })]), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-stepper', {
    attrs: {
      "flat": "",
      "vertical": true
    }
  }, [_c('v-stepper-header', {
    staticClass: "elevation-0"
  }, [_c('v-stepper-step', {
    staticClass: "text-center",
    attrs: {
      "step": "",
      "color": "#b2b2b2"
    }
  }), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "step": "",
      "color": "#b2b2b2"
    }
  }), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "step": "",
      "color": "#b2b2b2"
    }
  }), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "step": "",
      "color": "#b2b2b2"
    }
  }), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "step": "",
      "color": "#b2b2b2"
    }
  })], 1)], 1)], 1), _c('v-card', {
    staticClass: "d-flex justify-space-between",
    attrs: {
      "flat": ""
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('span', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']
  }, [_vm._v("设备采购")]), _c('p', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-ms-content' : 'page-content']
  }, [_vm._v(" 成熟度评估"), _c('br'), _vm._v(" 方案设计"), _c('br'), _vm._v(" 硬件采购"), _c('br'), _vm._v(" 设备租赁 ")])]), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('span', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']
  }, [_vm._v("激活/部署")]), _c('p', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-ms-content' : 'page-content']
  }, [_vm._v(" APP 安装"), _c('br'), _vm._v(" 资产管理"), _c('br'), _vm._v(" 系统设置"), _c('br'), _vm._v(" EMM 注册 ")])]), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('span', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']
  }, [_vm._v("发放/培训")]), _c('p', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-ms-content' : 'page-content']
  }, [_vm._v(" 全国范围"), _c('br'), _vm._v(" 集中发放"), _c('br'), _vm._v(" 上门发放"), _c('br'), _vm._v(" 用户指导 ")])]), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('span', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']
  }, [_vm._v("运维/管理")]), _c('p', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-ms-content' : 'page-content']
  }, [_vm._v(" 热线支持"), _c('br'), _vm._v(" 诊断排障"), _c('br'), _vm._v(" 备机服务"), _c('br'), _vm._v(" ESC/ASP ")])]), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('span', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']
  }, [_vm._v("设备回收")]), _c('p', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-ms-content' : 'page-content']
  }, [_vm._v(" 数据擦除"), _c('br'), _vm._v(" 残值回收"), _c('br'), _vm._v(" 设备销毁"), _c('br'), _vm._v(" 设备捐赠 ")])])], 1)], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "mt-16 mb-4 text-h3"
  }, [_c('span', {
    staticClass: "activity-title text-h4 text-md-h3 font-weight-bold"
  }, [_vm._v("设备部署")])]), _c('v-card-text', {
    staticClass: "page-content"
  }, [_c('p', [_vm._v(" 我们一直在帮助成长型企业有效地管理他们的设备。从将新的设备资产与现有 IT 基础设施集成，到确保所有设备都得到妥善管理和安全。我们帮助您确定并执行最佳的部署工作流程，以满足您的业务需求。"), _c('br'), _c('br'), _vm._v(" 您无需在设备上手动操作，即享受高级别的控制权限和安全级别，并确定所有用户在拿到设备时，开箱即用！在部署过程的每一步我们都将为您提供支持。 ")])]), _c('v-card-text', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "page-h5 text-center",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('span', [_c('v-img', {
    staticClass: "image-deploy",
    staticStyle: {
      "margin": "auto"
    },
    attrs: {
      "src": "/static/after-sales/d1.png"
    }
  })], 1), _c('span', {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_vm._v("零接触部署")])]), _c('v-col', {
    staticClass: "page-h5 text-center",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-img', {
    staticClass: "image-deploy",
    staticStyle: {
      "margin": "auto"
    },
    attrs: {
      "src": "/static/after-sales/d2.png"
    }
  }), _c('span', {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_vm._v("自动化部署")])], 1), _c('v-col', {
    staticClass: "page-h5 text-center",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-img', {
    staticClass: "image-deploy",
    staticStyle: {
      "margin": "auto"
    },
    attrs: {
      "src": "/static/after-sales/d3.png"
    }
  }), _c('span', {
    staticStyle: {
      "line-height": "40px"
    }
  }, [_vm._v("自动化部署")])], 1)], 1), _c('v-row', {
    staticClass: "mt-16"
  }, [_c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("初始化部署服务")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 设备初始化、设备监管、Apple ID 申请、MDM 账号激活、批量注册、自动化部署、软件预装、系统设置、资产标签、系统升级。 ")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("集中发放")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 专业工程师到客户现场，组织会议集中发放，并提供简单培训及技术支持完成发放。 ")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("市区内上门发放")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 为客户安排工程师上门提供设备发放，并提供技术方面的解答。 ")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("用户自提")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 用户可选择前往倍升互联服务网点领取已部署的设备。 ")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("产品培训")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 为客户提供办公软件培训及技术支持。 ")])])], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "mt-16 mb-4 text-h3"
  }, [_c('span', {
    staticClass: "activity-title text-h4 text-md-h3 font-weight-bold"
  }, [_vm._v("检测维修")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    staticClass: "white--text",
    attrs: {
      "src": "/static/after-sales/in_banner.png"
    }
  })], 1)], 1), _c('v-card-text', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 我们是 Apple 授权服务商，拥有经验丰富的专业检测维修团队，随时随地可以帮您解决产品检测和维修上的任何问题。无论是电池、屏幕还是内部工作问题，还是进行升级、数据传输、数据恢复甚至健康检查，只要您需要帮助，我们随时为您提供服务。 ")])]), _c('v-col', {
    staticClass: "page-content text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 标准硬件维修 / 企业定制服务 / 增值服务方案 ")])]), _c('v-col', {
    staticClass: "page-content text-center",
    attrs: {
      "cols": "3"
    }
  }, [_c('img', {
    staticClass: "image-main",
    attrs: {
      "src": "/static/after-sales/i1.png"
    }
  }), _c('p', [_vm._v(" Apple认证工程师 ")])]), _c('v-col', {
    staticClass: "page-content text-center",
    attrs: {
      "cols": "3"
    }
  }, [_c('img', {
    staticClass: "image-main",
    attrs: {
      "src": "/static/after-sales/i2.png"
    }
  }), _c('p', [_vm._v(" 专业工具 ")])]), _c('v-col', {
    staticClass: "page-content text-center",
    attrs: {
      "cols": "3"
    }
  }, [_c('img', {
    staticClass: "image-main",
    attrs: {
      "src": "/static/after-sales/i3.png"
    }
  }), _c('p', [_vm._v(" 官方系统 ")])]), _c('v-col', {
    staticClass: "page-content text-center",
    attrs: {
      "cols": "3"
    }
  }, [_c('img', {
    staticClass: "image-main",
    attrs: {
      "src": "/static/after-sales/i4.png"
    }
  }), _c('p', [_vm._v(" 标准维修流程 ")])]), _c('v-col', {
    staticClass: "page-content text-center",
    attrs: {
      "cols": "4"
    }
  }, [_c('img', {
    staticClass: "image-main",
    attrs: {
      "src": "/static/after-sales/i5.png"
    }
  }), _c('p', [_vm._v(" 安全与隐私 ")])]), _c('v-col', {
    staticClass: "page-content text-center",
    attrs: {
      "cols": "4"
    }
  }, [_c('img', {
    staticClass: "image-main",
    attrs: {
      "src": "/static/after-sales/i6.png"
    }
  }), _c('p', [_vm._v(" 服务与质量管控 ")])]), _c('v-col', {
    staticClass: "page-content text-center",
    attrs: {
      "cols": "4"
    }
  }, [_c('img', {
    staticClass: "image-main",
    attrs: {
      "src": "/static/after-sales/i7.png"
    }
  }), _c('p', [_vm._v(" 官方统一价格 ")])])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }