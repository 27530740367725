var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-col', {
    staticClass: "mt-16 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-center text-h4 text-md-h3 font-weight-bold mt-4"
  }, [_vm._v(" 订单 ")])]), _c('v-col', {
    staticClass: "mb-12",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', {
    attrs: {
      "centered": "",
      "plain": "",
      "transition": "fade-transition",
      "prev-icon": "chevron_left",
      "next-icon": "chevron_right",
      "show-arrows": ""
    },
    on: {
      "change": _vm.handleTabChange
    },
    model: {
      value: _vm.tabType,
      callback: function callback($$v) {
        _vm.tabType = $$v;
      },
      expression: "tabType"
    }
  }, [_c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title'],
    attrs: {
      "href": "#intention"
    }
  }, [_vm._v(" 意向单 ")]), _c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title'],
    attrs: {
      "href": "#purchase_order"
    }
  }, [_vm._v(" 采购订单 ")]), _c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title'],
    attrs: {
      "href": "#service_order"
    }
  }, [_vm._v(" 服务工单 ")]), _c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title'],
    attrs: {
      "href": "#repair_order"
    }
  }, [_vm._v(" 维修工单 ")]), _c('v-tab-item', {
    attrs: {
      "value": "intention",
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "mt-12 mb-4"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-data-table', {
    staticClass: "orderTable",
    attrs: {
      "headers": _vm.intentionHeaders,
      "items": _vm.intentionList,
      "items-per-page": 20,
      "hide-default-footer": "",
      "show-expand": "",
      "single-expand": _vm.singleExpand,
      "expanded": _vm.expanded,
      "no-data-text": "暂无数据"
    },
    on: {
      "update:expanded": function updateExpanded($event) {
        _vm.expanded = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.status",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.status == 1 ? _c('span', {
          staticStyle: {
            "color": "green"
          }
        }, [_vm._v("处理中")]) : _vm._e(), item.status == 4 ? _c('span', {
          staticStyle: {
            "color": "green"
          }
        }, [_vm._v("已完成")]) : _vm._e()];
      }
    }, {
      key: "expanded-item",
      fn: function fn(_ref2) {
        var headers = _ref2.headers,
          item = _ref2.item;
        return [_c('td', {
          attrs: {
            "colspan": headers.length
          }
        }, [_c('v-data-table', {
          staticClass: "orderTable my-4",
          attrs: {
            "headers": _vm.intentionSubHeaders,
            "items": item.subOrderList,
            "hide-default-footer": "",
            "no-data-text": "暂无数据"
          },
          scopedSlots: _vm._u([{
            key: "item.productName",
            fn: function fn(_ref3) {
              var item = _ref3.item;
              return [_c('v-row', {
                staticClass: "intentionProduct",
                attrs: {
                  "no-gutters": ""
                }
              }, [_c('v-col', {
                staticClass: "image",
                attrs: {
                  "cols": "12",
                  "md": "2"
                }
              }, [_c('img', {
                attrs: {
                  "width": "100%",
                  "src": item.picUrl.split(',')[0]
                }
              })]), _c('v-col', {
                attrs: {
                  "cols": "12",
                  "md": "10"
                }
              }, [_c('v-row', {
                attrs: {
                  "no-gutters": ""
                }
              }, [_c('v-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('span', {
                staticClass: "title"
              }, [_vm._v(_vm._s(item.productName))])]), _c('v-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('p', [_vm._v(_vm._s(item.skuName))])])], 1)], 1)], 1)];
            }
          }, {
            key: "item.purchaseType",
            fn: function fn(_ref4) {
              var item = _ref4.item;
              return [item.purchaseType === 1 ? _c('span', [_vm._v("采购")]) : _vm._e(), item.purchaseType !== 1 ? _c('span', [_vm._v("租赁（" + _vm._s(item === null || item === void 0 ? void 0 : item.leasePeriod) + "月/" + _vm._s(item === null || item === void 0 ? void 0 : item.leasePayMethod) + "）")]) : _vm._e()];
            }
          }, {
            key: "item.salesPrice",
            fn: function fn(_ref5) {
              var item = _ref5.item;
              return [item.purchaseType === 1 ? _c('span', [_vm._v(_vm._s(item.salesPrice))]) : _vm._e(), item.purchaseType !== 1 ? _c('span', [_vm._v(_vm._s(item.leasePrice) + " 月")]) : _vm._e()];
            }
          }], null, true)
        })], 1)];
      }
    }])
  })], 1), _c('v-col', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-pagination', {
    staticClass: "orderPage",
    attrs: {
      "length": _vm.intentionPage.pageCount,
      "total-visible": "7"
    },
    on: {
      "input": _vm.handleIntentionPage
    },
    model: {
      value: _vm.intentionPage.pageNo,
      callback: function callback($$v) {
        _vm.$set(_vm.intentionPage, "pageNo", $$v);
      },
      expression: "intentionPage.pageNo"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "value": "purchase_order",
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "mt-12 mb-4"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-data-table', {
    staticClass: "orderTable",
    attrs: {
      "headers": _vm.orderHeaders,
      "items": _vm.orderList,
      "items-per-page": _vm.orderPage.pageSize,
      "hide-default-footer": "",
      "no-data-text": "暂无数据"
    },
    scopedSlots: _vm._u([{
      key: "item.status",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [item.status == 0 ? _c('span', {
          staticStyle: {
            "color": "#000"
          }
        }, [_vm._v("待执⾏")]) : _vm._e(), item.status == 1 ? _c('span', {
          staticStyle: {
            "color": "#1890FF"
          }
        }, [_vm._v("处理中")]) : _vm._e(), item.status == 2 ? _c('span', {
          staticStyle: {
            "color": "#ccc"
          }
        }, [_vm._v("已取消")]) : _vm._e(), item.status == 3 ? _c('span', {
          staticStyle: {
            "color": "#f50"
          }
        }, [_vm._v("已拒绝")]) : _vm._e(), item.status == 4 ? _c('span', {
          staticStyle: {
            "color": "#87d068"
          }
        }, [_vm._v("已完成")]) : _vm._e()];
      }
    }, {
      key: "item.operate",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.handleOrderDetail(item.id);
            }
          }
        }, [_vm._v("详情")])];
      }
    }])
  })], 1), _c('v-col', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-pagination', {
    staticClass: "orderPage",
    attrs: {
      "length": _vm.orderPage.pageCount,
      "total-visible": "7"
    },
    on: {
      "input": _vm.handleOrderPage
    },
    model: {
      value: _vm.orderPage.pageNo,
      callback: function callback($$v) {
        _vm.$set(_vm.orderPage, "pageNo", $$v);
      },
      expression: "orderPage.pageNo"
    }
  })], 1)], 1), _c('order-order-detail', {
    attrs: {
      "showDialog": _vm.showOrderDialog,
      "orderId": _vm.currentOrderId
    },
    on: {
      "dialogCloseEvent": function dialogCloseEvent($event) {
        _vm.showOrderDialog = false;
      }
    }
  })], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "value": "service_order",
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "mt-12 mb-4"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-data-table', {
    staticClass: "orderTable",
    attrs: {
      "headers": _vm.serviceHeaders,
      "items": _vm.serviceList,
      "items-per-page": 20,
      "hide-default-footer": "",
      "no-data-text": "暂无数据"
    },
    scopedSlots: _vm._u([{
      key: "item.status",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [item.status == 0 ? _c('span', {
          staticStyle: {
            "color": "#000"
          }
        }, [_vm._v("待受理")]) : _vm._e(), item.status == 1 ? _c('span', {
          staticStyle: {
            "color": "#1890FF"
          }
        }, [_vm._v("处理中")]) : _vm._e(), item.status == 2 ? _c('span', {
          staticStyle: {
            "color": "#ccc"
          }
        }, [_vm._v("已取消")]) : _vm._e(), item.status == 3 ? _c('span', {
          staticStyle: {
            "color": "#f50"
          }
        }, [_vm._v("已拒绝")]) : _vm._e(), item.status == 4 ? _c('span', {
          staticStyle: {
            "color": "#87d068"
          }
        }, [_vm._v("已完成")]) : _vm._e()];
      }
    }, {
      key: "item.operate",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.handleServiceDetail(item.caseNumber);
            }
          }
        }, [_vm._v("详情")])];
      }
    }])
  })], 1), _c('v-col', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-pagination', {
    staticClass: "orderPage",
    attrs: {
      "length": _vm.servicePage.pageCount,
      "total-visible": "7"
    },
    on: {
      "input": _vm.handleServicePage
    },
    model: {
      value: _vm.servicePage.pageNo,
      callback: function callback($$v) {
        _vm.$set(_vm.servicePage, "pageNo", $$v);
      },
      expression: "servicePage.pageNo"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "value": "repair_order",
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "mt-12 mb-4"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-data-table', {
    staticClass: "orderTable",
    attrs: {
      "headers": _vm.repairHeaders,
      "items": _vm.repairList,
      "items-per-page": 20,
      "hide-default-footer": "",
      "no-data-text": "暂无数据"
    },
    scopedSlots: _vm._u([{
      key: "item.status",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [item.status == 0 ? _c('span', {
          staticStyle: {
            "color": "#000"
          }
        }, [_vm._v("待受理")]) : _vm._e(), item.status == 1 ? _c('span', {
          staticStyle: {
            "color": "#1890FF"
          }
        }, [_vm._v("处理中")]) : _vm._e(), item.status == 2 ? _c('span', {
          staticStyle: {
            "color": "#ccc"
          }
        }, [_vm._v("已取消")]) : _vm._e(), item.status == 3 ? _c('span', {
          staticStyle: {
            "color": "#f50"
          }
        }, [_vm._v("已拒绝")]) : _vm._e(), item.status == 4 ? _c('span', {
          staticStyle: {
            "color": "#87d068"
          }
        }, [_vm._v("已完成")]) : _vm._e()];
      }
    }, {
      key: "item.returnsChannel",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [item.returnsChannel == 'express' ? _c('span', [_vm._v("快递")]) : _c('span', [_vm._v("自取")])];
      }
    }, {
      key: "item.operate",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.handleRepairDetail(item.caseNumber);
            }
          }
        }, [_vm._v("详情")])];
      }
    }])
  })], 1), _c('v-col', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-pagination', {
    staticClass: "orderPage",
    attrs: {
      "length": _vm.repairPage.pageCount,
      "total-visible": "7"
    },
    on: {
      "input": _vm.handleRepairPage
    },
    model: {
      value: _vm.repairPage.pageNo,
      callback: function callback($$v) {
        _vm.$set(_vm.repairPage, "pageNo", $$v);
      },
      expression: "repairPage.pageNo"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }