<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="mt-16 mb-4">
        <p class="text-center text-h4 text-md-h3 font-weight-bold mt-4">
          {{ userForm.username }} @ {{ enterprise.enterpriseFullName }}
        </p>
      </v-col>
      <v-col cols="12" class="mb-12">
        <v-tabs 
          centered 
          plain 
          transition="fade-transition" 
          prev-icon="chevron_left"
          next-icon="chevron_right"
          show-arrows
          v-model="tabType"
        >
          <v-tab href="#basic" :class="[$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']">
            基础信息
          </v-tab>
          <v-tab href="#security" :class="[$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']">
            安全设置
          </v-tab>
          <v-tab href="#enterprise" :class="[$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']">
            企业信息
          </v-tab>

          <!--基础信息-->
          <v-tab-item value="basic" transition="fade-transition">
            <v-card flat>
              <v-card-text class="mt-12 mb-4">
                <v-form v-model="validForm" @submit.prevent="submit">
                  <v-row>
                    <v-col cols="12" md="3" :class="['my-0','pb-0',$vuetify.breakpoint.mobile ? '' : 'text-right']">
                      <label><span style="color: red;">*</span>账号</label>
                    </v-col>
                    <v-col cols="12" md="7" class="my-0 py-0">
                      <input type="hidden" name="id" :value="userForm.id" />
                      <v-text-field
                        name="realname"
                        v-model="userForm.realname"
                        :rules="[rules.required]"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" :class="['my-0','pb-0',$vuetify.breakpoint.mobile ? '' : 'text-right']">
                      <label><span style="color: red;">*</span>姓氏</label>
                    </v-col>
                    <v-col cols="12" md="3" class="my-0 py-0">
                      <v-text-field
                        name="surname"
                        v-model="userForm.surname"
                        :rules="[rules.required]"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="1" :class="['my-0','pb-0',$vuetify.breakpoint.mobile ? '' : 'text-right']">
                      <label><span style="color: red;">*</span>名字</label>
                    </v-col>
                    <v-col cols="12" md="3" class="my-0 py-0">
                      <v-text-field
                        name="name"
                        v-model="userForm.name"
                        :rules="[rules.required]"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" :class="['my-0','pb-0',$vuetify.breakpoint.mobile ? '' : 'text-right']">
                      <label><span style="color: red;">*</span>手机</label>
                    </v-col>
                    <v-col cols="12" md="7" class="my-0 py-0">
                      <v-text-field
                        name="phone"
                        v-model="userForm.phone"
                        :rules="[rules.required]"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" :class="['my-0','pb-0',$vuetify.breakpoint.mobile ? '' : 'text-right']">
                      <label><span style="color: red;">*</span>邮箱</label>
                    </v-col>
                    <v-col cols="12" md="7" class="my-0 py-0">
                      <v-text-field
                        name="email"
                        v-model="userForm.email"
                        :rules="[rules.required]"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" :class="['my-0','pb-0',$vuetify.breakpoint.mobile ? '' : 'text-right']">
                      <label>部门</label>
                    </v-col>
                    <v-col cols="12" md="7" class="my-0 py-0">
                      <v-text-field
                        name="espEnterpriseDeptName"
                        v-model="userForm.espEnterpriseDeptName"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" :class="['my-0','pb-0',$vuetify.breakpoint.mobile ? '' : 'text-right']">
                      <label>职位</label>
                    </v-col>
                    <v-col cols="12" md="7" class="my-0 py-0">
                      <v-text-field
                        name="espEnterpriseJobTitle"
                        v-model="userForm.espEnterpriseJobTitle"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" offset-md="3" md="7" class="mt-0 pt-0">
                      <v-btn color="primary" @click="handleBasicSave">提交</v-btn>
                      <v-btn @click="loadUserInfo" class="ml-4">重置</v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!--安全设置-->
          <v-tab-item value="security" transition="fade-transition">
            <v-card flat>
              <v-card-text class="mt-12 mb-8">
                <v-row>
                  <v-col cols="12" md="1" offset-md="1">
                    <label class="font-weight-bold">登录密码</label>
                  </v-col>
                  <v-col cols="12" md="8">
                    <p >已设置。密码至少6位字符，支持数字、字母和除空格外的特殊字符，且必须同时包含数字和大小写字母。</p>
                  </v-col>
                  <v-col cols="12" md="1">
                    <a @click="passwordDialog = true">修改</a>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-dialog 
              v-model="passwordDialog" 
              :width="$vuetify.breakpoint.mobile ? '' : '600'" 
              :fullscreen="$vuetify.breakpoint.mobile"
              @click:outside="handleClose"
            >
              <v-card flat>
                <v-card-title class="mb-4">修改密码</v-card-title>
                <v-card-text>
                  <v-form v-model="validPassword" @submit.prevent="submit">
                    <v-row>
                      <v-col cols="12" md="3" :class="['my-0','pb-0',$vuetify.breakpoint.mobile ? '' : 'text-right']">
                        <label><span style="color: red;">*</span>旧密码</label>
                      </v-col>
                      <v-col cols="12" md="9" class="my-0 py-0">
                        <v-text-field
                          name="oldPassword"
                          v-model="passwordForm.oldPassword"
                          :rules="[rules.required, rules.sixLength, rules.password]"
                          type="password"
                          label="旧密码*"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" :class="['my-0','pb-0',$vuetify.breakpoint.mobile ? '' : 'text-right']">
                        <label><span style="color: red;">*</span>新密码</label>
                      </v-col>
                      <v-col cols="12" md="9" class="my-0 py-0">
                        <v-text-field
                          name="newPassword"
                          v-model="passwordForm.newPassword"
                          :rules="[rules.required, rules.sixLength, rules.password]"
                          type="password"
                          label="新密码*"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" :class="['my-0','pb-0',$vuetify.breakpoint.mobile ? '' : 'text-right']">
                        <label><span style="color: red;">*</span>确认新密码</label>
                      </v-col>
                      <v-col cols="12" md="9" class="my-0 py-0">
                        <v-text-field
                          name="confirmPassword"
                          v-model="passwordForm.confirmPassword"
                          :rules="[rules.required, rules.sixLength, rules.password]"
                          type="password"
                          label="确认新密码*"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" offset-md="3" md="9" class="mt-0 pt-0">
                        <v-btn color="primary" @click="handleChangePassword">确定</v-btn>
                        <v-btn @click="passwordDialog = false" class="ml-4">取消</v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-tab-item>

          <!--企业信息-->
          <v-tab-item value="enterprise" transition="fade-transition">
            <v-card outlined tile class="mt-12 mb-8 epCard">
              <v-card-text class="epText">
                <v-row>
                  <v-col cols="12" md="4">
                    <v-row>
                      <v-col cols="5" class="my-0 pb-0 epTitle">
                        企业名称
                      </v-col>
                      <v-col cols="7" class="epContent">
                        {{ enterprise.enterpriseFullName }}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-row>
                      <v-col cols="5" class="my-0 pb-0 epTitle">
                        企业简称
                      </v-col>
                      <v-col cols="7" class="epContent">
                        {{ enterprise.enterpriseShortName }}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-row>
                      <v-col cols="5" class="my-0 pb-0 epTitle">
                        组织机构代码
                      </v-col>
                      <v-col cols="7" class="epContent">
                        {{ enterprise.licenseKey }}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-row>
                      <v-col cols="5" class="my-0 pb-0 epTitle">
                        企业联系人
                      </v-col>
                      <v-col cols="7" class="epContent">
                        {{ enterprise.contactName }}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-row>
                      <v-col cols="5" class="my-0 pb-0 epTitle">
                        联系方式
                      </v-col>
                      <v-col cols="7" class="epContent">
                        {{ enterprise.contactPhone }}
                      </v-col>
                    </v-row>
                  </v-col>  
                  <v-col cols="12" md="4">
                    <v-row>
                      <v-col cols="5" class="my-0 pb-0 epTitle">
                        绑定时间
                      </v-col>
                      <v-col cols="7" class="epContent">
                        {{ enterprise.bindTime }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
.page-h1 {
  font-weight: 600;
  font-size: 56px;
  line-height: 62px;
}
.page-h2 {
  font-weight: 500;
  font-size: 48px;
}
.page-h3 {
  font-weight: 500;
  font-size: 36px;
  line-height: 31px;
}
.page-h4 {
  font-weight: 500;
  font-size: 26px;
}
.page-h5 {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
}
.page-tab {
  font-weight: 500;
  font-size: 26px;
}

.page-subtitle {
  font-weight: 500;
  font-size: 22px;
}
.page-content {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
}

.page-btn {
  font-weight: 400;
  font-size: 24px;
}

.page-list {
  li {
    list-style: none;
  }
}

.activity-title {
  margin-left: auto;
  margin-right: auto;
}
.large-btn {
  width: 300px;
  font-size: 24px;
}
.tab-title {
  font-weight: 500;
  font-size: 24px;
  padding: 10px 40px;
  border-bottom: 1px solid #d9d9d9;
}

.tab-mobile-title {
  padding: 10px 10px;
  border-bottom: 1px solid #d9d9d9;
}
.grey-block {
  background-color: #f5f5f7;
  color: #0573d9;
  border-radius: 24px;
  padding: 30px 40px;
}

.mdm-image {
  height: 60px;
  width: 60px;
  object-fit: contain;
}
.jamf-image {
  height: 59px;
  margin-bottom: 20px;
}

.vm-image {
  width: 124px;
}

.filePanel {
  width: 100%;
  border-color: rgba(0, 0, 0, .42);
  border-style: solid;
  border-width: thin;
  border-radius: 4px;
  padding-bottom: 10px;

  label {
    color: rgba(0, 0, 0, 0.6);
    padding-left: 10px;
    padding-top: 4px;
  }

  button {
    margin-right: 10px;
  }
}

.epCard {
  //border-radius: 4px !important;
  .epText {
    padding: 12px;
    .epTitle {
      height: 60px;
      padding-top: 18px;
      border: thin solid rgba(0, 0, 0, 0.12);
      background-color: rgba(0, 0, 0, 0.12);
    }
    .epContent {
      height: 60px;
      padding-top: 18px;
      border: thin solid rgba(0, 0, 0, 0.12);
      white-space:nowrap;
    }
  }
}

@media screen and (max-width:600px){
  .mdm-image {
    height: 30px;
    width: 30px;
  }
  .jamf-image {
    height: 40px;
  }

  .vm-image {
    width: 50px;
  }
}
</style>

<script>
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_USER_INFO,
  EDIT_USER,
  CHANGE_PASSWORD
} from "@/store/actions.type";
import store from "@/store";

export default {
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
        sixLength: value => {
          if (value) {
            return value.length > 5 || '密码长度至少为6位';
          } else {
            return true;
          }
        },
        password: value => {
          if (value) {
            const pattern = /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9]{6,20}/
            return pattern.test(value) || '密码必须同时包含数字和大小写字母';
          } else {
            return true;
          }
        },
      },
      validForm: true,
      userForm: {
        id: "111",
        realname: "test",
        surname: "test",
        name: "test",
        phone: "13000000001",
        email: "test@test.com",
        username: "test@test.com",
        deptName: "",
        jobTitle: "",
      },
      enterprise: {
        enterpriseFullName: "测试（北京）有线公司",
        enterpriseShortName: "测试（北京）有线公司",
        licenseKey: "",
        contactName: "测试",
        contactPhone: "13000000001",
        bindTime: "2024-04-03 15:28:49",
      },
      passwordDialog: false,
      passwordForm: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      validPassword: true,
      tabType: 'basic',
    }
  },
  methods: {
    loadUserInfo() {
      store.dispatch(FETCH_USER_INFO)
        .then((data) => {
          if (data.data.code !== 200) {
            store.commit(SET_ERROR, {msg: data.data.message});
            return;
          }
          
          const user = data.data.result;
          this.userForm.id = user.id;
          this.userForm.realname = user.realname;
          this.userForm.surname = user.surname;
          this.userForm.name = user.name;
          this.userForm.phone = user.phone;
          this.userForm.email = user.email;
          this.userForm.username = user.username;
          this.userForm.deptName = user.espEnterpriseDeptName;
          this.userForm.jobTitle = user.espEnterpriseJobTitle;

          this.enterprise.enterpriseFullName = user.enterpriseFullName;
          this.enterprise.enterpriseShortName = user.enterpriseShortName;
          this.enterprise.licenseKey = user.licenseKey;
          this.enterprise.contactName = user.contactName;
          this.enterprise.contactPhone = user.contactPhone;
          this.enterprise.bindTime = user.bindTime;
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
    handleBasicSave() {
      if (!this.validForm) {
        return;
      }

      store.dispatch(EDIT_USER, this.userForm)
        .then((data) => {
          if (data.data.code !== 200) {
            store.commit(SET_ERROR, {msg: data.data.message});
            return;
          }
          this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    },
    handleChangePassword() {
      if (!this.validPassword) {
        return;
      }

      store.dispatch(CHANGE_PASSWORD, this.passwordForm)
        .then((data) => {
          if (data.data.code !== 200) {
            store.commit(SET_ERROR, {msg: data.data.message});
            return;
          }
          this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err.response.data.message});
        });
    }
  },
  created() {
    this.tabType = this.$route.query.type || this.tabType;
    this.loadUserInfo();
  }
}
</script>
