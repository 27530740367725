var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-img', {
    staticClass: "white--text align-end",
    attrs: {
      "src": "/static/success-cases/success-banner.png"
    }
  })], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "mt-16 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-center text-h4 text-md-h3 font-weight-bold mt-4"
  }, [_vm._v("成功案例")])]), _c('v-col', {
    staticClass: "mb-16",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-center text-md-h5 my-4 grey--text text-pretty"
  }, [_vm._v(" 协助众多企业完成了智能化数字化的转型及生产力工具的升级， 无论是快速扩张还是稳重求进，倍升互联都能应企业所需。 ")])]), _c('v-col', {
    staticClass: "text-center mb-12",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    directives: [{
      name: "log",
      rawName: "v-log",
      value: 'SUCCESS_CASES_WITH_CONTACT',
      expression: "'SUCCESS_CASES_WITH_CONTACT'"
    }],
    staticClass: "rounded-xl large-btn",
    attrs: {
      "x-large": "",
      "depressed": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.contactDialog = true;
      }
    }
  }, [_vm._v(" 与我们取得联系 ")])], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('v-tabs', {
    attrs: {
      "centered": "",
      "plain": "",
      "transition": "fade-transition",
      "prev-icon": "chevron_left",
      "next-icon": "chevron_right",
      "show-arrows": ""
    }
  }, [_c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']
  }, [_vm._v(" 零售 ")]), _c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']
  }, [_vm._v(" 保险 ")]), _c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']
  }, [_vm._v(" 制造 ")]), _c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']
  }, [_vm._v(" 金融 ")]), _c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']
  }, [_vm._v(" 航空 ")]), _c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']
  }, [_vm._v(" 医药 ")]), _c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']
  }, [_vm._v(" 互联网 ")]), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "mt-16 mb-4 text-h3"
  }, [_c('span', {
    staticClass: "activity-title text-h4 text-md-h3 font-weight-bold"
  }, [_vm._v("绫致时装集团")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2"
    }
  }, [_c('v-img', {
    staticClass: "white--text",
    attrs: {
      "src": "/static/success-cases/retaillogo.jpg"
    }
  })], 1)], 1), _c('v-card-text', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "page-h4 mt-4 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("项目背景")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 在 iPadOS 和众多自定 app的帮助下，能够轻松掌控这个庞大的体系，并实现了全渠道零售，整个系统都能共享数据，有助于店员为到店的顾客合理搭配风格，减少库存积压。 ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('vue-plyr', [_c('video', {
    attrs: {
      "controls": "",
      "playsinline": ""
    }
  }, [_c('source', {
    attrs: {
      "src": "https://doublerise-b2b.oss-cn-beijing.aliyuncs.com/prod/video/Apple%20%E4%B8%8E%E7%BB%AB%E8%87%B4%E6%97%B6%E8%A3%85%E9%9B%86%E5%9B%A2%20-%20%E5%80%9F%E5%8A%A9%20iOS%20%E4%BC%98%E5%8C%96%E9%9B%B6%E5%94%AE%E8%BF%90%E8%90%A5.mp4",
      "type": "video/mp4"
    }
  })])])], 1)], 1), _c('v-row', {
    staticClass: "mt-4 mb-4 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("客户痛点")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" - 销售方面需要及时为顾客提供周到的服务，并根据客户情况提供穿搭建议，提升客户的满意和购买数量；"), _c('br'), _vm._v(" - 门店管理，能够实时汇总销量数据，了解销售情况，并通过销售数据了解店员工作情况。"), _c('br'), _vm._v(" - 库存管理，能够实时的管理库存，提高货品流转，并能够在顾客购买后能够缩短中间流程，提升销售效率。 ")])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("解决方案")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("销售： iPad，造型大咖")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 当有顾客临门时，绫致门店的售货员需要立刻提供周到的服务。有了穿搭指南方面的自定 app，售货员能结合顶尖设计师的推荐，与顾客分享色彩搭配和相应的饰品。很多顾客享受这种专业的服务，往往愿意在店里多选购几件衣服。 ")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("管理： iPadOS，门店经理的得力助手")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 通过 iPad 和自定 app，门店经理能掌握大量有用的数据。他们可以查看上一年的销售情况和流行趋势，了解本周表现出色的售货员，以及当下畅销的服装款式。这些数据全都触手可及，使门店经理能在不断变化的零售环境中始终拥有先见之明。 ")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("库存：大小尺码各店库存，App 都清楚")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 保持低库存是零售业成功的重要因素。在绫致，如果顾客在线购买了一件衬衫，悬挂这件衬衫的零售店需要立刻打包并发货。该门店的系统会发出购买确认的推送通知，提醒店员找到这件衬衫，将它包好，等待快递公司取货。 ")])])], 1), _c('v-row', {
    staticClass: "mt-4 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("项目成果")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" - 7500 家位于中国的零售店"), _c('br'), _vm._v(" - 1.3 万部零售店使用的 iPad 设备"), _c('br'), _vm._v(" - 2.7 万小时节省的工时"), _c('br'), _vm._v(" - 售货员效率更高，能力更强。"), _c('br'), _vm._v(" - 提供专业的穿搭窍门和建议，提升顾客满意度。"), _c('br'), _vm._v(" - 盘点库存的耗时缩短，售货员效率提升 20% 之多。 ")])])], 1), _c('v-row', {
    staticClass: "mt-8 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("客户评价")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 使用 iPad，我们能为顾客快速提供穿搭方案，获得进一步销售的机会，这也为我们带来许多回头客。 ")])])], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "mt-16 mb-4 text-h3"
  }, [_c('span', {
    staticClass: "activity-title text-h4 text-md-h3 font-weight-bold"
  }, [_vm._v("友邦人寿保险有限公司")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2"
    }
  }, [_c('v-img', {
    staticClass: "white--text",
    attrs: {
      "src": "/static/success-cases/insurancelogo.jpg"
    }
  })], 1)], 1), _c('v-card-text', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "page-h4 mt-4 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("项目背景")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 采用 Apple 提供的技术专案，以超过 16 款自定 app 为营销人员和营销主管的日常工作提供全方位支持，从销售、服务、招聘、培训，到客户和营销人员的管理。 ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('vue-plyr', [_c('video', {
    attrs: {
      "controls": "",
      "playsinline": ""
    }
  }, [_c('source', {
    attrs: {
      "src": "https://doublerise-b2b.oss-cn-beijing.aliyuncs.com/prod/video/Apple%20%E4%B8%8E%E5%8F%8B%E9%82%A6%E4%BA%BA%E5%AF%BF%20-%20%E5%88%9B%E6%96%B0%E4%BF%9D%E9%99%A9%E4%B8%9A%E7%9A%84%E6%9C%AA%E6%9D%A5.mp4",
      "type": "video/mp4"
    }
  })])])], 1)], 1), _c('v-row', {
    staticClass: "mt-4 mb-4 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("客户痛点")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" - 销售能够高效管理客户资源，并对客户进行有效的评估，追踪客户跟进情况，提升效率。"), _c('br'), _vm._v(" - 能够掌握最新的团队和目标情况，有效的管理团队并掌握团队的销售业绩，从而能够及时调整方向。"), _c('br'), _vm._v(" - 帮助销售人员了解客户的需求状况，提高客户沟通效率，并能够为客户提供有价值的咨询服务。"), _c('br'), _vm._v(" - 提升招聘的效率，并进行整个流程的追踪。为营销人员提供针对性培训的计划。 ")])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("解决方案")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("客户与销售管理")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 通过 Master Planner 这套自定 app，员工可以管理客户关系，评估和提高绩效，追踪遍布全中国的保单和销售情况。借助周详的客户信息，营销人员目标明确，安排有序。管理人员则可以清楚掌握整个团队的运作情况和目标，从而指导员工，帮助他们更好地发展。 ")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("销售与服务")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 友邦人寿的自定 app 可以模拟财务状况，为营销人员提供与客户开启对话的切入点，并针对不同的人生阶段提供更有意义的咨询服务。通过 iPad 上的相机和语音识别软件，还能更方便快捷地完成核保流程。 ")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("招聘与培训")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 有了 iRecruiter 这款自定 app，整个招聘过程一气呵成，对营销主管和应聘者都便利，从提交申请到入职都轻松。iPad 上的摄像头还能用来扫描身份证，以便快速输入和验证身份。除此以外，营销主管可以对整个流程进行追踪把关。而自定的 E-Academy app 会根据营销人员需要具备的技能、自身能力以及职业发展规划提供有针对性的培训。 ")])])], 1), _c('v-row', {
    staticClass: "mt-4 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("项目成果")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" - 内置安全保障：通过 iOS 内置的重要安全功能，比如触控 ID 和面容 ID 等，营销人员能够轻松确保每部设备的安全。而 iOS app 的沙箱技术可存储并保护客户的数据。"), _c('br'), _vm._v(" - 自定 App：Apple 提供各种所需，帮助企业开发自定 app。友邦人寿遵循以用户为中心的开发模式，以确保这些 app 能顺畅运行。营销人员、营销主管以及业务主管一起参加设计研讨会，针对实际用户的使用场景，提供即时和持续的反馈意见。"), _c('br'), _vm._v(" - 保单签发周期平均可缩短 10 天。"), _c('br'), _vm._v(" - NPS 和 CES 稳步增长，客户满意度业界闻名。"), _c('br'), _vm._v(" - 招聘人才转化率得到 26% 的提升。"), _c('br'), _vm._v(" - 培训、招聘、运营和管理方面的成本大幅减少。 ")])])], 1), _c('v-row', {
    staticClass: "mt-8 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("客户评价")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 友邦人寿基于 iPad 搭建的这些系统，对我们的成功起到了非常重要的作用。而且在我们的未来，iPad 也将发挥巨大的作用。 Apple 是友邦人寿的首选，因为它提供了更安全的平台、更好的用户体验和更高的应用程序开发效率。 ")])])], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "mt-16 mb-4 text-h3"
  }, [_c('span', {
    staticClass: "activity-title text-h4 text-md-h3 font-weight-bold"
  }, [_vm._v("深圳市黑云精密工业有限公司")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2"
    }
  }, [_c('v-img', {
    staticClass: "white--text",
    attrs: {
      "src": "/static/success-cases/manulogo.jpg"
    }
  })], 1)], 1), _c('v-card-text', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "page-h4 mt-4 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("项目背景")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 借助 iPhone、iPad 和自定 app，对企业资源规划系统进行现代化改造，从销售到支持、从采购到生产、从车间到办公室，涉及整个公司的方方面面。 ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('vue-plyr', [_c('video', {
    attrs: {
      "controls": "",
      "playsinline": ""
    }
  }, [_c('source', {
    attrs: {
      "src": "https://doublerise-b2b.oss-cn-beijing.aliyuncs.com/prod/video/Apple%20%E4%B8%8E%20Ash%20Cloud%20-%20iOS%20%E4%BC%98%E5%8C%96%E5%B7%A5%E5%8E%82%E8%BF%90%E8%90%A5.mp4.mp4",
      "type": "video/mp4"
    }
  })])])], 1)], 1), _c('v-row', {
    staticClass: "mt-4 mb-4 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("客户痛点")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" - 提高产品品质，提升产品检验的效率和检验流程。"), _c('br'), _vm._v(" - 提升安全管理，员工身份权限验证并确保信息安全。"), _c('br'), _vm._v(" - 提高产品设计的效率，并实现多人协作。"), _c('br'), _vm._v(" - 安全生产管理，合理利用产线资源，提高生产效率。"), _c('br'), _vm._v(" - 精准控制库存以及实现合理的仓储管理。 ")])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("解决方案")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("品质管理")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 切实检验不靠猜。使用 Ash Cloud Management 自定 app，生产线主管和工人可以即时查看产品的技术规格，从而进行详尽细致的检查，找出错误、差异和工艺缺陷。这样就能在产品进入下一个阶段之前，解决质量控制问题。 ")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("权限管理")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 能者多劳的 iPhone，还兼任了员工证。黑云精密员工只能用自己的 iPhone 刷工牌进出机密区域。安装自定 app 的 iPad 可以读取员工 iPhone 上的二维码，即时确认他们的身份。员工可以使用 iPad 搜索公司目录、检查个人资料、提交各种申请、审核报销费用等。 ")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("产品设计")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 精心设计的 iPad，精通设计。黑云精密的设计师爱用 iPad 和 Apple Pencil 绘图、编辑或互相协作，进行新产品的设计构思。宽大的视网膜显示屏就像一张理想的画布，能呈现复杂又精致的细节，Apple Pencil 则可提供像素级的精确度。设计师还能把创意轻松分享给相关人士，以加快流程并迅速实施 ")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("产线监控")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 日常运行，交给 App 和机器就好。生产线主管可使用 iPhone 或 iPad 上的自定 app 检查已分配的任务，了解产品技术规格，查看包装指南，以及核对生产时间表。任务分配以后，生产线工人可以记录每个环节的进度，以便主管人员解决任何瓶颈或延误的问题。 ")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("盘点")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 加快包装出库，减少存货堆积。控制产品的库存水平对于工厂提升效率至关重要。主管人员可以使用 Ash Cloud Management app 对库存进行实时监控。利用 iPad 和一款扫码 app，工人即使在打包装箱时也能轻松扫描产品，这就提高了库存盘点的准确度。 ")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("仓储管理")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 用增强现实，不用搬动货物就能看效果。利用 iPad 宽大的视网膜显示屏，黑云精密的员工可以重新构想工厂和配送中心的布局。只需轻点、轻扫、手指开合，就能够精确测量场地、移动货盘、重新整理货架空间。 ")])])], 1), _c('v-row', {
    staticClass: "mt-4 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("项目成果")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" - 生产效率提升 30% 以上。"), _c('br'), _vm._v(" - 生产过程更顺，产品品质更高。"), _c('br'), _vm._v(" - 全面掌控和实时管理的效果更好。"), _c('br'), _vm._v(" - 很多公司基于 Swift 开发自定 app，带给员工安全、一致和可靠的工作体验。对于黑云精密这样的工厂来说，这意味着生产线能够顺利运作，员工可以快速学习新 app，各种新动态和新信息也统统触手可及。"), _c('br'), _vm._v(" - 通过整合 iOS、iPadOS、macOS 和 Cisco 技术，公司可以无缝连接企业网络，优化 app 性能，互相安全协作。Apple 与 Cisco 的合作关系确保黑云精密拥有出色的无线网络基础架构，员工可以访问工作所需的各种 app，不必担心会中断。 ")])])], 1), _c('v-row', {
    staticClass: "mt-8 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("客户评价")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" Apple 提供所需工具，让我们构建理想的管理系统，帮助我们实现愿景。 ")])])], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "mt-16 mb-4 text-h3"
  }, [_c('span', {
    staticClass: "activity-title text-h4 text-md-h3 font-weight-bold"
  }, [_vm._v("亚洲基础设施投资银行")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2"
    }
  }, [_c('v-img', {
    staticClass: "white--text",
    attrs: {
      "src": "/static/success-cases/aiib.png"
    }
  })], 1)], 1), _c('v-card-text', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "page-h4 mt-4 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("项目背景")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" - 引入 Mac 作为员工可选办公电脑；"), _c('br'), _vm._v(" - 根据员工的使用需求申请，采购 Mac 电脑。 ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/success-cases/jrbanner.png"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-4 mb-4 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("客户痛点")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" - 新引入的 Mac 电脑需要符合客户的安全合规策略；"), _c('br'), _vm._v(" - 现有的管理系统 intune 能够有效起到管理作用。 ")])])], 1), _c('v-row', {
    staticClass: "mt-4 mb-4 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("项目难点")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" - Intune 支持对 Mac 电脑的管理，但在测试中发现对于 app 推送、AD 账户登陆等需求的 支持不足，容易引发更多管理问题，并使IT的部署工作变得繁琐；"), _c('br'), _vm._v(" - 加入了 ABM 的 Mac、iPhone、iPad 在自动化部署时，注册 intune 的环节总会出现错 误，从而导致设备无法正常激活使用；"), _c('br'), _vm._v(" - IT 对 macOS 系统不了解，Helpdesk 支持能力不足 ")])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("解决方案")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("Apple 产品供应")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 提供 Mac、iPhone 和 iPad 产品的供应。 ")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("ABM 服务注册")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 将供应的所有 Apple 产品注册到客户的 ABM 账户内。 ")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("Jamf Pro Cloud")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" MDM 解决方案，替换 intune 作为 Apple 产品的管理系统，解决了 Mac 电脑管理问题，以及 Apple 产品自动化部署时的频繁出现的注册错误，intune 只作为合规检查和设备信息呈现出口。 ")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("Jamf Connect Cloud")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 移动账户认证登陆方案，解决 Mac 电脑上用 AD 账户登陆和密码同步的管理。 ")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("全生命周期服务")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 端点防护及合规管理方案，支持端点的威胁监测、防护与修复，本项目主要用于 Mac 电脑上的U盘禁用。 ")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("自助服务")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 1 、提供 macOS 和 iOS 的技术培训，提高 IT 对 Apple 设备的 Helpdesk 能力；"), _c('br'), _vm._v(" 2 、外采的 Apple 产品 ABM 注册刷机服务。 ")])])], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "mt-16 mb-4 text-h3"
  }, [_c('span', {
    staticClass: "activity-title text-h4 text-md-h3 font-weight-bold"
  }, [_vm._v("中国国际货运航空")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2"
    }
  }, [_c('v-img', {
    staticClass: "white--text",
    attrs: {
      "src": "/static/success-cases/hklogo.png"
    }
  })], 1)], 1), _c('v-card-text', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "page-h4 mt-4 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("项目背景")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 电子飞行包取代传统的飞行包，帮助飞行乘员（机组）使用更少的纸张，更容易、有效地执行飞行管理任务。 ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/success-cases/hkbanner.png"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-4 mb-4 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("客户痛点")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 产品选择问题。电子飞行包可以托管广泛的应用程序，分为三个软件类别："), _c('br'), _vm._v(" A类：静态应用程序，如文档查看器（PDF/HTML/XML格式）、飞行机组操作手册及其他印刷文件；"), _c('br'), _vm._v(" B类：静态或动态电子“航图”；"), _c('br'), _vm._v(" C类：基于技术标准订单授权的机场移动地图功能，以及有关广播式自动相关检视系统的一些组件。 ")])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("解决方案")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("整体方案")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 利用倍升互联-设备全生命周期服务解决方案，从设备选型开始引导客户选择最合适的使用设备（iPad Air），并帮助客户完成设备部署和策略分发。同时倍升互联还帮助客户进行管控平台的代运维，并在设备出现硬件问题后通过快递的方式帮助客户进行售后维修。客户在全部流程中只需要提供 apps 即可，没有更多的人员投入。 ")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("软件方案")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 国货航通过采用 WSO 企业移动化管理平台，通过该平台可以清晰的统计企业资产信息、全面掌握 EFB 资产状态、同时安全部门对设备可以进行远程操作，包括远程锁定、 密码保护、密码重置、 设备锁定、设备定位、远程擦除保证信息安全、 漫游的成本控制、统一移动应用管理等。同时结合 ABM 来快速注册设备，从而提高了工作人员办事效率及安全性。 ")])])], 1), _c('v-row', {
    staticClass: "mt-4 grey-block",
    attrs: {
      "align": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "d-flex justify-space-between align-center",
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('span', {
    staticClass: "page-h5",
    staticStyle: {
      "width": "16%"
    }
  }, [_vm._v("项目挑战")]), _c('p', {
    staticClass: "page-content",
    staticStyle: {
      "width": "31%"
    }
  }, [_vm._v(" 1.项目产品选型"), _c('br'), _vm._v(" 2.设备难以管控且经验不足"), _c('br'), _vm._v(" 3.设备售后维修"), _c('br'), _vm._v(" 4.服务响应时限"), _c('br'), _vm._v(" 5.成本改进计划 ")]), _c('span', {
    staticClass: "page-h5",
    staticStyle: {
      "width": "6%"
    }
  }, [_vm._v(">")]), _c('span', {
    staticClass: "page-h5",
    staticStyle: {
      "width": "16%"
    }
  }, [_vm._v("项目成果")]), _c('p', {
    staticClass: "page-content",
    staticStyle: {
      "width": "31%"
    }
  }, [_vm._v(" 1.项目产品选型"), _c('br'), _vm._v(" 2.设备难以管控且经验不足"), _c('br'), _vm._v(" 3.设备售后维修"), _c('br'), _vm._v(" 4.服务响应时限"), _c('br'), _vm._v(" 5.成本改进计划 ")])])], 1), _c('v-row', {
    staticClass: "mt-8 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("倍升价值")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 倍升互联凭借丰富的项目经验帮助客户从无到有的建设自己的 EFB 场景，并为客户提供一站式服务，解决客户因 IT 人才短缺所带来的人员成本增加，助力国货航电子飞行包项目安全使用六年无事故。至今倍升互联已为近10家航空公司提供电子飞行包项目的采购与支持。 ")])])], 1), _c('v-row', {
    staticClass: "mt-8 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("客户评价")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 倍升互联的全生命周期服务帮助我们持续向用户提供卓越的货运服务，同时，企业印刷费用每年节省至少300万，飞行员们也能获得贴心的服务保障。 ")])])], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "mt-16 mb-4 text-h3"
  }, [_c('span', {
    staticClass: "activity-title text-h4 text-md-h3 font-weight-bold"
  }, [_vm._v("拜耳医药")])]), _c('v-row', [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2"
    }
  }, [_c('v-img', {
    staticClass: "white--text",
    attrs: {
      "src": "/static/success-cases/medlogo.png"
    }
  })], 1)], 1), _c('v-card-text', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "page-h4 mt-4 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("项目背景")])]), _c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("移动终端服务外包")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 不同药品有非常多的特性，针对不同病症有不同的功能和特点，在国际上要经过很多权威机构的认证，有很多科学文献出来。拜耳会通过另外一个平台，结合 CRM，将这些展示给医生。 ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/success-cases/medbanner.png"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-4 mb-4 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("客户痛点")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 拜耳对不同的设备都做了一些评估，过去黑莓在公司内部有标准的应用，主要支持 Message、eMail 和日历功能，现在 iPhone 在公司内部也变成了一种新的通讯标准设。对于内部移动应用，现在更多的是基于 iPad 的应用，所以对 CRM 的两套体系，包括针对医院复杂版本和针对药店的简化版本，都要进行评估，一是使用于何种类型的销售，二是在何种规模的国家使用这种体系。 ")])])], 1), _c('v-row', {
    staticClass: "mt-4 mb-4 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("项目挑战")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 1、 需要配合业务开发移动设备服务平台；"), _c('br'), _vm._v(" 2、 客户业务遍布全国"), _c('br'), _vm._v(" 3、 全国设备发放和回收"), _c('br'), _vm._v(" 4、 新员工&新设备的使用指导"), _c('br'), _vm._v(" 5、 ABM 和 MDM权限与服务分离 ")])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("解决方案")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("整体方案")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 搭建集下单、配送、售后维修、培训及资产管理的 ESP 服务平台，利用 ESC 和 ESP 双服务中台为客户提供核心服务。同时根据自身优势扩大服务范围，除倍升自有12家 ASP 维修中心，更与百邦、阳光雨露等合作伙伴进行战略合作，覆盖全国500多个城市131家服务网点，形成能够覆盖直辖市、副省级市、地级市、副地级市、县级市的服务网络。并承接客户本地服务网点及办公地点的设备发放、回收、维修和使用指导培训工作。 ")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("部署方案")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 按照拜耳团队要求对新旧设备进行设备更换、数据擦除、刷机激活、软件安装、电话卡激活等服务。 ")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("呼叫中心方案")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 两名热线服务专员为客户提供技术支持，派单、跟单、远程培训、客户满意度调查等多项服务。 ")])])], 1), _c('v-row', {
    staticClass: "mt-8 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("项目成果")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 拜耳是一个超大型的跨国企业，在国内的办公地点多达10余个，业务遍布全国34个省级行政区，员工接近一万人。客户原有的 IT 服务方式需要雇佣接近200人的 IT 团队为各个区域服务，不仅效率低投诉率高，且很多偏远地点无法覆盖。倍升的方案使得客户不需要再承受高昂的人员成本费用，同时仅使用一个平台即可实现采购、售后、培训及资产清查等功能，大大提高了企业的办公效率，节省费用。 ")])])], 1), _c('v-row', {
    staticClass: "mt-8 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("倍升价值")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 1、 解决了 Mac 设备的管理困难，而且仍然可以用 Intune 管理合规，无需频繁切换 MDM 管理系统；"), _c('br'), _vm._v(" 2、 提升客户 IT 的 Apple 产品 Helpdesk 的支持能力；"), _c('br'), _vm._v(" 3、 解决了客户引入 Mac 办公的安全顾虑，客户已开始采购 Mac 电脑。 ")])])], 1), _c('v-row', {
    staticClass: "mt-8 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("客户评价")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 10年期间整个项目经历了两次业务大调整，不管业务萎缩或是服务交接，倍升都一如既往的帮助客户平稳度过调整期。"), _c('br'), _c('br'), _vm._v(" 在疫情三年期间，倍升为配合客户业务不中断，紧急在成都和深圳配备了临时仓用来满足客户规定的服务时效，倍升已成为拜耳中国最信赖的合作伙伴！ ")])])], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "mt-16 mb-4 text-h3"
  }, [_c('span', {
    staticClass: "activity-title text-h4 text-md-h3 font-weight-bold"
  }, [_vm._v("字节跳动（抖音集团）")])]), _c('v-row', [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2"
    }
  }, [_c('v-img', {
    staticClass: "white--text",
    attrs: {
      "src": "/static/success-cases/interLogo.png"
    }
  })], 1)], 1), _c('v-card-text', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "page-h4 mt-4 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("项目背景")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" - 在主要办公地点需要快速的电脑设备现场维修服务，设备包含 PC、Mac 和摄影器材；"), _c('br'), _vm._v(" - 服务范围需要覆盖字节全国的办公区域。 ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/success-cases/Internetbanner.png"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-4 mb-4 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("客户痛点")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" - 在 SD 内部闭环完成电脑维修流程，缩短管理链路；"), _c('br'), _vm._v(" - 实现在字节部分工区完成维修，降低数据泄漏风险，并同时提升用户体验；"), _c('br'), _vm._v(" - 协助字节优化 SLA，提升响应和维修解决时效 ")])])], 1), _c('v-row', {
    staticClass: "mt-4 mb-4 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("项目难点")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" - >95%的维修工单在下一个工作日 (NBD) 内在客户指定的工单平台上响应"), _c('br'), _c('br'), _vm._v(" - >95%的维修工单在5个工作日内完成故障维修"), _c('br'), _c('br'), _vm._v(" - 同一故障维修后三个月内发生二次维修的工单量占比当月所有维修工单量<2%"), _c('br'), _c('br'), _vm._v(" - 用户选择维修“满意”及以上的工单占比大于当月工单总量的95% ")])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("解决方案")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("驻场服务")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 为字节在北京、上海、深圳三地的所有办公区域提供全职驻场维修服务，其他7个城市的办公场所提供定期上门/按需上门/邮寄维修服务。 ")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("服务范围")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 提供包含 Mac 等多种类产品及其他 PC 设备的现场检测及维修。 ")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("人员支撑")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 在全国提供了超过20人的维修工程师专职服务字节，以满足客户的快速响应需求，提升维系时效和用户体验。 ")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("服务扩展")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 协助字节拓展部分业务，例如设备拆机清洁、旧部件检测、固件升级等。 ")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("备件服务")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 提供一定比例的维修备件，并根据月度维修情况灵活调整备件数量。 ")])]), _c('v-col', {
    staticClass: "page-h5",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("高效对接")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 全员使用飞书对接，沟通无延迟、无障碍。 ")])])], 1), _c('v-row', {
    staticClass: "mt-8 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("项目成果")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" - 年检测量超2万单，维修量超1万单"), _c('br'), _c('br'), _vm._v(" - 做到全年 PC & Mac SLA 双达标。 ")])])], 1), _c('v-row', {
    staticClass: "mt-8 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("倍升价值")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 1、 帮助字节理顺并优化了整体服务流程，更能保证维修服务的时效。"), _c('br'), _vm._v(" 2、 提供主板级修复能力，防止发生过度维修、多修、错修事件。 ")])])], 1), _c('v-row', {
    staticClass: "mt-8 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v("客户评价")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 字节充分认可倍升的服务和能力，并给倍升颁发“字节跳动年度优秀合作伙伴奖”。 ")])])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('contact-dialog', {
    attrs: {
      "showDialog": _vm.contactDialog,
      "type": "SUCCESS_CASES"
    },
    on: {
      "dialogCloseEvent": function dialogCloseEvent($event) {
        _vm.contactDialog = false;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }