var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-col', {
    staticClass: "mt-16 mb-2 text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "text-h6 font-weight-bold mt-4"
  }, [_vm._v(" " + _vm._s(_vm.knowledgeDetail.title) + " ")])]), _vm.knowledgeDetail.isVideo ? _c('v-col', {
    staticClass: "text-small",
    attrs: {
      "cols": "12"
    }
  }, [_c('vue-plyr', [_c('video', {
    attrs: {
      "controls": "",
      "playsinline": ""
    }
  }, [_c('source', {
    attrs: {
      "src": _vm.knowledgeDetail.videoKey,
      "type": "video/mp4"
    }
  })])])], 1) : _vm._e(), !_vm.knowledgeDetail.isVideo ? _c('v-col', {
    staticClass: "text-small",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.knowledgeDetail.context)
    }
  })]) : _vm._e(), _c('v-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("发布日期： " + _vm._s(_vm.knowledgeDetail.updateTime))])]), _c('v-col', {
    staticClass: "my-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('a', {
    attrs: {
      "href": "/get-support"
    }
  }, [_c('v-icon', [_vm._v("chevron_left")])], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }