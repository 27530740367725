var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-img', {
    staticClass: "white--text align-end",
    attrs: {
      "src": "/static/equipment-lease/equipment_lease_banner.png"
    }
  })], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "mt-16 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-center text-h4 text-md-h3 font-weight-bold mt-4"
  }, [_vm._v("设备租赁")])]), _c('v-col', {
    staticClass: "mb-16",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "text-center text-md-h5 my-4 grey--text text-pretty"
  }, [_vm._v(" 按需租赁硬件及软件，以及相关配套的一系列增值服务。"), _c('br'), _vm._v(" 让企业资产管理更加灵活，释放效能，优化产能。 ")])]), _c('v-col', {
    staticClass: "text-center mb-12",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    directives: [{
      name: "log",
      rawName: "v-log",
      value: 'EQUIPMENT_LEASE_WITH_CONTACT',
      expression: "'EQUIPMENT_LEASE_WITH_CONTACT'"
    }],
    staticClass: "rounded-xl large-btn",
    attrs: {
      "x-large": "",
      "depressed": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.contactDialog = true;
      }
    }
  }, [_vm._v(" 与我们取得联系 ")])], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12"
    }
  }, [_c('v-tabs', {
    attrs: {
      "centered": "",
      "plain": "",
      "transition": "fade-transition",
      "prev-icon": "chevron_left",
      "next-icon": "chevron_right",
      "show-arrows": ""
    }
  }, [_c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']
  }, [_vm._v(" MDaaS 租赁服务 ")]), _c('v-tab', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']
  }, [_vm._v(" 客户案例介绍 ")]), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "mt-16 mb-4"
  }, [_c('span', {
    staticClass: "activity-title text-h4 text-md-h3 font-weight-bold"
  }, [_vm._v("MDaaS 租赁服务")])]), _c('v-card-title', {
    staticClass: "my-4"
  }, [_c('span', {
    staticClass: "activity-title page-content font-weight-bold"
  }, [_vm._v("重新定义租赁，助力企业资产高效运转")])]), _c('v-card-text', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 我们专注于为不同行业提供定制化的硬件设备租赁以及 DaaS（Device as a Service）解决方案， 致力于提供全生命周期一站式租赁服务。"), _c('br'), _vm._v(" 我们的服务流程高效简便，付费模式灵活多样，旨在为企业大幅降低IT设备的运维成本， 优化客户的现金流动性，从而推动企业资产的高效管理与运用。 ")])]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "4"
    }
  }, [_c('img', {
    staticClass: "equip-image",
    attrs: {
      "src": "/static/equipment-lease/e1.png"
    }
  }), _c('span', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']
  }, [_vm._v("优化资产配置")]), _c('p', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']
  }, [_vm._v(" 变采购为租赁"), _c('br'), _vm._v(" 释放流动资金 ")])]), _c('v-col', {
    staticClass: "page-h4 text-center",
    attrs: {
      "cols": "4"
    }
  }, [_c('img', {
    staticClass: "equip-image",
    attrs: {
      "src": "/static/equipment-lease/e2.png"
    }
  }), _c('span', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']
  }, [_vm._v("减轻运营压力")]), _c('p', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']
  }, [_vm._v(" 租赁配套服务"), _c('br'), _vm._v(" 人员压力减轻运维 ")])]), _c('v-col', {
    staticClass: "page-h4 text-center",
    attrs: {
      "cols": "4"
    }
  }, [_c('img', {
    staticClass: "equip-image",
    attrs: {
      "src": "/static/equipment-lease/e3.png"
    }
  }), _c('span', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']
  }, [_vm._v("批量换新设备")]), _c('p', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-ms-content' : 'page-content']
  }, [_vm._v(" 同等预算支出租赁"), _c('br'), _vm._v(" 可让更多员工换新设备 ")])]), _c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 1.移动设备，灵活选择 ")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 我们为客户提供全面丰富的 IT 硬件的产 ")])]), _c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 2.拥有方式，随心选择 ")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 彻底改变传统的设备采购方式，提高企业资金 ")])]), _c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 3.提升效率，减轻压力 ")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 高效的跨平台设备管理平台，对设备进行批量操作，进行部署、配置、应用下发和管理管控。提升企业IT管理效率，保障设备数据安全，减轻IT管控压力。 ")])]), _c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 4.运营维护，轻松打理 ")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 我们提供丰富多样的运维服务选项，帮您打理移动化设备的同时，在各个阶段为您提供支持，减轻您的运维压力，释放更多IT资源。 ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "page-h4 text-center mt-8 mb-6",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" MDaaS企业客户服务旅程 ")])], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-flex justify-space-around my-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('span', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']
  }, [_vm._v("产品选型")]), _c('p', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']
  }, [_vm._v(" 根据企业需求"), _c('br'), _vm._v(" 选择硬件设备 ")])]), _c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', {
    staticClass: "vertical-center",
    attrs: {
      "large": ""
    }
  }, [_vm._v("chevron_right")])], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('span', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']
  }, [_vm._v("选择服务")]), _c('p', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']
  }, [_vm._v(" 根据企业需求"), _c('br'), _vm._v(" 选择适合的服务 ")])]), _c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', {
    staticClass: "vertical-center",
    attrs: {
      "large": ""
    }
  }, [_vm._v("chevron_right")])], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('span', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']
  }, [_vm._v("远程部署")]), _c('p', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']
  }, [_vm._v(" 批量部署"), _c('br'), _vm._v(" 批量定制 ")])])]), _c('v-col', {
    staticClass: "d-flex justify-space-around my-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('span', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']
  }, [_vm._v("设备发放")]), _c('p', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']
  }, [_vm._v(" 可按客户需求指定地点"), _c('br'), _vm._v(" 并重新包装产品 ")])]), _c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', {
    staticClass: "vertical-center",
    attrs: {
      "large": ""
    }
  }, [_vm._v("chevron_right")])], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('span', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']
  }, [_vm._v("MDM管理")]), _c('p', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']
  }, [_vm._v(" 资产管理、策略管理、安全登录"), _c('br'), _vm._v(" 内容管理、应用管理、设备管理 ")])]), _c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', {
    staticClass: "vertical-center",
    attrs: {
      "large": ""
    }
  }, [_vm._v("chevron_right")])], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('span', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']
  }, [_vm._v("专享服务")]), _c('p', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']
  }, [_vm._v(" 专家团队提供专享"), _c('br'), _vm._v(" 硬件维修和热线服务 ")])])]), _c('v-col', {
    staticClass: "d-flex justify-space-around my-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('span', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']
  }, [_vm._v("现场服务")]), _c('p', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']
  }, [_vm._v(" 为客户提供现场服务，"), _c('br'), _vm._v(" 包括上门、巡检和驻场服务 ")])]), _c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', {
    staticClass: "vertical-center",
    attrs: {
      "large": ""
    }
  }, [_vm._v("chevron_right")])], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('span', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']
  }, [_vm._v("设备期末处置")]), _c('p', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']
  }, [_vm._v(" 提供期末设备退回"), _c('br'), _vm._v(" 和数据清理 ")])]), _c('div', {
    staticClass: "text-center"
  }, [_c('v-icon', {
    staticClass: "vertical-center",
    attrs: {
      "large": ""
    }
  }, [_vm._v("chevron_right")])], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('span', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']
  }, [_vm._v("资产处置")]), _c('p', {
    class: [_vm.$vuetify.breakpoint.mobile ? 'page-m-content' : 'page-content']
  }, [_vm._v(" 所有退回的设备都会环保处置"), _c('br'), _vm._v(" 减少排放电子垃圾 ")])])])], 1), _c('v-divider', {
    staticClass: "mb-8"
  }), _c('v-row', [_c('v-col', {
    staticClass: "page-h4 my-8",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 灵活租赁模式 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('img', {
    staticClass: "lease-image",
    attrs: {
      "src": "/static/equipment-lease/l1.png"
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('span', {
    staticClass: "page-h5"
  }, [_vm._v("固定租期")]), _c('p', {
    staticClass: "page-content"
  }, [_vm._v(" 在固定的连续的时间内租赁，一般为1~3年，到期后，设备退还给出租方。 ")])]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('img', {
    staticClass: "lease-image",
    attrs: {
      "src": "/static/equipment-lease/l2.png"
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('span', {
    staticClass: "page-h5"
  }, [_vm._v("分期租赁")]), _c('p', {
    staticClass: "page-content"
  }, [_vm._v(" 在固定的连续的时间内租赁，一般为1~3年，到期后，设备归承租方。 ")])]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('img', {
    staticClass: "lease-image",
    attrs: {
      "src": "/static/equipment-lease/l3.png"
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('span', {
    staticClass: "page-h5"
  }, [_vm._v("组合租赁")]), _c('p', {
    staticClass: "page-content"
  }, [_vm._v(" 在租赁期内的不同阶段，可按照不同价格租赁，即分阶段定价;"), _c('br'), _vm._v(" 到期后，可选择买断设备或退回等;"), _c('br'), _vm._v(" 买断价格，分为事先商定的协议价和市场价;"), _c('br'), _vm._v(" 如提前买断，则视具体产品而具体协商，其测算原则为剩余租金加上设备残值。 ")]), _c('p', {
    staticClass: "page-h5"
  }, [_vm._v(" *上述的组合租赁，属于深度定制化租赁，请联系销售顾问。 ")])])], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "mt-16 mb-4 text-h3"
  }, [_c('span', {
    staticClass: "activity-title text-h4 text-md-h3 font-weight-bold"
  }, [_vm._v("客户案例介绍")])]), _c('v-tabs', {
    attrs: {
      "centered": "",
      "plain": "",
      "transition": "fade-transition",
      "prev-icon": "chevron_left",
      "next-icon": "chevron_right",
      "show-arrows": ""
    }
  }, [_c('v-tab', {
    class: _vm.$vuetify.breakpoint.mobile ? ['tab-mobile-title', 'text-h7'] : ['tab-title', 'text-h5']
  }, [_vm._v(" 知名工业领域领导者 ")]), _c('v-tab', {
    class: _vm.$vuetify.breakpoint.mobile ? ['tab-mobile-title', 'text-h7'] : ['tab-title', 'text-h5']
  }, [_vm._v(" 智能化汽车科技公司 ")]), _c('v-tab', {
    class: _vm.$vuetify.breakpoint.mobile ? ['tab-mobile-title', 'text-h7'] : ['tab-title', 'text-h5']
  }, [_vm._v(" 知名清洁能源科技公司 ")]), _c('v-tab', {
    class: _vm.$vuetify.breakpoint.mobile ? ['tab-mobile-title', 'text-h7'] : ['tab-title', 'text-h5']
  }, [_vm._v(" 知名电梯企业 ")]), _c('v-tab', {
    class: _vm.$vuetify.breakpoint.mobile ? ['tab-mobile-title', 'text-h7'] : ['tab-title', 'text-h5']
  }, [_vm._v(" 大型综合企业集团 ")]), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "page-h4 mt-4 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("客户介绍")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 客户为世界知名的工业和风能传动领域的引领者，是一家有着120多年历史积淀的企业，以高效、创新、稳定和优质的机械与电气零部件闻名于世，产品应用遍布世界各个角落。客户专注于齿轮箱的研制、开发和规模化生产，产品涵盖工业齿轮箱、风能齿轮箱、联轴器、状态检测系统等，广泛应用于煤炭、风电、水泥等行业。 ")])])], 1), _c('v-row', {
    staticClass: "mt-4 mb-4 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("项目背景")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 已经在使⽤租赁模式，希望能需找到⼀家既能提供租赁产品，同时能提供租赁 设备服务的服务商，来帮助客户提⾼ IT 运维能⼒，并提⾼客户满意度。 ")])])], 1), _c('v-row', {
    staticClass: "mt-4 mb-4 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("客户痛点")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 1、 集团预算机制变化"), _c('br'), _vm._v(" 2、 由于战略调整出现设备转换空窗期"), _c('br'), _vm._v(" 3、 租赁的设备缺乏管理和控制"), _c('br'), _vm._v(" 4、 租赁设备缺少常规运维和服务 ")])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "page-h4 mt-4 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("MDaaS 解决方案")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 1、 HP产品租赁，满⾜集团年初预算"), _c('br'), _vm._v(" 2、 三周内提供近1000台中⾼端设备，满⾜客户短期需求"), _c('br'), _vm._v(" 3、 随产品提供微软 AutoPilot 部署⼯具和 MDM 管理平台"), _c('br'), _vm._v(" 4、 提供设备运维、旧设备回收、资产管理平台、数据擦除 ")])])], 1), _c('v-row', {
    staticClass: "mt-4 mb-4 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("MDaaS 提供的价值")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 1、 HP产品租赁，满足集团年初预算满足财务政策"), _c('br'), _vm._v(" 2、 提升工作效率"), _c('br'), _vm._v(" 3、 技术支持"), _c('br'), _vm._v(" 4、 设备管理 ")])])], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "page-h4 mt-4 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("客户介绍")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 客户公司作为汽⻋⾏业领先的汽⻋智能化科技公司，致⼒于持续打造⾏业领先 的智能⽹联⽣态开放平台，全⾯为⻋企赋能，创造更智能、更安全的出⾏体验。公 司以“让智慧出⾏驱动美好⽣活”为愿景，以“加速汽⻋智能化，创建⼈⻋新关系”为 使命，未来全球汽⻋智能化发展新格局。 ")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/equipment-lease/s4.png"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-4 mb-4 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("项目背景")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 为了应对外部环境的压⼒，同时解决内部不断增⻓的业务压⼒，该企业决定采⽤ 终端设备租赁及 IT ⼈员外包的⽅式来解决⼀次性资⾦投⼊过⼤的问题。 ")])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "page-h4 mt-4 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("客户痛点")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 1、 占⽤资⾦流问题"), _c('br'), _vm._v(" 客户员⼯总数超1万⼈，新增⼈员的设备缺⼝约近4000台，且都为 Macbook 及多个品牌的产品。"), _c('br'), _vm._v(" 2、 运维管理难度⼤"), _c('br'), _vm._v(" 客户在多地拥有多个分公司，员⼯数量众多，但是 IT 桌⾯运维⼈员仅为 6 ⼈，设备 部署压⼒巨⼤，维修协调困难。"), _c('br'), _vm._v(" 3、 管理经验弱"), _c('br'), _vm._v(" 内部 IT 桌⾯运维⼈员对于苹果设备接触较少，对于 Mac 设备的⽀持能⼒较弱，⽆ 法提供完善的⽀持。 ")])])], 1), _c('v-row', {
    staticClass: "mt-4 mb-4 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("MDaaS 解决方案")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 1、 以租代购 ")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 根据客户实际的需求定制组合租赁⽅案，不同需求匹配不同的产品。并提供随租随 还、意外免赔付等服务⽅案，优化了资⾦结构减少客户的⼀次性投⼊，将更多现⾦流投⼊到核⼼业 务中，提⾼市场竞争⼒。 ")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 2、 驻场⼯程师 ")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 为客户提供驻场服务⼯程师，为其提供⾼效的 IT 运维服务，全⼒保障客户员⼯的 ⼯作效率，确保其将全部精⼒集中到业务发展中。 ")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 3、 ABM + MDM ")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 为客户提供 ABM + MDM 管理⽅案，协助客户 IT ⾼效便捷的管理 Apple 相关 的设备，确保苹果设备符合客户的 IT 安全政策。 ")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 4、 专业培训 ")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 为最终⽤户提供专业的 Apple 设备的使⽤培训，帮助新员⼯更快的熟练使⽤ Apple 设备，提⾼⼯作效率，加快新员⼯迅速融⼊正常⼯作的进度。 ")])])], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "page-h4 mt-4 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("客户介绍")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 客户为国内知名清洁能源科技公司，是全球可信赖的清洁能源战略合 作伙伴，致⼒于推动能源变⾰，让⼈⼈可负担、可靠、可持续的能源惠及 全球，构建“可持续更美好”的未来。该客户深度聚焦能源开发、能源装 备、能源服务、能源应⽤四⼤领域，以强⼤科研创新和最佳业务实践，将 可再⽣能源的效率提升⾄新⾼度。 ")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/equipment-lease/s5.png"
    }
  })], 1), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/equipment-lease/s6.png"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-4 mb-4 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("项目背景")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 为满足客户不断发展的业务需求，提高办公效率，客户计划进行办公电脑及工作站的租赁， 以确保员工拥有高性能的工作设备，支持各类办公应用和项目需求。 ")])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "page-h4 mt-4 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("客户痛点")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 1、 提升⼯作效率"), _c('br'), _vm._v(" 2、 降低采购成本"), _c('br'), _vm._v(" 3、 技术⽀持"), _c('br'), _vm._v(" 4、 设备管理 ")])])], 1), _c('v-row', {
    staticClass: "mt-4 mb-4 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("MDaaS 解决方案")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 1、 以租代购 ")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 为客户提供多品牌、⾼性能的全新电脑和⼯作站；并提供预安装部署和专业的 设备使⽤培训服务。 ")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 2、 降低成本 ")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 帮助客户减少⼀次性采购成本，提供灵活便捷的租赁和服务⽅案，为客户降低 IT 设备维护和更新的负担。 ")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 3、 技术⽀持 ")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 为客户提供硬件和软件维护，以及⽇常设备巡检保障设备的稳定性，并提供 备机服务降低设备故障对业务的影响，确保⼯作正常进⾏。 ")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 4、 设备管理 ")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 为客户提供设备检测回收服务，并提供专业数据擦除服务，确保数据安全， 利⽤资产管理平台简化设备管理流程，提⾼⼯作效率。 ")])])], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "page-h4 mt-4 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("客户介绍")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 某国际知名电梯企业作为电梯和自动扶梯制造、安装和服务的全球领导者，在全球范围内维护着超过220万个客户单位，是业内最大的服务组合。我们会在世界上最具标志性的建筑、住宅和商业建筑、交通枢纽以及人们出行的任何地方接触到他。 ")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/equipment-lease/s7.png"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-4 mb-4 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("项目背景")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 作为全球领先的某知名电梯制造商，目前公司有近万台Apple设备用于员工办公、电梯巡检， 故障派单等保障性核心业务；随着设备生命周期到来，原有近10000台设备陆续需进行设备 回收和更换，且公司考虑轻资产租赁方式进行供应商选择。 ")])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "page-h4 mt-4 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("客户痛点")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 1、 采购需求"), _c('br'), _vm._v(" 购置大量资产，并不符合该制造商的战略需求，需要替代性的才买方案以符合其全球管理的决策。"), _c('br'), _vm._v(" 2、 管理 & 控制需求"), _c('br'), _vm._v(" Flexibility + Security + Reduced TCO"), _c('br'), _vm._v(" 3、 服务需求"), _c('br'), _vm._v(" 缺少发放、运维、维修、回收等服务支持。 ")])])], 1), _c('v-row', {
    staticClass: "mt-4 mb-4 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("MDaaS 解决方案")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 1、 设备租赁 ")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 采用经营性租赁、分期付款的方式，以实现企业轻资产化，帮助该制造商实现开源节流的目标，以符合其全球战略管理决策 ")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 2、 零接触部署 & MDM 服务 ")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 作为 Apple 官方 ABM 服务商，提供 ABM 全自动零接触部署方案的落地与实施，并且作为 VMware 金牌服务商，提供针对 WSO 平台的服务及咨询支持 ")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 3、 运维管理 & 回收服务 ")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 作为 Apple 授权企业经销商及服务商，提供 Apple 设备全生命周期的运维保障服务，确保该制造商使用的设备在日常工作中正常运行，并且提供分布全国的售后支持服务 针对已经超过生命周期的设备，提供就设备的回收检测及专业的数据擦除服务，确保企业数据安全。 ")])])], 1)], 1)], 1)], 1), _c('v-tab-item', {
    attrs: {
      "transition": "fade-transition"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "mt-4 mb-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "page-h4 mt-4 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("客户介绍")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 该集团是⼀家以有⾊材料智造、教育事业、健康产业（⽣态农业、医疗、养 ⽼）等多产业布局的国际化⼤型综合企业集团，总部位于杭州市。 现有员⼯ 25000多名，产业布局12个国家和地区，营销⽹络辐射全球。连续四 年上榜世界500强，连续⼗九年上榜中国企业500强。旗下的股份公司在亚洲、 美洲、欧洲设有22个⽣产基地、是全球铜管棒加⼯⾏业的标杆和领袖级企业， 教育集团是中国⺠办基础教育的标杆。 ")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/equipment-lease/s8.png"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-4 mb-4 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("项目背景")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 为了应对外部环境的压⼒，同时也为了更好实现集团轻资产、数字化管 理的需求，需要对集团内员⼯⽇常办公 IT 设备服务的获得⽅式进⾏变 更，以更好赋能员⼯，让员⼯办公设备和服务实现灵活的按需配置、按 使⽤付费。 ")])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "page-h4 mt-4 mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("客户痛点")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 1、 终端更新需求"), _c('br'), _vm._v(" 客户员⼯总数超2万⼈，新增⼈员的设备缺⼝约近5000台，且在⽤设备⼤ 多数已经到达或者接近5年的使⽤⽣命周期。"), _c('br'), _vm._v(" 2、 运维管理需求"), _c('br'), _vm._v(" 客户涉及⾏业复杂、员⼯数量众多、终端使⽤场景复杂，但是 IT 桌⾯运 维⼈员仅⼗多⼈，设备部署更新压⼒巨⼤，技术⽀持响应缓慢。"), _c('br'), _vm._v(" 3、 旧设备、闲置设备回收需求"), _c('br'), _vm._v(" 有⼤量到达⽣命周期末的设备需要完成回收、数据清除、汇总、报告等。 ")])])], 1), _c('v-row', {
    staticClass: "mt-4 mb-4 grey-block"
  }, [_c('v-col', {
    staticClass: "page-h4",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("MDaaS 解决方案")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 1、 设备租赁 ")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 根据客户实际的需求定制组合租赁⽅案，提供包括36期随租随还、意外免赔付等服 务⽅案，极⼤减少了客户的⼀次性投⼊成本，优化了现⾦流。 ")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 2、 项⽬管理 ")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 为客户提供专职的项⽬管理⼈员，协助⽀持客户进⾏项⽬设备的部署、数据擦除、 回收等⼯作，确保项⽬的顺利交付。 ")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 3、 驻场⼯程师 ")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 客户提供驻场服务⼯程师，为其提供⾼效的 IT 运维服务，全⼒保障客户员⼯的⼯ 作效率，确保其将全部精⼒集中到业务发展中。 ")])]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 4、 设备发放 ")]), _c('v-col', {
    staticClass: "page-content",
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" 为客户提供上⻔集中发放服务，同时为员⼯提供数据迁移咨询及新设备使⽤培训， 减轻了客户 IT 及项⽬组的负担，同时提⾼了最终⽤户的满意度。 ")])])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('contact-dialog', {
    attrs: {
      "showDialog": _vm.contactDialog,
      "type": "DEVICE_LEASING"
    },
    on: {
      "dialogCloseEvent": function dialogCloseEvent($event) {
        _vm.contactDialog = false;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }