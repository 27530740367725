<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-card flat>
          <v-img src="/static/solution/solution-banner.png" class="white--text align-end">
          </v-img>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mt-16 mb-4">
        <p class="text-center text-h4 text-md-h3 font-weight-bold mt-4">解决方案</p>
      </v-col>
      <v-col cols="12" class="mb-16">
        <p class="text-center text-md-h5 my-4 grey--text text-pretty">
          为不同领域的企业提供包含硬件采购、软件开发、移动设备管理（MDM）<br />
          以及设备全生命周期服务在内的定制化解决方案。
        </p>
      </v-col>
      <v-col cols="12" class="text-center mb-12">
        <v-btn
          x-large
          depressed
          color="primary"
          class="rounded-xl large-btn"
          v-log="'SOLUTIONS_WITH_CONTACT'"
          @click="contactDialog = true"
        >
          与我们取得联系
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-tabs 
          centered 
          plain 
          transition="fade-transition" 
          prev-icon="chevron_left"
          next-icon="chevron_right"
          show-arrows
        >
          <v-tab :class="[$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']">
            MDM 移动设备管理
          </v-tab>
          <v-tab :class="[$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']">
            桌面虚拟化
          </v-tab>
          <v-tab :class="[$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']">
            零信任安全
          </v-tab>

          <!--MDM 移动设备管理-->
          <v-tab-item transition="fade-transition">
            <v-card flat>
              <v-card-title class="mt-16 mb-4 text-h3">
                <span class="activity-title text-h4 text-md-h3 font-weight-bold">MDM 移动设备管理</span>
              </v-card-title>

              <v-card-text class="mt-4 mb-4">
                <v-row>
                  <v-col cols="12" class="text-md-h5 my-4 grey--text">
                    <p>
                      移动设备管理，又称 MDM (Mobile Device Management)，它提供从设备注册、激活、使用、淘汰各个环节进行完整的移动设备全生命周期管理。移动设备管理 (MDM) 能实现用户及设备管理、配置管理、安全管理、资产管理等功能。移动设备管理 (MDM) 还能提供全方位安全体系防护，同时在移动设备、移动 APP、移动文档三方面进行管理和防护。在增强企业员工的移动性，在不影响企业安全的前提下提高员工生产力。它可以让你管理智能手机、笔记本电脑、平板电脑和台式机以及多种操作系统，如 iOS、Android、Windows、macOS 和 Chrome OS。
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="page-h4">
                    <span>选择适合企业的移动管理</span>
                  </v-col>
                  <v-col cols="12" class="page-content my-0">
                    <span>功能方面选择</span>
                  </v-col>
                  <v-col cols="12" class="my-4">
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="1">
                    <img class="mdm-image" src="/static/solution/s1.png">
                  </v-col>
                  <v-col cols="11">
                    <span class="page-h4">1.部署</span>
                    <p class="page-content">
                      通过 iPad 和自定 app，门店经理能掌握大量有用的数据。他们可以查看上一年的销售情况和流行趋势，了解本周表现出色的售货员，以及当下畅销的服装款式。这些数据全都触手可及，使门店经理能在不断变化的零售环境中始终拥有先见之明。
                    </p>
                  </v-col>
                  <v-col cols="1">
                    <img class="mdm-image" src="/static/solution/s2.png">
                  </v-col>
                  <v-col cols="11">
                    <span class="page-h4">2.配置</span>
                    <p class="page-content">
                      一台新设备分发之后，往往需要有各种网络、用户、应用等等配置，十分耗费时间，管控软件可以有效的解决这个问题。
                    </p>
                  </v-col>
                  <v-col cols="1">
                    <img class="mdm-image" src="/static/solution/s3.png">
                  </v-col>
                  <v-col cols="11">
                    <span class="page-h4">3.Apps</span>
                    <p class="page-content">
                      APP 管理是企业中最重要的一环，移动化办公的核心就是移动应用的使用，因此如何快速部署和升级安装应用是企业最关心的功能之一。
                    </p>
                  </v-col>
                  <v-col cols="1">
                    <img class="mdm-image" src="/static/solution/s4.png">
                  </v-col>
                  <v-col cols="11">
                    <span class="page-h4">4.资产管理</span>
                    <p class="page-content">
                      资产管理是企业很重要的一个需求，通过软件企业能够第一时间了解设备的使用状态和分布情况。可以根据设备所属部门分组，设备的类型分组，设备上安装的 APP 分组等等，然后根据不同的分组配置不同的配置文件和策略。
                    </p>
                  </v-col>
                  <v-col cols="1">
                    <img class="mdm-image" src="/static/solution/s5.png">
                  </v-col>
                  <v-col cols="11">
                    <span class="page-h4">5.安全</span>
                    <p class="page-content">
                      安全管控对企业来说是非常重要，可以限制应用的使用，一旦发现有黑名单应用，我们可以将应用强制关闭，也可以直接删除。
                    </p>
                  </v-col>
                  <v-col cols="1">
                    <img class="mdm-image" src="/static/solution/s6.png">
                  </v-col>
                  <v-col cols="11">
                    <span class="page-h4">6.自助服务</span>
                    <p class="page-content">
                      自助服务也是一个非常强大的功能，相当于为企业提供了一个内部的应用商店，企业可以把需要的内容分发上去，让员工自主选择安装。企业可以把 APP、电子书、甚至打印机或者其他硬件的驱动发布到自助服务区中，还可以自己编写一些可执行脚本发布进去，让用户根据需要点击使用或安装。
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-tabs 
                      centered 
                      plain 
                      transition="fade-transition" 
                      prev-icon="chevron_left"
                      next-icon="chevron_right"
                      show-arrows
                    >
                      <v-tab :class="[$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']">
                        VMware
                      </v-tab>
                      <v-tab :class="[$vuetify.breakpoint.mobile ? 'tab-mobile-title' : 'tab-title']">
                        Jamf
                      </v-tab>
                      <!--VMware-->
                      <v-tab-item transition="fade-transition">
                        <v-card flat>
                          <v-card-title class="my-8">
                            <span class="activity-title text-h4 text-md-h3 font-weight-bold">VMware</span>
                          </v-card-title>
                          <v-card-subtitle class="text-center text-md-h5 my-4 grey--text">
                            <p>倍升互联作为 VMware 高级合作伙伴，为适应企业轻资产化的大趋势，现已推出更加灵活便捷的 VMware 订阅方案，企业客户可以根据实际需要，按年订阅所需席位。此外，我们还提供相应的 MDM 部署解决方案和运维服务，解放 IT 生产力。</p>
                          </v-card-subtitle>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" md="10" offset-md="1">
                                <v-img src="/static/solution/VMwareLogo.png" />
                              </v-col>
                              <v-col cols="12" class="page-h5">
                                <span>VMware Workspace One 数字化工作空间平台</span>
                              </v-col>
                              <v-col cols="12" class="page-content">
                                <p>
                                  VMware Workspace ONE 是一款智能驱动型数字化工作空间平台，可通过自动注册零接触部署、软件分发和移动设 备管理，移动安全管理，来简单、安全地在相关设备上交付和管理任何应用。Workspace ONE 集成了访问控制 、应用管理和多平台端点管理功能，可以作为云计算服务提供，也可以用于本地部署。
                                </p>
                              </v-col>
                              <v-col cols="2">
                                <img class="vm-image" src="/static/solution/VMwarePic1.png" />
                              </v-col>
                              <v-col cols="10">
                                <span class="page-h5">自动注册零接触部署</span>
                                <p class="page-content">
                                  通过 Apple Business Manager 您可以自动注册 Apple 设备，部署内容，并委派管理权限。您可以在后台设置，自动将企业拥有的移动设备的配置部署到您公司的所有设备。无需在设备上手动操作，您即享受更高级别的控制权限和安全级别，并确定所有用户在拿到设备时，开箱即用!
                                </p>
                              </v-col>
                              <v-col cols="2">
                                <img class="vm-image" src="/static/solution/VMwarePic2.png" />
                              </v-col>
                              <v-col cols="10">
                                <span class="page-h5">软件分发</span>
                                <p class="page-content">
                                  企业能够通过 Workspace ONE 软件分发来自动安装、更新和删除软件包，并且提供脚本分发和文件管理工具。为软件、应用、文件、脚本和命令创建自动化工作流，以在注册过程中，或按需在终端设备上安装和配置。您还可以将软件包设置为根据条件进行安装，自动部署软件更新，并在更新发布时通知用户。
                                </p>
                              </v-col>
                              <v-col cols="2">
                                <img class="vm-image" src="/static/solution/VMwarePic3.png" />
                              </v-col>
                              <v-col cols="10">
                                <span class="page-h5">移动设备管理</span>
                                <p class="page-content">
                                  为了保持工作效率，员工需要随时随地能够访问企业的内容。很多员工正在利用免费的文件共享服务，来访问敏感文档，让企业数据陷入风险。Workspace ONE 可以让您随时随地安全的对内容进行移动访问。为远程办公团队，提供了更好的体验。
                                </p>
                              </v-col>
                              <v-col cols="2">
                                <img class="vm-image" src="/static/solution/VMwarePic4.png" />
                              </v-col>
                              <v-col cols="10">
                                <span class="page-h5">移动安全管理</span>
                                <p class="page-content">
                                  Workspace ONE 通过添加合规策略来检查设备日常安全状态，并制定相应的触发器来自动执行安全操作。如果设备丢失，也可以直接在管理控制台下发企业擦除，甚至设备擦除的命令，避免企业数据外泄。
                                </p>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>

                      <!--Jamf-->
                      <v-tab-item transition="fade-transition">
                        <v-card flat>
                          <v-card-title class="page-h2">
                            <span class="activity-title text-h4 text-md-h3 font-weight-bold my-4">Jamf</span>
                          </v-card-title>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" md="6" class="mb-8">
                                <p class="text-h5 text-md-h4 font-weight-bold mt-4 mb-8">
                                  由于专注于 Apple 生态系统，<br /><br />
                                  全球的行业领导者选择 Jamf
                                </p>
                                <p class="text-md-h5 my-4 grey--text">
                                  自动化 Apple 设备管理的整个生命周期<br />
                                  自动化 Apple 设备管理的整个生命周期<br />
                                  保留用户所需的 Apple 设备体验<br />
                                  访问全球最大的 Apple IT 社区<br />
                                  自动化 Apple 设备管理的整个生命周期<br />
                                  对每个 macOS、iOS、iPadOS 和 tvOS 版本的<br />
                                  当天 Apple OS 支持<br />
                                  行业特定的应用程序和工作流程可满足您的确切需求
                                </p>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-img src="/static/solution/jarmpos1.png" />
                              </v-col>
                              <v-col cols="12" md="6" class="mb-8">
                                <v-img src="/static/solution/jarmpos2.png" />
                              </v-col>
                              <v-col cols="12" md="6">
                                <span class="page-h4">
                                  为什么使用 Jamf ？
                                </span>
                                <p class="page-content">
                                  Jamf 是唯一可远程连接、管理和保护 Apple 用户、设备和服务的大规模 Apple 企业管理解决方案。
                                </p>
                              </v-col>

                              <v-col cols="12">
                                <img class="jamf-image" src="/static/solution/jamf1.png" />
                              </v-col>
                              <v-col cols="12">
                                <span class="page-h5">Jamf Pro 设备管理</span>
                              </v-col>
                              <v-col cols="12">
                                <p class="page-content">
                                  使简单的任务自动化，使复杂的任务简单化。Jamf Pro 为员工和学生提供他们所需的工具，让他们在工作和学习中更加轻松自如。Jamf Pro（原名为 Casper Suite）在提高终端用户工作效率和创造力的同时，使您的设备管理自动化，兑现了 Apple 设备统一端点管理的承诺，是适用于 IT 专家和其所支持用户的 EMM 工具。
                                </p>
                              </v-col>

                              <v-col cols="12">
                                <img class="jamf-image" src="/static/solution/jamf2.png" />
                              </v-col>
                              <v-col cols="12">
                                <span class="page-h5">Jamf Connect 连接用户</span>
                              </v-col>
                              <v-col cols="12">
                                <p class="page-content">
                                  简化 Mac 身份验证和账户管理。是否要绑定到 Active Directory (AD)? 不需要。如今,大众强烈希望能够在保障账户安全性的基础上免除将 Mac 绑定到 Active Directory 的操作。Jamf 近期收购了 NoMAD，现已更名为 Jamf Connect，以实现这一流程的无缝衔接。通过 Jamf Connect，最终用户可在拆箱打开 Mac 后使用一组 Okta 或 Mic-rosoft Azure 凭据登录，而后访问其公司的所有应用程序。
                                </p>
                              </v-col>

                              <v-col cols="12">
                                <img class="jamf-image" src="/static/solution/jamf3.png" />
                              </v-col>
                              <v-col cols="12">
                                <span class="page-h5">Jamf Protect 终端防护</span>
                              </v-col>
                              <v-col cols="12">
                                <ol class="page-content">
                                  <li>防病毒保护</li>
                                  <li>合规监控</li>
                                  <li>安全可见性</li>
                                  <li>行为侦测</li>
                                  <li>威胁搜寻</li>
                                </ol>
                              </v-col>

                              <v-col cols="12">
                                <img class="jamf-image" src="/static/solution/jamf4.png" />
                              </v-col>
                              <v-col cols="12">
                                <span class="page-h5">Jamf Private Access</span>
                              </v-col>
                              <v-col cols="12">
                                <p class="page-content">
                                  让零信任网络访问成为现实。安全地将员工连接到在现代社会中发挥生产力所需的设备、应用程序和数据。Private Access 是一个真正的零信任网络访问解决方案，它取代了传统的条件式访问和 VPN 技术。现在，作为 Jamf 平台的一部分，它可以确保在用户对其设备进行身份验证后，业务连接得到保护，同时让非业务应用程序能够直通互联网，维护终端用户的私密性并优化网络基础设施。对于 IT 而言，部署服务器、管理证书和配置 IP 地址将成为过去。
                                </p>
                              </v-col>
                              <v-col cols="12">
                                <span class="page-h5">Jamf Threat Defense</span>
                              </v-col>
                              <v-col cols="12">
                                <p class="page-content">
                                  由 Jamf 提供支持的 Threat Defense 通过移动威胁检测和零日网络钓鱼攻击预防，借助一流的通知和修复应用程序来保护 iOS 终端设备免遭入侵。 Jamf 和 Threat Defense 一同组成了一个完整的专用解决方案，保护 Apple 用户免受恶意侵害，同时将对最终用户体验的影响降至最低。
                                </p>
                              </v-col>
                              <v-col cols="12">
                                <span class="page-h5">Jamf Data Policy</span>
                              </v-col>
                              <v-col cols="12">
                                <p class="page-content">
                                  自动化、执行和管理使用政策和数据使用。仅有基础知识无法做好设备管理，对于支持远程和混合环境员工的企业尤其如此。公司移动设备提供了随时随地工作的自由，但 YouTube和 Netflix 等流媒体服务的兴起，正在迅速消耗数据资源。即使在个人启用的设备上，非业务活动也不应为您的数据计划或者可以接受的远程用户使用政策带来压力。
                                </p>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                    </v-tabs>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!--桌面虚拟化-->
          <v-tab-item transition="fade-transition">
            <v-card flat>
              <v-card-title class="mt-16 mb-4 text-h3">
                <span class="activity-title text-h4 text-md-h3 font-weight-bold">桌面虚拟化</span>
              </v-card-title>

              <v-card-text class="mt-4 mb-4">
                <v-row>
                  <v-col cols="12" class="text-md-h5 my-4 grey--text">
                    <p>
                      将计算机的终端系统(也称作桌面)进行虚拟化，以达到桌面使用的安全性和灵活性。 可以通过任何设备，在任何地点，任何时间通过网络访问属于我们个人的桌面系统。
                    </p>
                  </v-col>
                  <v-col cols="12" class="page-h4">
                    <span>为什么使用虚拟化桌面和应用?</span>
                  </v-col>

                  <v-col cols="1">
                    <img class="mdm-image" src="/static/solution/v1.png">
                  </v-col>
                  <v-col cols="11">
                    <span class="page-h4">1.提高安全性</span>
                    <p class="text-md-h5 my-4 grey--text">
                      数据和应用从不离开数据中心，消除了端点泄漏。
                    </p>
                  </v-col>
                  <v-col cols="1">
                    <img class="mdm-image" src="/static/solution/v2.png">
                  </v-col>
                  <v-col cols="11">
                    <span class="page-h4">2.简化管理</span>
                    <p class="text-md-h5 my-4 grey--text">
                      借助现代方法集中、简化和加快管理和交付，避免使用传统的 PC 管理工具。
                    </p>
                  </v-col>
                  <v-col cols="1">
                    <img class="mdm-image" src="/static/solution/v3.png">
                  </v-col>
                  <v-col cols="11">
                    <span class="page-h4">3.确保合规性</span>
                    <p class="text-md-h5 my-4 grey--text">
                      精细控制有助于在受监管的行业中实施合规性要求。
                    </p>
                  </v-col>
                  <v-col cols="1">
                    <img class="mdm-image" src="/static/solution/v4.png">
                  </v-col>
                  <v-col cols="11">
                    <span class="page-h4">4.提高工作效率</span>
                    <p class="text-md-h5 my-4 grey--text">
                      将 Windows、传统和关键业务应用交付给任何位置的任何设备，无论这些设备采用何种操作系统。
                    </p>
                  </v-col>
                  <v-col cols="1">
                    <img class="mdm-image" src="/static/solution/v5.png">
                  </v-col>
                  <v-col cols="11">
                    <span class="page-h4">5.提高收入</span>
                    <p class="text-md-h5 my-4 grey--text">
                      通过利用低成本端点以及池化数据中心中的计算、图形和存储资源来提高利用率，从而节省成本。
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="page-h5 mb-12">
                    <span>为什么使用虚拟化桌面和应用??</span>
                  </v-col>
                  <v-col cols="3">
                    <v-row>
                      <v-col cols="12" :class="['text-center', $vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']">
                        传统 PC<br />
                        架构缺点
                      </v-col>
                      <v-col cols="12" class="text-center">
                        <v-icon large>keyboard_arrow_down</v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="9" class="page-content">
                    <p>
                      1.难以管理 <br />
                      2.总体拥有成本高<br />
                      3.难以保护数据的安全<br />
                      4.资源未充分利用<br />
                      5.接入桌面方式单一、无法移动办公
                    </p>
                  </v-col>
                  <v-col cols="3" :class="['text-center', $vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']">
                    解决方案
                  </v-col>
                  <v-col cols="9" class="page-content">
                    <p>
                      桌面虚拟化构建基于服务器的桌面解决方案，不仅可以解决 PC 桌面面临的各种难题，还能优化可用性、可管理性、总体拥有成本和灵活性
                    </p>
                  </v-col>
                  <v-col cols="3" :class="['text-center', $vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']">
                    方案概述
                  </v-col>
                  <v-col cols="9" class="page-content">
                    <p>
                      桌面虚拟化架构采用以用户为中心的计算、按需向用户提供 IT 资源，既不改变用户
                      的使用习惯、保证用户获得足够的自由度，同时满足集中管理和数据安全的要求，
                      解决上述桌面管理的各种难题。
                    </p>
                  </v-col>
                  <v-col cols="3">
                    <v-row>
                      <v-col cols="12" class="text-center">
                        <v-icon large>keyboard_arrow_down</v-icon>
                      </v-col>
                      <v-col cols="12" :class="['text-center', $vuetify.breakpoint.mobile ? 'page-h6' : 'page-h5']">
                        方案目标
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="9" class="page-content">
                    <p>
                      1.降低成本<br />
                      2.更广泛与简化的终端设备支持<br />
                      3.终端设备采购、维护成本大大降低<br />
                      4.集中管理、统一配置，使用更安全<br />
                      5.降低耗电、节能减排，让企业 IT 更为绿色<br />
                      6.让企业的数据与 IT 系统更为安全，保证业务连续性
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="page-h4">
                    桌面虚拟化-大势所趋
                  </v-col>
                  <v-col cols="12" class="text-md-h5 my-4 grey--text">
                    <p>
                      许多公司已经转向虚拟化以便用有限的 IT 预算做更多的事情和降低技术成本。企业正认识到存储和服务器虚拟化能够显著减少硬件、电源、空间和管理等成本的好处，桌面虚拟化将为企业 IT 部门提供巨大的成本好处。
                      鉴于用户正在变得越来越移动性和应用程序越来越大的事实，接触每一个桌面，为最终用户的系统安装、设置和更新应用程序是一项非常繁重的任务。另一方面，用户日益要求他们的 PC 尽可能快速的运行，无论是在线还是离线都要快速地运行，同时还要保证他们的计算环境的安全。桌面虑拟化旨在使工作场所与它下面的架构层隔离开，为企业提供新的管理和部署的选择。 随着全球疫情的不固定性，我们注定要与疫情进行长期奋战，后疫情时代不止改变了我们的生活与工作习惯，更造成了全球零部件和电子产品短缺。桌面虚拟化也将迎来更大的机会。
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!--零信任安全-->
          <v-tab-item transition="fade-transition">
            <v-card flat>
              <v-card-title class="mt-16 mb-4">
                <span class="activity-title text-h4 text-md-h3 font-weight-bold">零信任安全</span>
              </v-card-title>
              <v-card-text class="mt-4 mb-4">
                <v-row>
                  <v-col cols="12" class="text-md-h5 my-4 grey--text">
                    <p>
                      零信任是一种实现 IT 安全性的方法名称它假定不存在受信任的网络边界，并且每个网络事务都必须经过身份验证才能发生。零信任基于“永不信任，始终验证”的原则，并依赖其他网络安全方法，如网络分段和严格的访问控制。零信任网络义“保护面”，其中包括关键数据、资产、应用和服务 (有时小得多，因为只包括关键资产。零信任安全性取代了企业网络称为 DAAS)。保护面通常比整个攻击面任视为一个漏洞，因为“受信任”网络上的用户能够在整个网边界内的资源应受信任的旧假设，并将络中移动，或导致其被授予访问权限的任何和所有数据外泄。
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-img src="/static/solution/case1.png" />
                  </v-col>
                  <v-col cols="12" class="text-center">
                    “内部等于可信任”和“外部等于不可信任”的旧安全观念需要被打破。
                  </v-col>
                  <v-col cols="12" class="text-md-h5 my-4 grey--text">
                    <p>
                      由此，ZTNA (零信任)应运而生。<br />
                      ZTNA 环境下，企业应用程序在公网上不再可见，可以免受攻击者的攻击。通过信任代理建立企业应用程序和用户之间的连接，根据身份、属性和环境动态授予访问权限，从而防止未经授权的用户进入并进一步防止数据泄露。对于数字化转型的企业，基于云的 ZTNA 产品，又提供了可扩展性和易用性。
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <v-img src="/static/solution/case2.png" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <contact-dialog :showDialog="contactDialog" type="SOLUTIONS" @dialogCloseEvent="contactDialog = false"></contact-dialog>
  </v-container>
</template>

<style lang="scss" scoped>
.page-h1 {
  font-weight: 600;
  font-size: 56px;
  line-height: 62px;
}
.page-h2 {
  font-weight: 500;
  font-size: 48px;
}
.page-h3 {
  font-weight: 500;
  font-size: 36px;
  line-height: 31px;
}
.page-h4 {
  font-weight: 500;
  font-size: 26px;
}
.page-h5 {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
}
.page-tab {
  font-weight: 500;
  font-size: 26px;
}

.page-subtitle {
  font-weight: 500;
  font-size: 22px;
}
.page-content {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
}

.page-btn {
  font-weight: 400;
  font-size: 24px;
}

.page-list {
  li {
    list-style: none;
  }
}

.activity-title {
  margin-left: auto;
  margin-right: auto;
}
.large-btn {
  width: 300px;
  font-size: 24px;
}
.tab-title {
  font-weight: 500;
  font-size: 24px;
  padding: 10px 40px;
  border-bottom: 1px solid #d9d9d9;
}

.tab-mobile-title {
  padding: 10px 10px;
  border-bottom: 1px solid #d9d9d9;
}
.grey-block {
  background-color: #f5f5f7;
  color: #0573d9;
  border-radius: 24px;
  padding: 30px 40px;
}

.mdm-image {
  height: 60px;
  width: 60px;
  object-fit: contain;
}
.jamf-image {
  height: 59px;
  margin-bottom: 20px;
}

.vm-image {
  width: 124px;
}

@media screen and (max-width:600px){
  .mdm-image {
    height: 30px;
    width: 30px;
  }
  .jamf-image {
    height: 40px;
  }

  .vm-image {
    width: 50px;
  }

  .text-pretty {
    word-break: keep-all;
  }
}
</style>

<script>
export default {
  data() {
    return {
      contactDialog: false,
    }
  }
}
</script>
